import React, { useState } from "react";
import { Modal, Button, Form, Input, Spin, message } from "antd";
import { postUpdateCallbackHost } from "@/store/slice/tool";
import { useUserPerms } from "@/utils/hook";
import { formLayout } from "@/utils/enum";

function PickGateway() {
    const perms = useUserPerms();
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const handleOnCancel = async () => {
        await form.resetFields();
        setVisible(false);
    };

    // eslint-disable-next-line no-unused-vars
    const [currentRow, setCurrentRow] = useState(null);
    const handleOk = async () => {
        setLoading(true);
        const formData = await form.validateFields();
        const { status, data } = await postUpdateCallbackHost(formData);
        setLoading(false);
        if (status !== 200) return;
        handleOnCancel();
        message.success("更新成功");
        console.log("data", data);
        setCurrentRow(data);
    };

    return (
        <>
            <Button
                type="primary"
                onClick={() => setVisible(true)}
                disabled={!perms["tool.UpdateGatewayCallbackHost"]}
            >
                更新回调域名
            </Button>
            <Modal
                title={"更新回调域名"}
                visible={visible}
                onOk={handleOk}
                onCancel={handleOnCancel}
                cancelText="取消"
                okText="送出"
            >
                <Spin spinning={loading}>
                    <Form form={form} {...formLayout}>
                        <Form.Item name="host_name" label="回调域名">
                            <Input />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </>
    );
}

export default PickGateway;
