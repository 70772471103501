import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Form, Select, Input } from "antd";
import { selectApp, getApps } from "@/store/slice/app";
import { formLayout, mode, Currency, FeeType } from "@/utils/enum";
import Spin from "@/components/Spin";
import SearchSelect from "@/components/SearchSelect";
import { CurrencyHelpTextFormItemFactory } from "@/components/factory/FormFactory";
import FeeTypeFormFields from "@/components/FeeTypeFormFields";

const { Option } = Select;

const AddEdit = props => {
  const { list: appList } = useSelector(selectApp);

  const [form] = Form.useForm();
  const handleOk = () => {
    form.validateFields().then(async formModel => {
      if (!formModel) return;
      const appData = appList.find(i => i.id === formModel.app_id) || {};
      const params = {
        ...formModel,
        ...(props.mode === "add" && {
          app_name: appData.name,
          userid: appData.developer_id ? String(appData.developer_id) : null,
        }),
      };
      await props.onOk(params);
      form.resetFields();
    });
  };
  useEffect(() => {
    if (props.visible) {
      if (props.mode === "edit") {
        form.setFieldsValue(props.data);
      }
      if (props.mode === "add") {
        form.setFieldsValue({ transfer_fee_type: 0 });
      }
    } else {
      form.resetFields();
    }
  }, [props.visible, props.mode, props.data, form]);

  return (
    <Modal
      title={`${mode[props.mode]}商户账户`}
      visible={props.visible}
      onOk={handleOk}
      onCancel={props.onCancel}
      cancelText="取消"
      okText="送出"
      confirmLoading={props.loading}
      destroyOnClose
      width={700}
    >
      <Spin spinning={props.loading}>
        <Form {...formLayout} form={form} preserve={true}>
          {props.mode === "add" && (
            <>
              <Form.Item
                name="app_id"
                label="商户ID"
                rules={[{ required: true, message: "请选择商户" }]}
              >
                <SearchSelect
                  action={getApps}
                  selector={selectApp}
                  searchKey="name"
                  val="id"
                  label={i => `${i.id} ${i.name}`}
                />
              </Form.Item>
              <Form.Item name="currency" label="货币">
                <Select>
                  {Object.keys(Currency).map(i => (
                    <Option value={Number(i)} key={i}>
                      {Currency[i]}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <CurrencyHelpTextFormItemFactory
                name="balance"
                label="余额"
                defaultValKey="balance"
              />
            </>
          )}
          <Form.Item
            hidden={props.mode === "add"}
            name="app_name"
            label="商户名称"
          >
            <Input type={props.mode === "add" ? "hidden" : "input"} />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => (
              <CurrencyHelpTextFormItemFactory
                name="agent_fee"
                label="线下代付手续费"
                defaultValKey="agent_fee"
                row={{ agent_fee: getFieldValue("agent_fee") }}
              />
            )}
          </Form.Item>
          <Form.Item name="transfer_fee_type" label="第三方代付手续费类型">
            <Select>
              {Object.keys(FeeType).map(i => (
                <Option value={Number(i)} key={i}>
                  {FeeType[i]}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const type = getFieldValue("transfer_fee_type");
              return <FeeTypeFormFields type={type} form={form} />;
            }}
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};
export default AddEdit;
