import { useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Form, Upload, Switch, Select, message, Button } from "antd";
import { formLayout, mode as Mode } from "@/utils/enum";
import Spin from "@/components/Spin";
import { ProductCategory } from "@/utils/enum";
const { Option } = Select;

const normFile = e => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const AddEdit = ({ visible, mode, data, onCancel, onOk, loading }) => {
  const [form] = Form.useForm();

  const isBatch = mode === "batch";

  const handleOk = async () => {
    form.validateFields().then(async formModel => {
      if (!formModel) return;

      const formData = new FormData();
      formData.append("is_active", formModel.is_active);
      formData.append("category", formModel.category);
      formData.append("file", formModel.file[0].originFileObj);

      await onOk(formData);
      form.resetFields();
    });
  };

  function beforeUpload(file) {
    if (loading) return null;
    if (file.size > 1024 * 1024) {
      message.warning("请选择小于 1M 的图片");
      return null;
    }
    return false;
  }

  useEffect(() => {
    visible
      ? form.setFieldsValue(
          mode === "edit" ? data : { is_active: false, category: 0 },
        )
      : form.resetFields();
  }, [visible, data, form, mode]);

  return (
    <Modal
      destroyOnClose={true}
      title={`${Mode[mode]}商品图片`}
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      cancelText="取消"
      okText="送出"
      confirmLoading={loading}
    >
      <Spin spinning={loading}>
        <Form {...formLayout} form={form}>
          <Form.Item name="is_active" label="是否上架" valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item name="category" label="商品类型">
            <Select>
              {Object.keys(ProductCategory).map(i => (
                <Option value={Number(i)} key={i}>
                  {ProductCategory[i]}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={isBatch ? "上传档案" : "上传图片"}
            name="file"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true, message: "请上传" }]}
          >
            <Upload
              beforeUpload={beforeUpload}
              listType={isBatch ? "text" : "picture-card"}
              maxCount={1}
              multiple={false}
              showUploadList={true}
              accept={isBatch ? ".xlsx" : "image/*"}
            >
              {isBatch ? (
                <Button type="primary" ghost icon={<PlusOutlined />}>
                  上传
                </Button>
              ) : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>上传</div>
                </div>
              )}
            </Upload>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};
export default AddEdit;
