import React, { useEffect, useCallback, useState } from "react";
import { Card, Form, Switch, Spin, message } from "antd";
import { postOpt, getOpt } from "@/store/slice/config";
import { useUserPerms } from "@/utils/hook";

// google 驗證
function Opt({ formLayout }) {
  const perms = useUserPerms();
  const [loading, setLoading] = useState(false);
  const [optForm] = Form.useForm();
  const handleGetOptStatus = useCallback(async () => {
    setLoading(true);
    const { status, data } = await getOpt();
    setLoading(false);
    if (status === 200) optForm.setFieldsValue({ ...data });
    // eslint-disable-next-line no-use-before-define
  }, [optForm]);

  const onValuesChange = async (_, data) => {
    setLoading(true);
    const { status } = await postOpt({ ...data });
    setLoading(false);
    status === 200 && message.success("更新成功");
  };

  useEffect(() => {
    handleGetOptStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card title="谷歌验证码" size="small">
      <Spin spinning={loading}>
        <Form form={optForm} {...formLayout} onValuesChange={onValuesChange}>
          <Form.Item name="sw" label="财务中心" valuePropName="checked">
            <Switch disabled={!perms["config.opt_update"]} />
          </Form.Item>
          <Form.Item name="app_sw" label="商户中心" valuePropName="checked">
            <Switch disabled={!perms["config.opt_update"]} />
          </Form.Item>
          <Form.Item name="agent_sw" label="代理后台" valuePropName="checked">
            <Switch disabled={!perms["config.opt_update"]} />
          </Form.Item>
        </Form>
      </Spin>
    </Card>
  );
}

export default Opt;
