import { useEffect, useState } from "react";
import { Layout, Menu, Divider } from "antd";
import { Link, useLocation } from "react-router-dom";
import {
  UserOutlined,
  TeamOutlined,
  ContainerOutlined,
  CalculatorOutlined,
  NotificationOutlined,
  UserSwitchOutlined,
  LoginOutlined,
  CalendarOutlined,
  BarChartOutlined,
  PayCircleOutlined,
  SettingOutlined,
  InteractionOutlined,
  UnorderedListOutlined,
  MoneyCollectOutlined,
  ToolOutlined,
  AccountBookOutlined,
} from "@ant-design/icons";
import { getRouterDisplayName } from "@/utils/format";
import { useDispatch } from "react-redux";
// import { selectApp } from "@/store/slice/app";
import { setRouterTabs } from "@/store/slice/routerTab";
import { useUserPerms } from "@/utils/hook";
const { SubMenu } = Menu;
const { Sider } = Layout;

const SidebarView = ({ routes }) => {
  const { pathname } = useLocation();
  // const { list } = useSelector(selectApp);
  const dispatch = useDispatch();
  const [selectedKey, setSelectedKey] = useState(pathname);
  const perms = useUserPerms();
  const handleSetRouterTab = key => {
    dispatch(setRouterTabs(key));
  };
  useEffect(() => {
    setSelectedKey(pathname);
  }, [pathname]);

  const GroupTitle = ({ title = "" }) => {
    return (
      <Divider
        orientation="left"
        orientationMargin="0"
        style={{ paddingRight: "1em", paddingLeft: "1em" }}
      >
        {title}
      </Divider>
    );
  };

  const Item = (key, icon = null) => (
    <Menu.Item key={key} icon={icon}>
      <Link to={key} onClick={() => handleSetRouterTab(pathname)}>
        {getRouterDisplayName(key, routes)}
      </Link>
    </Menu.Item>
  );

  const checkPageAuth = (keysArr = []) => keysArr.some(k => perms[k]);

  return (
    <Sider theme="light" breakpoint="lg" collapsedWidth="0" className="sidebar">
      {/* <div className="sider-content"> */}
      <div className="logo">{process.env.REACT_APP_TITLE}</div>
      {/* <Divider style={{ marginTop: 0 }} /> */}
      <Menu mode="inline" selectedKeys={[selectedKey]}>
        <GroupTitle title="交易" />
        {checkPageAuth(["order.List"]) && (
          <SubMenu key="Order" icon={<ContainerOutlined />} title="代收">
            {Item("/Order")}
            {/* {list.map(i => Item(`/Order${i.id}`))} */}
          </SubMenu>
        )}
        {checkPageAuth(["transfer.List"]) && (
          <SubMenu key="Transfer" icon={<InteractionOutlined />} title="代付">
            {Item("/Transfers")}
            {Item("/TransfersStatus2")}
            {Item("/TransfersStatus7")}
            {Item("/TransfersStatus8")}
          </SubMenu>
        )}
        {checkPageAuth(["transfer.ListApp"]) && (
          <SubMenu
            key="TransfersApp"
            icon={<MoneyCollectOutlined />}
            title="商户提款"
          >
            {Item("/TransfersApp")}
            {Item("/TransfersAppStatus2")}
            {Item("/TransfersAppStatus7")}
            {Item("/TransfersAppStatus8")}
          </SubMenu>
        )}
        {checkPageAuth(["transfer.ListAgent"]) && (
          <SubMenu
            key="TransfersAgent"
            icon={<MoneyCollectOutlined />}
            title="代理提款"
          >
            {Item("/TransfersAgent")}
            {Item("/TransfersAgentStatus2")}
            {Item("/TransfersAgentStatus7")}
            {Item("/TransfersAgentStatus8")}
          </SubMenu>
        )}

        {/* 代理出售USDT */}
        {perms["order.ListAgent"] && Item("/OrderAgent", <LoginOutlined />)}

        <GroupTitle title="管理" />

        {checkPageAuth(["app.List", "appuser.List", "appacct.List"]) && (
          <SubMenu key="Merchant" icon={<TeamOutlined />} title="商户管理">
            {perms["appGroup.List"] && Item("/AppGroup")}
            {perms["app.List"] && Item("/App")}
            {perms["appuser.List"] && Item("/AppUser")}
            {perms["appacct.List"] && Item("/AppAcct")}
          </SubMenu>
        )}
        {checkPageAuth(["agent.List", "agentacct.List"]) && (
          <SubMenu key="Agents" icon={<UserSwitchOutlined />} title="代理管理">
            {perms["agent.List"] && Item("/Agent")}
            {perms["agentacct.List"] && Item("/AgentAcct")}
          </SubMenu>
        )}

        {checkPageAuth([
          "bank.List",
          "card.List",
          "cardacct.List",
          "cardacctlog.List",
          "cryptowallet.List",
          "cryptoacct.List",
          "gateway.List",
          "gatewayacct.List",
        ]) && (
          <SubMenu key="Gateway" icon={<PayCircleOutlined />} title="渠道管理">
            {checkPageAuth([
              "bank.List",
              "card.List",
              "cardacct.List",
              "cardacctlog.List",
            ]) && (
              <SubMenu
                key="Cards"
                // icon={<CreditCardOutlined />}
                title="银行卡"
              >
                {perms["bank.List"] && Item("/Bank")}
                {perms["card.List"] && Item("/Card")}
                {perms["cardacct.List"] && Item("/CardAcct")}
                {perms["cardacctlog.List"] && Item("/CardAcctLog")}
                {perms["bankrecord.List"] && Item("/CardBankLog")}
              </SubMenu>
            )}

            {checkPageAuth(["cryptowallet.List", "cryptoacct.List"]) && (
              <SubMenu
                key="Crypto"
                // icon={<WalletOutlined />}
                title="加密货币"
              >
                {perms["cryptowallet.List"] && Item("/CryptoWallet")}
                {perms["cryptoacct.List"] && Item("/CryptoAcct")}
                {perms["cryptoacctlog.List"] && Item("/CryptoAcctLog")}
              </SubMenu>
            )}

            {checkPageAuth(["gateway.List", "gatewayacct.List"]) && (
              <SubMenu
                key="Pay"
                // icon={<PayCircleOutlined />}
                title="支付管理"
              >
                {Item("/Gateway")}
                {Item("/Gateway1")}
                {Item("/Gateway4")}
                {Item("/Gateway2")}
                {Item("/Gateway3")}
                {perms["gatewayacct.List"] && Item("/GatewayAcct")}
              </SubMenu>
            )}
          </SubMenu>
        )}

        {checkPageAuth(["product.List"]) && (
          <SubMenu
            key="product"
            icon={<InteractionOutlined />}
            title="商品管理"
          >
            {checkPageAuth(["product.List"]) && Item("/Products")}
            {checkPageAuth(["product_image.List"]) && Item("/Product/Images")}
          </SubMenu>
        )}

        <GroupTitle title="报表及日志" />

        {checkPageAuth([
          "appacctlog.List",
          "agentacctlog.List",
          "gatewayacctlog.List",
        ]) && (
          <SubMenu
            key="FundsLog"
            icon={<AccountBookOutlined />}
            title="帐户纪录"
          >
            {perms["appacctlog.List"] && Item("/AppAcctLog")}
            {perms["agentacctlog.List"] && Item("/AgentAcctLog")}
            {perms["gatewayacctlog.List"] && Item("/GatewayAcctLogs")}
          </SubMenu>
        )}

        {checkPageAuth([
          "agent.daily",
          // "developer.daily",
          "order.daily",
          "app.daily",
          "transfer.app.daily",
          "app.gateway.daily",
          "transfer.app.gateway.daily",
          "app.profit.summary",
          "app.balance.summary.daily",
        ]) && (
          <SubMenu key="Report" icon={<BarChartOutlined />} title="报表">
            {checkPageAuth(["order.daily", "app.profit.summary"]) && (
              <SubMenu
                key="ReportTrade"
                // icon={<BarChartOutlined />}
                title="交易"
              >
                {perms["order.daily"] && Item("/OrderDaily")}
                {perms["app.profit.summary"] && Item("/AppProfitSummary")}
              </SubMenu>
            )}

            {checkPageAuth([
              "app.daily",
              "transfer.app.daily",
              "app.gateway.daily",
              "transfer.app.gateway.daily",
              "app.balance.summary.daily",
            ]) && (
              <SubMenu
                key="ReportApp"
                // icon={<BarChartOutlined />}
                title="商户"
              >
                {perms["app.daily"] && Item("/AppDaily")}
                {perms["transfer.app.daily"] && Item("/TransferAppDaily")}
                {perms["app.gateway.daily"] && Item("/AppGatewayDaily")}
                {perms["transfer.app.gateway.daily"] &&
                  Item("/TransferAppGatewayDaily")}
                {perms["app.balance.summary.daily"] &&
                  Item("/AppBalanceSummary")}
              </SubMenu>
            )}

            {checkPageAuth(["agent.daily"]) && (
              <SubMenu
                key="ReportTransfer"
                // icon={<BarChartOutlined />}
                title="代理"
              >
                {perms["agent.daily"] && Item("/AgentDaily")}
              </SubMenu>
            )}

            {checkPageAuth(["gateway.daily"]) && (
              <SubMenu
                key="ReportGateway"
                // icon={<BarChartOutlined />}
                title="渠道"
              >
                {perms["gateway.daily"] && Item("/GatewayDaily")}
              </SubMenu>
            )}
          </SubMenu>
        )}

        {checkPageAuth(["loginlog.List", "audit.List", "acctswaplog.List"]) && (
          <SubMenu key="DailyLog" icon={<CalendarOutlined />} title="日志">
            {perms["loginlog.List"] && Item("/LoginLog")}
            {perms["audit.List"] && Item("/Audit")}
            {perms["acctswaplog.List"] && Item("/AcctSwapLog")}
          </SubMenu>
        )}

        <GroupTitle title="系统" />

        <SubMenu key="Users" icon={<UserOutlined />} title="用户管理">
          {Item("/")}
          {perms["role.List"] && Item("/Role")}
        </SubMenu>

        {checkPageAuth(["agentwhitelist.List", "developerwhitelist.List"]) && (
          <SubMenu
            key="Whitelist"
            icon={<UnorderedListOutlined />}
            title="白名单管理"
          >
            {perms["agentwhitelist.List"] && Item("/AgentWhitelist")}
            {perms["developerwhitelist.List"] && Item("/DeveloperWhitelist")}
          </SubMenu>
        )}

        {checkPageAuth(["config.exchange"]) &&
          Item("/USDTExchange", <CalculatorOutlined />)}
        {checkPageAuth(["agent_notice.List"]) &&
          Item("/AgentNotices", <NotificationOutlined />)}

        {Item("/SystemSetting", <SettingOutlined />)}
        {Item("/Tool", <ToolOutlined />)}

        {/* {checkPageAuth(["app.List", "appuser.List", "appacct.List"]) && (
            <SubMenu key="Merchant" icon={<TeamOutlined />} title="商户管理">
              {perms["appGroup.List"] && Item("/AppGroup")}
              {perms["app.List"] && Item("/App")}
              {perms["appuser.List"] && Item("/AppUser")}
              {perms["appacct.List"] && Item("/AppAcct")}
            </SubMenu>
          )} */}

        {/* {checkPageAuth(["gateway.List", "gatewayacct.List"]) && (
            <SubMenu key="Pay" icon={<PayCircleOutlined />} title="支付管理">
              {Item("/Gateway")}
              {Item("/Gateway1")}
              {Item("/Gateway4")}
              {Item("/Gateway2")}
              {Item("/Gateway3")}
              {perms["gatewayacct.List"] && Item("/GatewayAcct")}
            </SubMenu>
          )} */}
        {/* {checkPageAuth(["order.List"]) && (
            <SubMenu key="Order" icon={<ContainerOutlined />} title="代收">
              {Item("/Order")}
              {list.map(i => Item(`/Order${i.id}`))}
            </SubMenu>
          )} */}
        {/* {checkPageAuth(["cryptowallet.List", "cryptoacct.List"]) && (
            <SubMenu key="Crypto" icon={<WalletOutlined />} title="加密货币">
              {perms["cryptowallet.List"] && Item("/CryptoWallet")}
              {perms["cryptoacct.List"] && Item("/CryptoAcct")}
              {perms["cryptoacctlog.List"] && Item("/CryptoAcctLog")}
            </SubMenu>
          )} */}
        {/* {checkPageAuth([
            "bank.List",
            "card.List",
            "cardacct.List",
            "cardacctlog.List",
          ]) && (
            <SubMenu key="Cards" icon={<CreditCardOutlined />} title="银行卡">
              {perms["bank.List"] && Item("/Bank")}
              {perms["card.List"] && Item("/Card")}
              {perms["cardacct.List"] && Item("/CardAcct")}
              {perms["cardacctlog.List"] && Item("/CardAcctLog")}
              {perms["bankrecord.List"] && Item("/CardBankLog")}
            </SubMenu>
          )} */}
        {/* {checkPageAuth(["agent.List", "agentacct.List"]) && (
            <SubMenu
              key="Agents"
              icon={<UserSwitchOutlined />}
              title="代理管理"
            >
              {perms["agent.List"] && Item("/Agent")}
              {perms["agentacct.List"] && Item("/AgentAcct")}
            </SubMenu>
          )} */}
        {/* {perms["loginlog.List"] && Item("/LoginLog", <LoginOutlined />)}
          {perms["audit.List"] && Item("/Audit", <TableOutlined />)} */}

        {/* 預設頁面 */}
        {/* <SubMenu key="Users" icon={<UserOutlined />} title="用户管理">
            {Item("/")}
            {perms["role.List"] && Item("/Role")}
          </SubMenu> */}

        {/* {checkPageAuth([
            "appacctlog.List",
            "agentacctlog.List",
            "acctswaplog.List",
            "gatewayacctlog.List",
          ]) && (
            <SubMenu
              key="FundsLog"
              icon={<AccountBookOutlined />}
              title="帐户纪录"
            >
              {perms["appacctlog.List"] && Item("/AppAcctLog")}
              {perms["agentacctlog.List"] && Item("/AgentAcctLog")}
              {perms["acctswaplog.List"] && Item("/AcctSwapLog")}
              {perms["gatewayacctlog.List"] && Item("/GatewayAcctLogs")}
            </SubMenu>
          )} */}
        {/* 
          {checkPageAuth([
            "agent.daily",
            // "developer.daily",
            "order.daily",
            "app.daily",
            "transfer.app.daily",
            "app.gateway.daily",
            "transfer.app.gateway.daily",
            "app.profit.summary",
            "app.balance.summary.daily",
          ]) && (
            <SubMenu key="Report" icon={<BarChartOutlined />} title="报表">
              {perms["agent.daily"] && Item("/AgentDaily")}
              {perms["order.daily"] && Item("/OrderDaily")}
              {perms["app.daily"] && Item("/AppDaily")}
              {perms["transfer.app.daily"] && Item("/TransferAppDaily")}
              {perms["gateway.daily"] && Item("/GatewayDaily")}
              {perms["app.gateway.daily"] && Item("/AppGatewayDaily")}
              {perms["transfer.app.gateway.daily"] &&
                Item("/TransferAppGatewayDaily")}
              {perms["app.profit.summary"] && Item("/AppProfitSummary")}
              {perms["app.balance.summary.daily"] && Item("/AppBalanceSummary")}
            </SubMenu>
          )} */}

        {/* <SubMenu key="Config" icon={<SettingOutlined />} title="系统设置">
            {checkPageAuth(["config.exchange"]) && Item("/USDTExchange")}
            {Item("/SystemSetting")}
            {checkPageAuth(["agent_notice.List"]) && Item("/AgentNotices")}
          </SubMenu> */}

        {/* {checkPageAuth(["transfer.List"]) && (
            <SubMenu key="Transfer" icon={<InteractionOutlined />} title="代付">
              {Item("/Transfers")}
              {Item("/TransfersStatus2")}
              {Item("/TransfersStatus7")}
              {Item("/TransfersStatus8")}
            </SubMenu>
          )} */}

        {/* {checkPageAuth(["transfer.ListAgent"]) && (
            <SubMenu
              key="TransfersAgent"
              icon={<MoneyCollectOutlined />}
              title="代理提款"
            >
              {Item("/TransfersAgent")}
              {Item("/TransfersAgentStatus2")}
              {Item("/TransfersAgentStatus7")}
              {Item("/TransfersAgentStatus8")}
            </SubMenu>
          )}
           */}

        {/* {perms["order.ListAgent"] && Item("/OrderAgent", <LoginOutlined />)} */}

        {/* {checkPageAuth(["transfer.ListApp"]) && (
            <SubMenu
              key="TransfersApp"
              icon={<MoneyCollectOutlined />}
              title="商户提款"
            >
              {Item("/TransfersApp")}
              {Item("/TransfersAppStatus2")}
              {Item("/TransfersAppStatus7")}
              {Item("/TransfersAppStatus8")}
            </SubMenu>
          )} */}

        {/* {checkPageAuth([
            "agentwhitelist.List",
            "developerwhitelist.List",
          ]) && (
            <SubMenu
              key="Whitelist"
              icon={<UnorderedListOutlined />}
              title="白名单管理"
            >
              {perms["agentwhitelist.List"] && Item("/AgentWhitelist")}
              {perms["developerwhitelist.List"] && Item("/DeveloperWhitelist")}
            </SubMenu>
          )} */}
        {/* {checkPageAuth(["product.List"]) && (
            <SubMenu
              key="product"
              icon={<InteractionOutlined />}
              title="商品管理"
            >
              {checkPageAuth(["product.List"]) && Item("/Products")}
              {checkPageAuth(["product_image.List"]) && Item("/Product/Images")}
            </SubMenu>
          )} */}
        {/* {Item("/Tool", <ToolOutlined />)} */}
      </Menu>
      <div className="tag">{process.env.REACT_APP_VERSION}</div>
      {/* </div> */}
    </Sider>
  );
};
export default SidebarView;
