import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "@/utils/request";
import { mapToValueEnum } from "@/utils/format";

export const getEnumPayType = createAsyncThunk(
  "config/getEnumPayType",
  async () => {
    const res = await request({
      url: "api/config/enum_pay_type",
      method: "get",
    });
    return res;
  },
);

export const getExchange = createAsyncThunk("config/getExchange", async () => {
  const res = await request({
    url: "/api/config/exchange",
    method: "get",
  });
  return res;
});

export const editExchange = async params => {
  const res = await request({
    url: `/api/config/exchange`,
    method: "post",
    data: params,
  });
  return res;
};

export const getOpt = async () => {
  const res = await request({
    url: `/api/config/opt`,
    method: "get",
  });
  return res;
};

export const postOpt = async data => {
  const res = await request({
    url: `/api/config/opt`,
    method: "post",
    data,
  });
  return res;
};

/**
 * 預設代理處理
 */
// 取得大額代理
export const getConfigAgent = async () => {
  const res = await request({
    url: `/api/config/agent`,
    method: "get",
  });
  return res;
};
// 取得小額代理
export const getConfigAgentClass = async () => {
  const res = await request({
    url: `/api/config/agentclass`,
    method: "get",
  });
  return res;
};
// 設定大額代理
export const postConfigAgent = async (data = {}) => {
  const res = await request({
    url: `/api/config/agent`,
    method: "post",
    data,
  });
  return res;
};
// 設定小額代理
export const postConfigAgentClass = async (data = {}) => {
  const res = await request({
    url: `/api/config/agentclass`,
    method: "post",
    data,
  });
  return res;
};

/**
 * 代理銀行卡限額
 */
// 最小限額
export const getConfigAgentCardMin = async () => {
  const res = await request({
    url: "/api/config/agent-card-minimum",
    method: "get",
  });
  return res;
};
export const postConfigAgentCardMin = async (data = {}) => {
  const res = await request({
    url: "/api/config/agent-card-minimum",
    method: "post",
    data,
  });
  return res;
};

// 最大限額
export const getConfigAgentCardMax = async () => {
  const res = await request({
    url: "/api/config/agent-card-maximum",
    method: "get",
  });
  return res;
};
export const postConfigAgentCardMax = async (data = {}) => {
  const res = await request({
    url: "/api/config/agent-card-maximum",
    method: "post",
    data,
  });
  return res;
};

/**
 * 自動上分
 */
// 訂單開啟自動上分
export const getConfigAutoOrderConfirm = async () => {
  const res = await request({
    url: "/api/config/auto-order-confirm",
    method: "get",
  });
  return res;
};
export const postConfigAutoOrderConfirm = async (data = {}) => {
  const res = await request({
    url: "/api/config/auto-order-confirm",
    method: "post",
    data,
  });
  return res;
};

/**
 * 銀行卡紀錄自動綁定開關
 */
export const getConfigBankRecord = async () => {
  const res = await request({
    url: "/api/config/bankrecord",
    method: "get",
  });
  return res;
};
export const postConfigBankRecord = async (data = {}) => {
  const res = await request({
    url: "/api/config/bankrecord",
    method: "post",
    data,
  });
  return res;
};

/**
 * 客服網址設定
 */
export const getConfigCustomerService = async () => {
  const res = await request({
    url: "/api/config/customer_service",
    method: "get",
  });
  return res;
};
export const postConfigCustomerService = async (data = {}) => {
  const res = await request({
    url: "/api/config/customer_service",
    method: "post",
    data,
  });
  return res;
};

/**
 * 代理提款商戶設置
 */
export const getConfigAgentPayoutApp = async () => {
  const res = await request({
    url: "/api/config/agent_payout_app",
    method: "get",
  });
  return res;
};
export const postConfigAgentPayoutApp = async (data = {}) => {
  const res = await request({
    url: "/api/config/agent_payout_app",
    method: "post",
    data,
  });
  return res;
};

/**
 * 代理出售USDT商戶設置
 */
export const getConfigAgentOrderUSDTApp = async () => {
  const res = await request({
    url: "/api/config/agent_order_usdt_app",
    method: "get",
  });
  return res;
};
export const postConfigAgentOrderUSDTApp = async (data = {}) => {
  const res = await request({
    url: "/api/config/agent_order_usdt_app",
    method: "post",
    data,
  });
  return res;
};


/**
 * 渠道成功率時間設置
 */
export const getConfigGatewaySucceedRateTime = async () => {
  const res = await request({
    url: "/api/config/gateway_succeed_rate_time",
    method: "get",
  });
  return res;
};
export const postGatewaySucceedRateTime = async (data = {}) => {
  const res = await request({
    url: "/api/config/gateway_succeed_rate_time",
    method: "post",
    data,
  });
  return res;
};

export const slice = createSlice({
  name: "config",
  initialState: {
    exchange: {},
    payTypeList: [],
    payTypeMap: {},
  },
  reducers: {},
  extraReducers: {
    [getExchange.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.exchange = data;
    },
    [getEnumPayType.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.payTypeList = data;
      state.payTypeMap = mapToValueEnum(data, { value: "key", label: "label" });
    },
  },
});
export const selectConfig = state => state.config;
export default slice.reducer;
