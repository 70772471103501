import React, { useState } from "react";
import { Space, Button, Modal, Switch, Image } from "antd";
import {
  selectProductImage,
  getProductImages,
  getProductImage,
  addProductImage,
  editProductImage,
  deleteProductImage,
} from "@/store/slice/productImage";
import { SearchFormFactory } from "@/components/factory/FormFactory";
import { useList, useDetail, useUserPerms } from "@/utils/hook";
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { NormalTable } from "@/components/factory/TableFactory";
import AddEdit from "./AddEdit";
import { dateFormat } from "@/utils/format";
import { ProductCategory, IsBoolEnum } from "@/utils/enum";
import Tag from "@/components/Tag";

function ProductImage() {
  const perms = useUserPerms();
  const searchFields = {
    id__in: { type: "string", label: "ID" },
    name__k: { type: "string", label: "商品名" },
    category: { type: "select", label: "商品类型", options: ProductCategory },
    is_active: {
      type: "select",
      label: "是否上架",
      options: IsBoolEnum,
    },
  };

  const {
    res: { list, meta },
    loading: listLoading,
    handleSearch,
    handleGetList,
    handleChangePage,
    handleChange,
    handleAdd: handleAddHook,
  } = useList(getProductImages, selectProductImage);
  const [addVisible, setAddVisible] = useState(false);

  const handleAddClick = () => {
    setAddVisible(true);
  };
  const handleAdd = async formData => {
    handleAddHook({ action: addProductImage, formData });
    setAddVisible(false);
  };
  const [detailId] = useState(null);
  const { handleEdit: handleEditHook } = useDetail(
    { action: getProductImage, id: detailId },
    selectProductImage,
  );

  const handleDelete = async (close, id) => {
    const { status } = await deleteProductImage(id);
    close();
    if (status !== 200) return;
    handleGetList({ page: meta.current });
  };

  const handleDeleteClick = id => {
    Modal.confirm({
      title: "是否删除",
      icon: <ExclamationCircleOutlined />,
      content: `即将删除 ${id}，是否继续？`,
      okText: "确认",
      cancelText: "取消",
      onOk: close => handleDelete(close, id),
    });
  };

  const handleChangeIsActive = async (checked, { id, ...params }) => {
    params.is_active = checked;
    await handleEditHook({ action: editProductImage, id, ...params });
    handleGetList({ page: meta.current });
  };

  const columns = [
    { title: "id", dataIndex: "id" },
    { title: "圖片名称", dataIndex: "name" },
    {
      title: "圖片Url",
      dataIndex: "url",
      render: val => <Image preview={{ src: val }} width={100} src={val} />,
    },
    {
      title: "商品类型",
      dataIndex: "category",
      render: val => ProductCategory[val] || "",
      width: 100,
    },
    {
      title: "是否上架",
      dataIndex: "is_active",
      dRender: val => <Tag val={val} />,
      render: (val, record) => (
        <Switch
          checked={val}
          onChange={checked => handleChangeIsActive(checked, record)}
          disabled={!perms["card.SetActive"]}
        />
      ),
    },
    { title: "创建ID", dataIndex: "create_id" },
    { title: "创建者", dataIndex: "create_name" },
    {
      title: "创建日期",
      dataIndex: "created",
      render: val => dateFormat(val),
      className: "text-nowrap",
      sorter: true,
    },
    { title: "更新者ID", dataIndex: "update_id" },
    { title: "更新者", dataIndex: "update_name" },
    {
      title: "更新日期",
      dataIndex: "updated",
      render: val => dateFormat(val),
      className: "text-nowrap",
      sorter: true,
    },
    {
      title: "动作",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      render: (_, record) => (
        <Space>
          <Button
            size="small"
            type="text"
            className="p-0"
            danger
            onClick={() => handleDeleteClick(record.id)}
            disabled={!perms["product_image.Delete"]}
          >
            删除
          </Button>
        </Space>
      ),
    },
  ];
  const defaultColumns = [
    "id",
    "name",
    "url",
    "category",
    "is_active",
    "create_name",
    "created",
    "update_name",
    "updated",
    "action",
  ];
  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchFormFactory fields={searchFields} handleSubmit={handleSearch} />

      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={handleAddClick}
        disabled={!perms["product_image.Create"]}
      >
        添加
      </Button>

      <NormalTable
        allColumns={columns}
        defaultColumns={defaultColumns}
        dataSource={list}
        meta={meta}
        onChangePage={handleChangePage}
        onChange={handleChange}
        loading={listLoading}
        onShowSizeChange={handleChangePage}
      />
      <AddEdit
        visible={addVisible}
        onOk={handleAdd}
        onCancel={() => setAddVisible(false)}
        loading={listLoading}
        mode={"add"}
      />
    </Space>
  );
}
export default ProductImage;
