import React from "react";
import { Form, Modal, Table, Input } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { selectApp, getApps } from "@/store/slice/app";
import SearchSelect from "@/components/SearchSelect";

function Batch({
  visible = false,
  loading = false,
  title = "",
  mode = "",
  onOk = () => {},
  onCancel = () => {},
  dataSource = [],
  columns = [],
}) {
  const [form] = Form.useForm();
  const clickOk = async () => {
    const formModel = await form.validateFields();
    const handleOk = () => {
      onOk({
        ...formModel,
        ...(mode === "whitelist" && {
          whitelist: formModel.whitelist
            ? formModel.whitelist.replace(/(\r\n|\n|\r)/gm, "").split(",")
            : [],
        }),
      });
    };

    let isOk = true;
    Object.keys(formModel).forEach(k => formModel[k] || (isOk = false));
    isOk
      ? handleOk()
      : Modal.confirm({
          title: "注意",
          icon: <ExclamationCircleOutlined />,
          content: `栏位为空将修改为空值，是否继续？`,
          okText: "确认",
          cancelText: "取消",
          onOk: handleOk,
        });
  };

  return (
    <>
      <Modal
        width={650}
        title={title}
        visible={visible}
        onOk={clickOk}
        onCancel={onCancel}
        cancelText="取消"
        okText="送出"
        confirmLoading={loading}
        destroyOnClose
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          form={form}
          preserve={false}
          style={{ marginBottom: "8px" }}
        >
          {mode === "apps" && (
            <Form.Item name="apps" label="商户IDs">
              <SearchSelect
                action={getApps}
                selector={selectApp}
                searchKey="name"
                val="id"
                label={i => `${i.id} ${i.name}`}
                mode="multiple"
              />
            </Form.Item>
          )}
          {mode === "api" && (
            <>
              <Form.Item name="api" label="api">
                <Input />
              </Form.Item>
            </>
          )}
          {mode === "appSecret" && (
            <>
              <Form.Item name="appid" label="appid">
                <Input />
              </Form.Item>
              <Form.Item name="appsecret" label="appsecret">
                <Input />
              </Form.Item>
            </>
          )}
          {mode === "whitelist" && (
            <>
              <Form.Item name="whitelist" label="白名单">
                <Input.TextArea />
              </Form.Item>
            </>
          )}
        </Form>

        <h4>批次更新清单</h4>
        <Table
          dataSource={dataSource}
          columns={columns}
          scroll={{ x: "auto" }}
          rowKey="id"
          size="small"
          pagination={false}
        />
      </Modal>
    </>
  );
}

export default Batch;
