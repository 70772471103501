import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Space } from "antd";
import {
  selectAgentDaily,
  getAgentDaily,
  getAgentDailySum,
  getAgentDailyRefetch,
  exportAgentDaily,
} from "@/store/slice/agentDaily";
import { SearchFormFactory } from "@/components/factory/FormFactory";
import {
  useList,
  useUserPerms,
  useExportExcel,
  useSearchCache,
} from "@/utils/hook";
import { Currency, IsBoolEnum } from "@/utils/enum";
import { NormalTable } from "@/components/factory/TableFactory";
import RefreshDataModal from "@/components/RefreshDataModal";
import SumTable from "@/components/SumTable";
import {
  exportColumnsRenderMap,
  sumColumns,
  columns,
  statisticsColumns,
} from "./AgentDailyColumns";

const AgentDaily = () => {
  const perms = useUserPerms();
  const searchFields = {
    id__in: { type: "string", label: "ID" },
    agent_id__in: { type: "string", label: "用户ID" },
    agent_name__k: { type: "string", label: "代理名称" },
    created__btw: { type: "rangeDate", label: "报表时间", showTime: false },
    currency: { type: "select", label: "货币类型", options: Currency },
    is_online: {
      type: "select",
      label: "是否线上渠道",
      options: IsBoolEnum,
    },
  };
  const {
    res: { list, meta, sum },
    loading: listLoading,
    handleGetList,
    handleSearch,
    handleChangePage,
    handleChange,
  } = useList(getAgentDaily, selectAgentDaily);

  const dispatch = useDispatch();
  const { handleGetPageSearchCache } = useSearchCache();
  const getSum = (params = {}) => {
    perms["agent.daily.sum"] &&
      dispatch(getAgentDailySum({ ...handleGetPageSearchCache(), ...params }));
  };
  useEffect(() => {
    getSum();
    // eslint-disable-next-line
  }, []);
  const [searchData, setSearchData] = useState({});
  const handleSearchClick = params => {
    setSearchData(params);
    handleSearch(params);
    getSum(params);
  };

  const {
    loading: exportLoading,
    handleExport,
    handleSelectedColumns,
  } = useExportExcel({
    exportColumnsRenderMap,
    exportAction: exportAgentDaily,
  });

  const [refreshVisible, setRefreshVisible] = useState(false);

  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchFormFactory
        fields={searchFields}
        handleSubmit={handleSearchClick}
        showExportBtn={true}
        handleExport={handleExport}
        exportLoading={exportLoading}
        otherBtn={
          <Button type="primary" onClick={() => setRefreshVisible(true)}>
            更新报表
          </Button>
        }
      />
      {perms["agent.daily.sum"] && <SumTable data={sum} labels={sumColumns} />}
      <NormalTable
        allColumns={columns}
        defaultColumns={columns.map(i => i.dataIndex)}
        dataSource={list}
        meta={meta}
        onChangePage={handleChangePage}
        onChange={handleChange}
        loading={listLoading}
        onShowSizeChange={handleChangePage}
        statisticsColumns={statisticsColumns}
        setSelectedColumns={handleSelectedColumns}
      />
      <RefreshDataModal
        visible={refreshVisible}
        setVisible={setRefreshVisible}
        refreshAction={getAgentDailyRefetch}
        callback={() => {
          handleGetList();
          getSum(searchData);
        }}
      />
    </Space>
  );
};
export default AgentDaily;
