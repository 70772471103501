import React from "react";
import { Space } from "antd";
import {
  selectAgentAcctLog,
  getAgentAcctLogs,
  exportAgentAccLog,
} from "@/store/slice/agentAcctLog";
import { SearchFormFactory } from "@/components/factory/FormFactory";
import { NormalTable } from "@/components/factory/TableFactory";
import { useList, useExportExcel } from "@/utils/hook";
import {
  columns,
  defaultColumns,
  exportColumnsRenderMap,
} from "./AgentAcctLogColumn";

const AgentAcctLog = () => {
  const searchFields = {
    id__in: { type: "string", label: "ID" },
    agent_acct_id__in: { type: "string", label: "代理账户ID" },
    agent_user_id__in: { type: "string", label: "代理用户ID" },
    agent_name__k: { type: "string", label: "代理名称" },
    order_no__in: { type: "string", label: "订单号" },
    funding_type: {
      type: "select",
      label: "资金类型",
      options: {
        '{"type__ne":9}': "余额",
        '{"type__eq":9}': "圈存",
      },
    },
    created__btw: { type: "rangeDate", label: "创建日期" },
  };

  const {
    res: { list, meta },
    loading: listLoading,
    handleSearch,
    handleChangePage,
    handleChange,
  } = useList(getAgentAcctLogs, selectAgentAcctLog);

  const handleSearchSubmit = params => {
    handleSearch(params);
  };

  const {
    loading: exportLoading,
    handleExport,
    handleSelectedColumns,
  } = useExportExcel({
    exportColumnsRenderMap,
    exportAction: exportAgentAccLog,
  });

  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchFormFactory
        fields={searchFields}
        handleSubmit={handleSearchSubmit}
        showExportBtn={true}
        handleExport={handleExport}
        exportLoading={exportLoading}
      />
      <NormalTable
        allColumns={columns}
        defaultColumns={defaultColumns}
        dataSource={list}
        meta={meta}
        onChangePage={handleChangePage}
        onChange={handleChange}
        loading={listLoading}
        onShowSizeChange={handleChangePage}
        setSelectedColumns={handleSelectedColumns}
      />
    </Space>
  );
};

export default AgentAcctLog;
