import { dateFormat, priceFormat } from "@/utils/format";
import { Currency, isActiveLang } from "@/utils/enum";
import Tag from "@/components/Tag";

const moneyRender = () => {
  return {
    render: (val, record) =>
      priceFormat({ val: val || 0, currency: record.currency }),
    className: "text-nowrap",
    sorter: true,
  };
};

// export const handleFailedCount = (val, record) =>
//   record.total_times - record.total_succeeded_times;

export const columns = [
  // { title: "ID", dataIndex: "id", sorter: true },
  { title: "渠道ID", dataIndex: "gateway_id", sorter: true },
  { title: "渠道名称", dataIndex: "gateway_name", sorter: true },
  { title: "商户ID", dataIndex: "app_id", sorter: true },
  { title: "商户名称", dataIndex: "app_name", sorter: true },
  {
    title: "报表时间",
    dataIndex: "created",
    className: "text-nowrap",
    render: val => dateFormat(val, "YYYY-MM-DD"),
    sorter: true,
  },
  {
    title: "是否线上渠道",
    dataIndex: "is_online",
    render: val => <Tag val={val} />,
    sorter: true,
  },
  {
    title: "货币类型",
    dataIndex: "currency",
    render: val => Currency[val] || "",
    className: "text-nowrap",
  },
  {
    title: "累计订单次数",
    dataIndex: "total_times",
    sorter: true,
  },
  {
    title: "累计订单成功次数",
    dataIndex: "total_succeeded_times",
    sorter: true,
  },
  {
    title: "累计金额",
    dataIndex: "total_amount",
    ...moneyRender(),
  },
  {
    title: "累计成功金额",
    dataIndex: "total_succeeded_amount",
    ...moneyRender(),
  },
  {
    title: "成功率",
    dataIndex: "succeeded_rate",
    sorter: true,
  },
  {
    title: "累计手续费",
    dataIndex: "total_fee",
    ...moneyRender(),
  },
  {
    title: "渠道余额",
    dataIndex: "gateway_balance",
    ...moneyRender(),
  },
  {
    title: "实际手续费",
    dataIndex: "total_amount_gateway_fee",
    ...moneyRender(),
  },
  {
    title: "实际渠道余额",
    dataIndex: "total_gateway_balance",
    ...moneyRender(),
  },
  // {
  //   title: "渠道别名",
  //   dataIndex: "gateway_alias_name",
  //   sorter: true,
  // },

  // {
  //   title: "失败数量",
  //   dataIndex: "failed_count",
  //   render: handleFailedCount,
  //   sorter: true,
  // },
  // {
  //   title: "扣除通道费后金额汇总",
  //   dataIndex: "total_amount_gateway",
  //   ...moneyRender(),
  // },
  // {
  //   title: "扣除商户费(商户+通道)后金额汇总",
  //   dataIndex: "total_amount_app",
  //   ...moneyRender(),
  // },
  // {
  //   title: "累计盈利金额",
  //   dataIndex: "total_profit_amount",
  //   ...moneyRender(),
  // },
];
export const sumColumns = [
  {
    title: "累计订单次数",
    dataIndex: "total_times",
  },
  {
    title: "累计订单成功次数",
    dataIndex: "total_succeeded_times",
  },
  {
    title: "累计成功金额",
    dataIndex: "total_succeeded_amount",
    render: val => priceFormat({ val, currency: 0 }),
  },
  {
    title: "累计实际手续费",
    dataIndex: "total_amount_gateway_fee",
    render: val => priceFormat({ val, currency: 0 }),
  },
];
export const statisticsColumns = columns.filter(
  i => ["total_times", "total_succeeded_times"].indexOf(i.dataIndex) !== -1,
);

const excelPriceFormat = (val, currency) =>
  priceFormat({ val, currency, showUnit: false });

export const exportColumnsRenderMap = {
  created: val => dateFormat(val, "YYYY-MM-DD"),
  is_online: val => isActiveLang(val),
  currency: val => Currency[val] || "",
  total_amount: (val, record) => excelPriceFormat(val, record.currency),
  total_succeeded_amount: (val, record) =>
    excelPriceFormat(val, record.currency),
  total_fee: (val, record) => excelPriceFormat(val, record.currency),
  gateway_balance: (val, record) => excelPriceFormat(val, record.currency),
  total_amount_gateway_fee: (val, record) =>
    excelPriceFormat(val, record.currency),
  total_gateway_balance: (val, record) =>
    excelPriceFormat(val, record.currency),
};
