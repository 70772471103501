import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "@/utils/request";
import { metaToPagin } from "@/utils/format";

export const getAppGateways = createAsyncThunk(
  "appGateway/getList",
  async (params = {}) => {
    const res = await request({
      url: `/api/appgateways`,
      method: "get",
      params,
    });
    return res;
  },
);

export const postAppGateway = async data => {
  const res = await request({
    url: `/api/appgateway`,
    method: "post",
    data,
  });
  return res;
};

export const deleteAppGateway = async id => {
  const res = await request({
    url: `/api/appgateway/${id}`,
    method: "delete",
  });
  return res;
};

export const slice = createSlice({
  name: "appGateway",
  initialState: {
    list: [],
    meta: {},
    currentRow: {},
  },
  extraReducers: {
    [getAppGateways.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.list = data.data;
      state.meta = metaToPagin(data.meta);
    },
    // [getApp.fulfilled]: (state, action) => {
    //   const { status, data } = action.payload;
    //   if (status !== 200) return;
    //   state.currentRow = data;
    // },
  },
});
// export const { setAppGateway } = slice.actions;
export const selectAppGateway = state => state.appGateway;
export default slice.reducer;
