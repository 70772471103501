import React, { useEffect, useState } from "react";
import { Card, Form, Spin, message, Button, Input } from "antd";
import {
    getConfigCustomerService,
    postConfigCustomerService,
} from "@/store/slice/config";
import { useUserPerms } from "@/utils/hook";

// 设置客服网址
function CustomerService({ formLayout }) {
    const perms = useUserPerms();
    const [customerServiceLdg, setcustomerServiceLdg] = useState(false);
    const [customerServiceForm] = Form.useForm();

    const onFinishCustomerService = async ({ url }) => {
        setcustomerServiceLdg(true);
        const {status} = await postConfigCustomerService({ url: url })
        setcustomerServiceLdg(false)
        if (status === 200) {
            message.success("更新成功");
            handleGetcustomerServiceConfig();
        }
    };

    const [customerServiceData, setCustomerServiceData] = useState({});
    const handleGetcustomerServiceConfig = async() => {
        setcustomerServiceLdg(true);
        const { status, data } = await getConfigCustomerService()
        setcustomerServiceLdg(false);
        if (status === 200) setCustomerServiceData({ ...data });
         customerServiceForm.setFieldsValue({ ...data });
    };

    useEffect(() => {
        handleGetcustomerServiceConfig();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validateUrl = (rule, value) => {
        return new Promise((resolve, reject) => {
            const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
            if (value && !urlPattern.test(value)) {
                reject("網址格式不正確");
            } else {
                resolve();
            }
        });
    };

    return (
        <Card title="设置客服网址" size="small">
            <Spin spinning={customerServiceLdg}>
                <Form
                    form={customerServiceForm}
                    onFinish={onFinishCustomerService}
                    initialValues={{ url: customerServiceData.url }}
                    {...formLayout}
                >
                    <Form.Item
                        name="url"
                        label="网址"
                        rules={[
                            {
                                required: true,
                                message: "请输入网址",
                            },
                            {
                                validator: validateUrl,
                            },
                        ]}
                    >
                        <Input value={customerServiceData.url} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={!perms["config.customer_service_update"]}
                        >
                            确认提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </Card>
    );
}

export default CustomerService;