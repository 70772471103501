import { dateFormat, priceFormat } from "@/utils/format";
import { Currency, isActiveLang } from "@/utils/enum";
import Tag from "@/components/Tag";

const moneyRender = () => {
  return {
    render: (val, record) =>
      priceFormat({ val: val || 0, currency: record.currency }),
    className: "text-nowrap",
    sorter: true,
  };
};

export const columns = [
  // { title: "ID", dataIndex: "id", sorter: true },
  { title: "商户ID", dataIndex: "app_id", sorter: true },
  { title: "商户名称", dataIndex: "app_name", sorter: true },
  {
    title: "报表时间",
    dataIndex: "created",
    className: "text-nowrap",
    render: val => dateFormat(val, "YYYY-MM-DD"),
    sorter: true,
  },
  {
    title: "是否线上渠道",
    dataIndex: "is_online",
    render: val => <Tag val={val} />,
    sorter: true,
  },
  {
    title: "货币类型",
    dataIndex: "currency",
    render: val => Currency[val] || "",
    className: "text-nowrap",
  },
  {
    title: "帐户余额",
    dataIndex: "total_balance",
    ...moneyRender(),
  },
  {
    title: "圈存余额",
    dataIndex: "total_block",
    ...moneyRender(),
  },
  {
    title: "累计订单次数",
    dataIndex: "total_order_times",
    sorter: true,
  },
  {
    title: "累计成功次数",
    dataIndex: "total_succeeded_times",
    sorter: true,
  },
  {
    title: "累计金额",
    dataIndex: "total_order_amount",
    ...moneyRender(),
  },
  {
    title: "累计成功金额",
    dataIndex: "total_succeeded_amount",
    ...moneyRender(),
  },
  {
    title: "提交代付笔数",
    dataIndex: "total_trans_times",
    sorter: true,
  },
  {
    title: "成功代付笔数",
    dataIndex: "transfer_succeeded_times",
    sorter: true,
  },
  {
    title: "提交代付金额",
    dataIndex: "total_trans_amount",
    ...moneyRender(),
  },
  {
    title: "成功代付金额",
    dataIndex: "transfer_succeeded_amount",
    ...moneyRender(),
  },
  {
    title: "存提差",
    dataIndex: "difference",
    ...moneyRender(),
  },
  {
    title: "代收手续费",
    dataIndex: "order_fee",
    ...moneyRender(),
  },
  {
    title: "代付手续费",
    dataIndex: "transfer_fee",
    ...moneyRender(),
  },
  {
    title: "今天盈利",
    dataIndex: "profit_today",
    ...moneyRender(),
  },
  {
    title: "实际代收手续费",
    dataIndex: "total_amount_gateway_fee",
    ...moneyRender(),
  },
  {
    title: "实际代付手续费",
    dataIndex: "transfer_succeeded_gateway_fee",
    ...moneyRender(),
  },
  {
    title: "YL盈利",
    dataIndex: "yl_profit_today",
    ...moneyRender(),
  },
  // {
  //   title: "提交代收人数",
  //   dataIndex: "order_users",
  //   sorter: true,
  // },
  // {
  //   title: "成功代收人数",
  //   dataIndex: "order_succeeded_users",
  //   sorter: true,
  // },
  // {
  //   title: "代收支出分润金额",
  //   dataIndex: "total_order_out_profit",
  //   ...moneyRender(),
  // },
  // {
  //   title: "代收收入分润金额",
  //   dataIndex: "total_order_profit",
  //   ...moneyRender(),
  // },
  // {
  //   title: "提交代付人数",
  //   dataIndex: "transfer_users",
  //   sorter: true,
  // },
  // {
  //   title: "成功代付人数",
  //   dataIndex: "transfer_succeeded_users",
  //   sorter: true,
  // },
  // {
  //   title: "代付订单支出手续费",
  //   dataIndex: "total_trans_fee",
  //   ...moneyRender(),
  // },
  // {
  //   title: "代付成功支付商户手续费",
  //   dataIndex: "transfer_succeeded_app_fee",
  //   ...moneyRender(),
  // },
  // {
  //   title: "代付成功支付渠道手续费",
  //   dataIndex: "transfer_succeeded_gateway_fee",
  //   ...moneyRender(),
  // },
  // {
  //   title: "累计分润金额",
  //   dataIndex: "total_profit_amount",
  //   ...moneyRender(),
  // },
  // {
  //   title: "代付订单数",
  //   dataIndex: "transfer_times",
  //   sorter: true,
  // },
  // {
  //   title: "代付订单金额",
  //   dataIndex: "transfer_amount",
  //   ...moneyRender(),
  // },
];
export const sumColumns = [
  {
    title: "订单成功次数",
    dataIndex: "order_succeeded_times",
  },
  {
    title: "订单成功金额",
    dataIndex: "order_succeeded_amount",
    render: val => priceFormat({ val, currency: 0 }),
  },
  {
    title: "提交代付笔数",
    dataIndex: "total_trans_times",
  },
  {
    title: "提交代付金额",
    dataIndex: "total_trans_amount",
    render: val => priceFormat({ val, currency: 0 }),
  },
  {
    title: "成功代付笔数",
    dataIndex: "transfer_succeeded_times",
  },
  {
    title: "成功代付金额",
    dataIndex: "transfer_succeeded_amount",
    render: val => priceFormat({ val, currency: 0 }),
  },
  {
    title: "代付成功支付商户手续费",
    dataIndex: "transfer_succeeded_app_fee",
    render: val => priceFormat({ val, currency: 0 }),
  },
  {
    title: "实际代付手续费",
    dataIndex: "transfer_succeeded_gateway_fee",
    render: val => priceFormat({ val, currency: 0 }),
  },
  {
    title: "实际代收手续费",
    dataIndex: "total_amount_gateway_fee",
    render: val => priceFormat({ val, currency: 0 }),
  },
  {
    title: "代收收入分润金额",
    dataIndex: "total_order_profit",
    render: val => priceFormat({ val, currency: 0 }),
  },
  {
    title: "总代付支出分润金额",
    dataIndex: "total_order_out_profit",
    render: val => priceFormat({ val, currency: 0 }),
  },
  {
    title: "代付订单支出手续费",
    dataIndex: "total_trans_fee",
    render: val => priceFormat({ val, currency: 0 }),
  },
];
export const statisticsColumns = columns.filter(
  i =>
    [
      "total_order_times",
      "total_succeeded_times",
      "total_trans_times",
      "transfer_succeeded_times",
      "transfer_users",
      "transfer_succeeded_users",
      "order_users",
      "order_succeeded_users",
      "total_trans_times",
    ].indexOf(i.dataIndex) !== -1,
);

const excelPriceFormat = (val, currency) =>
  priceFormat({ val, currency, showUnit: false });

export const exportColumnsRenderMap = {
  created: val => dateFormat(val, "YYYY-MM-DD"),
  is_online: val => isActiveLang(val),
  currency: val => Currency[val] || "",
  total_balance: (val, record) => excelPriceFormat(val, record.currency),
  total_block: (val, record) => excelPriceFormat(val, record.currency),
  total_order_amount: (val, record) => excelPriceFormat(val, record.currency),
  total_succeeded_amount: (val, record) =>
    excelPriceFormat(val, record.currency),
  total_trans_amount: (val, record) => excelPriceFormat(val, record.currency),
  transfer_succeeded_amount: (val, record) =>
    excelPriceFormat(val, record.currency),
  difference: (val, record) => excelPriceFormat(val, record.currency),
  order_fee: (val, record) => excelPriceFormat(val, record.currency),
  transfer_fee: (val, record) => excelPriceFormat(val, record.currency),
  profit_today: (val, record) => excelPriceFormat(val, record.currency),
  total_amount_gateway_fee: (val, record) =>
    excelPriceFormat(val, record.currency),
  transfer_succeeded_gateway_fee: (val, record) =>
    excelPriceFormat(val, record.currency),
  yl_profit_today: (val, record) => excelPriceFormat(val, record.currency),
};
