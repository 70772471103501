import Tag from "@/components/Tag";
import { Currency, isActiveLang } from "@/utils/enum";
import { dateFormat, priceFormat } from "@/utils/format";

const moneyRender = () => {
  return {
    render: (val, record) =>
      priceFormat({ val: val || 0, currency: record.currency }),
    className: "text-nowrap",
    sorter: true,
  };
};

export const columns = [
  { title: "ID", dataIndex: "id", sorter: true },
  { title: "商户ID", dataIndex: "app_id", sorter: true },
  { title: "商户名称", dataIndex: "app_name", sorter: true },
  {
    title: "是否线上渠道",
    dataIndex: "is_online",
    render: val => <Tag val={val} />,
    sorter: true,
  },
  {
    title: "货币类型",
    dataIndex: "currency",
    render: val => Currency[val] || "",
    className: "text-nowrap",
  },
  {
    title: "报表时间",
    dataIndex: "created",
    className: "text-nowrap",
    render: val => dateFormat(val, "YYYY-MM-DD"),
    sorter: true,
  },
  {
    title: "代收盈利",
    dataIndex: "order_profit_amount",
    ...moneyRender(),
  },
  {
    title: "代付盈利",
    dataIndex: "transfer_profit_amount",
    ...moneyRender(),
  },
  {
    title: "合计盈利",
    dataIndex: "subtotal_profit",
    ...moneyRender(),
  },
  {
    title: "总盈利",
    dataIndex: "total_profit",
    ...moneyRender(),
  },
];

export const sumColumns = [
  {
    title: "货币类型",
    dataIndex: "currency",
    render: val => Currency[val] || "",
  },
  {
    title: "总代收盈利",
    dataIndex: "total_order_profit_amount",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
  },
  {
    title: "总代收盈利",
    dataIndex: "total_order_profit_amount",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
  },
  {
    title: "总代付盈利",
    dataIndex: "total_transfer_profit_amount",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
  },
  {
    title: "总合计盈利",
    dataIndex: "total_subtotal_profit",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
  },
];

export const statisticsColumns = [];

const excelPriceFormat = (val, currency) =>
  priceFormat({ val, currency, showUnit: false });
export const exportColumnsRenderMap = {
  created: val => dateFormat(val, "YYYY-MM-DD"),
  is_online: val => isActiveLang(val),
  currency: val => Currency[val] || "",
  order_profit_amount: (val, record) => excelPriceFormat(val, record.currency),
  transfer_profit_amount: (val, record) =>
    excelPriceFormat(val, record.currency),
  subtotal_profit: (val, record) => excelPriceFormat(val, record.currency),
  total_profit: (val, record) => excelPriceFormat(val, record.currency),
};
