import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "@/utils/request";
import { metaToPagin } from "@/utils/format";

export const getAppGroups = createAsyncThunk(
  "appGroup/getList",
  async (params = {}) => {
    const res = await request({
      url: "/api/app_groups",
      method: "get",
      params,
    });
    return res;
  },
);

export const getAppGroup = createAsyncThunk("appGroup/getDetail", async id => {
  const res = await request({
    url: `/api/app_groups/${id}`,
    method: "get",
  });
  return res;
});

export const addAppGroup = async data => {
  const res = await request({
    url: `/api/app_groups`,
    method: "post",
    data,
  });
  return res;
};

export const editAppGroup = async params => {
  const res = await request({
    url: `/api/app_groups/${params.id}`,
    method: "post",
    data: params.formModel,
  });
  return res;
};

export const deleteAppGroup = async id => {
  const res = await request({
    url: `/api/app_groups/${id}`,
    method: "delete",
  });
  return res;
};

export const slice = createSlice({
  name: "appGroup",
  initialState: {
    list: [],
    meta: {},
    currentRow: {},
  },
  extraReducers: {
    [getAppGroups.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.list = data.data;
      state.meta = metaToPagin(data.meta);
    },
    [getAppGroup.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.currentRow = data;
    },
  },
});
export const { setAppGroup } = slice.actions;
export const selectAppGroup = state => state.appGroup;
export default slice.reducer;
