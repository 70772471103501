import React, { useState } from "react";
import { Space, Button, Modal, Form, Spin, Input, Select, message } from "antd";
import { SearchFormFactory } from "@/components/factory/FormFactory";
import {
  getCardBankLog,
  selectCardBankLog,
  postCardBankLog,
} from "@/store/slice/cardBankLog";
import { useList, useUserPerms } from "@/utils/hook";
import { dateFormat, priceFormat } from "@/utils/format";
import { BankPayType, AuditType, IsBoolEnum, formLayout } from "@/utils/enum";
import { NormalTable } from "@/components/factory/TableFactory";
import JsonModal from "@/components/JsonModal";

function CardBankLog() {
  const perms = useUserPerms();
  const searchFields = {
    id__in: { type: "string", label: "ID" },
    bank_name__k: { type: "string", label: "开户行中文名" },
    bank_code__in: { type: "string", label: "开户行代码" },
    order_no__in: { type: "string", label: "订单号" },
    audit_status: {
      type: "select",
      label: "审计类型",
      options: AuditType,
    },
    created__btw: { type: "rangeDate", label: "创建日期" },
  };
  const {
    res: { list, meta },
    loading: listLoading,
    handleGetList,
    handleChangePage,
    handleChange,
  } = useList(getCardBankLog, selectCardBankLog);

  const [currentRow, setCurrentRow] = useState(null);
  const [jsonVisible, setJsonVisible] = useState(false);
  const handleJsonClick = record => {
    setCurrentRow(record);
    setJsonVisible(true);
  };

  const [form] = Form.useForm();
  const [detailLoading, setDetailLoading] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const handleEditClick = async record => {
    setCurrentRow(record);
    await form.setFieldsValue({ ...record });
    setEditVisible(true);
  };

  const handleEditOk = async () => {
    const formModel = await form.validateFields();
    setDetailLoading(true);
    const { status } = await postCardBankLog({ id: currentRow.id, formModel });
    setDetailLoading(false);
    if (status !== 200) return;
    message.success("更新成功！");
    setEditVisible(false);
    handleGetList();
  };

  const columns = [
    { title: "ID", dataIndex: "id", sorter: true },
    { title: "开户行中文名", dataIndex: "bank_name" },
    { title: "开户行代码", dataIndex: "bank_code" },
    { title: "银行账号", dataIndex: "account" },
    {
      title: "金额",
      dataIndex: "amount",
      render: val => priceFormat({ val, currency: 0 }),
      className: "text-nowrap",
    },
    {
      title: "类型",
      dataIndex: "record_type",
      render: val => BankPayType[val],
    },
    {
      title: "帐务记录日期",
      dataIndex: "date",
      render: val => dateFormat(val),
    },
    { title: "订单号", dataIndex: "order_no" },
    {
      title: "审计类型",
      dataIndex: "audit_status",
      render: val => AuditType[val],
    },
    { title: "备注", dataIndex: "remark" },
    {
      title: "是否手动",
      dataIndex: "is_manual",
      render: val => IsBoolEnum[val ? 1 : 0],
    },
    {
      title: "创建日期",
      dataIndex: "created",
      className: "text-nowrap",
      render: val => dateFormat(val),
      sorter: true,
    },
    {
      title: "更新日期",
      dataIndex: "updated",
      className: "text-nowrap",
      render: val => dateFormat(val),
      sorter: true,
    },
    {
      title: "动作",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      render: (_, record) => (
        <Space>
          <Button
            size="small"
            onClick={() => handleJsonClick(record)}
            type="link"
            className="p-0"
          >
            json
          </Button>
          <Button
            size="small"
            onClick={() => handleEditClick(record)}
            type="text"
            className="p-0"
            disabled={!perms["bankrecord.Update"]}
          >
            编辑
          </Button>
        </Space>
      ),
    },
  ];
  const defaultColumns = [
    "id",
    "bank_name",
    "bank_code",
    "account",
    "amount",
    "order_no",
    "action",
  ];

  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchFormFactory fields={searchFields} handleSubmit={handleGetList} />
      <NormalTable
        allColumns={columns}
        defaultColumns={defaultColumns}
        dataSource={list}
        meta={meta}
        onChangePage={handleChangePage}
        onChange={handleChange}
        loading={listLoading}
        onShowSizeChange={handleChangePage}
      />
      <JsonModal
        width={650}
        visible={jsonVisible}
        data={currentRow}
        onCancel={() => setJsonVisible(false)}
        loading={false}
      />
      <Modal
        width="600px"
        title="编辑"
        visible={editVisible}
        onOk={handleEditOk}
        onCancel={() => setEditVisible(false)}
        cancelText="取消"
        okText="送出"
        destroyOnClose
      >
        <Spin spinning={detailLoading}>
          <Form {...formLayout} form={form}>
            <Form.Item label="ID">{currentRow?.id}</Form.Item>
            <Form.Item name="audit_status" label="审计类型">
              <Select>
                {Object.keys(AuditType).map(i => (
                  <Select.Option key={i} value={Number(i)}>
                    {AuditType[i]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="order_no"
              label="订单号"
              rules={[{ required: true, message: "请输入订单号" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="remark" label="备注">
              <Input />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </Space>
  );
}

export default CardBankLog;
