import Order from "@/views/Order";
const getComponent = name => {
  try {
    let cpn = require(`@/views/${name}`);
    return cpn.default();
  } catch (e) {
    console.log(e);
  }
};
const routes = [
  {
    path: "/",
    name: "User",
    perm: "user.List",
    component: () => getComponent("User"),
    exact: true,
    displayName: "用户管理",
  },
  {
    path: "/Role",
    name: "Role",
    perm: "role.List",
    component: () => getComponent("Role"),
    displayName: "角色管理",
  },
  {
    path: "/RolePerm/:id",
    name: "RolePerm",
    perm: "",
    component: () => getComponent("Role/EditPerm"),
    displayName: "权限设置",
  },
  {
    path: "/Developer",
    name: "Developer",
    perm: "",
    component: () => getComponent("Developer"),
    displayName: "开发商管理",
  },
  {
    path: "/AppGroup",
    name: "AppGroup",
    perm: "appGroup.List",
    component: () => getComponent("AppGroup"),
    displayName: "群组管理",
  },
  {
    path: "/AppUser",
    name: "AppUser",
    perm: "appuser.List",
    component: () => getComponent("AppUser"),
    displayName: "商户用户",
  },
  {
    path: "/App",
    name: "App",
    perm: "app.List",
    component: () => getComponent("App"),
    displayName: "商户管理",
  },
  {
    path: "/AppAcct",
    name: "AppAcct",
    perm: "appacct.List",
    component: () => getComponent("AppAcct"),
    displayName: "商户账户",
  },
  {
    path: "/Gateway",
    name: "Gateway",
    perm: "gateway.List",
    component: () => getComponent("Gateway"),
    displayName: "支付管理",
  },
  {
    path: "/Gateway1",
    name: "Gateway1",
    perm: "",
    component: () => getComponent("Gateway/indexType1"),
    displayName: "银行卡支付",
  },
  {
    path: "/Gateway2",
    name: "Gateway2",
    perm: "",
    component: () => getComponent("Gateway/indexType2"),
    displayName: "第三方支付",
  },
  {
    path: "/Gateway3",
    name: "Gateway3",
    perm: "",
    component: () => getComponent("Gateway/indexType3"),
    displayName: "第三方代付",
  },
  {
    path: "/Gateway4",
    name: "Gateway4",
    perm: "",
    component: () => getComponent("Gateway/indexPaytype4"),
    displayName: "加密货币支付",
  },
  {
    path: "/GatewayAcct",
    name: "GatewayAcct",
    perm: "gatewayacct.List",
    component: () => getComponent("GatewayAcct"),
    displayName: "第三方余额帐户",
  },
  {
    path: "/Order",
    name: "Order",
    perm: "order.List",
    component: () => <Order />,
    displayName: "全部订单",
  },
  {
    path: "/OrderDetail/:id",
    name: "OrderDetail",
    perm: "",
    component: () => getComponent("Order/Detail"),
    displayName: "订单明细",
    hidePageHeader: true,
  },
  {
    path: "/CryptoWallet",
    name: "CryptoWallet",
    perm: "cryptowallet.List",
    component: () => getComponent("CryptoWallet"),
    displayName: "钱包管理",
  },
  {
    path: "/CryptoWalletEdit/:id",
    name: "CryptoWalletEdit",
    component: () => getComponent("CryptoWallet/Edit"),
    displayName: "编辑钱包",
  },
  {
    path: "/CryptoAcct",
    name: "CryptoAcct",
    perm: "cryptoacct.List",
    component: () => getComponent("CryptoAcct"),
    displayName: "收款地址管理",
  },
  {
    path: "/CryptoAcctLog",
    name: "CryptoAcctLog",
    perm: "cryptoacctlog.List",
    component: () => getComponent("CryptoAcct/CryptoAcctLog"),
    displayName: "资金动账记录",
  },
  {
    path: "/Bank",
    name: "Bank",
    perm: "bank.List",
    component: () => getComponent("Bank"),
    displayName: "银行管理",
  },
  {
    path: "/Card",
    name: "Card",
    perm: "card.List",
    component: () => getComponent("Card"),
    displayName: "银行卡管理",
  },
  {
    path: "/CardAcct",
    name: "CardAcct",
    perm: "cardacct.List",
    component: () => getComponent("CardAcct"),
    displayName: "银行卡账户管理",
  },
  {
    path: "/CardAcctLog",
    name: "CardAcctLog",
    perm: "cardacctlog.List",
    component: () => getComponent("CardAcct/CardAcctLog"),
    displayName: "银行卡账变日志",
  },
  {
    path: "/CardBankLog",
    name: "CardBankLog",
    perm: "bankrecord.List",
    component: () => getComponent("CardAcct/CardBankLog"),
    displayName: "银行卡网银纪录",
  },
  {
    path: "/Agent",
    name: "Agent",
    perm: "agent.List",
    component: () => getComponent("Agent"),
    displayName: "代理管理",
  },
  {
    path: "/AgentAcct",
    name: "AgentAcct",
    perm: "agentacct.List",
    component: () => getComponent("AgentAcct"),
    displayName: "代理帐户",
  },
  {
    path: "/LoginLog",
    name: "LoginLog",
    perm: "loginlog.List",
    component: () => getComponent("LoginLog"),
    displayName: "登录日志",
  },
  {
    path: "/Audit",
    name: "Audit",
    perm: "audit.List",
    component: () => getComponent("Audit"),
    displayName: "审计日志",
  },
  {
    path: "/AgentDaily",
    name: "AgentDaily",
    perm: "agent.daily",
    component: () => getComponent("Report/AgentDaily"),
    displayName: "代理日报",
  },
  // {
  //   path: "/DeveloperDaily",
  //   name: "DeveloperDaily",
  //   perm: "developer.daily",
  //   component: () => getComponent("Report/DeveloperDaily"),
  //   displayName: "开发者日报",
  // },
  {
    path: "/OrderDaily",
    name: "OrderDaily",
    perm: "order.daily",
    component: () => getComponent("Report/OrderDaily"),
    displayName: "订单日报",
  },
  {
    path: "/AppDaily",
    name: "AppDaily",
    perm: "app.daily",
    component: () => getComponent("Report/AppDaily"),
    displayName: "商户报表",
  },
  {
    path: "/TransferAppDaily",
    name: "TransferAppDaily",
    perm: "transfer.app.daily",
    component: () => getComponent("Report/TransferAppDaily"),
    displayName: "代付商户报表",
  },
  {
    path: "/GatewayDaily",
    name: "GatewayDaily",
    perm: "gateway.daily",
    component: () => getComponent("Report/GatewayDaily"),
    displayName: "渠道报表",
  },
  {
    path: "/AppGatewayDaily",
    name: "AppGatewayDaily",
    perm: "app.gateway.daily",
    component: () => getComponent("Report/AppGatewayDaily"),
    displayName: "支付商户渠道报表",
  },
  {
    path: "/TransferAppGatewayDaily",
    name: "TransferAppGatewayDaily",
    perm: "transfer.app.gateway.daily",
    component: () => getComponent("Report/TransferAppGatewayDaily"),
    displayName: "代付商户渠道报表",
  },
  {
    path: "/AppProfitSummary",
    name: "AppProfitSummary",
    perm: "app.profit.summary",
    component: () => getComponent("Report/AppProfitSummary"),
    displayName: "支付盈利汇总报表",
  },
  {
    path: "/AppBalanceSummary",
    name: "AppBalanceSummary",
    perm: "app.profit.summary",
    component: () => getComponent("Report/AppBalanceSummary"),
    displayName: "商户余额汇总报表",
  },
  {
    path: "/USDTExchange",
    name: "USDTExchange",
    perm: "config.exchange",
    component: () => getComponent("USDTExchange"),
    displayName: "USDT 汇率设置",
  },
  {
    path: "/Transfers",
    name: "Transfers",
    perm: "transfer.List",
    component: () => getComponent("Transfers"),
    displayName: "查看代付",
  },
  {
    path: "/TransfersStatus2",
    name: "TransfersStatus2",
    perm: "",
    component: () => getComponent("Transfers/indexStatus2"),
    displayName: "申请中的代付",
  },
  {
    path: "/TransfersStatus7",
    name: "TransfersStatus7",
    perm: "",
    component: () => getComponent("Transfers/indexStatus7"),
    displayName: "我认领的汇款",
  },
  {
    path: "/TransfersStatus8",
    name: "TransfersStatus8",
    perm: "",
    component: () => getComponent("Transfers/indexStatus8"),
    displayName: "我的出款中列表",
  },
  {
    path: "/SystemSetting",
    name: "SystemSetting",
    perm: "",
    component: () => getComponent("SystemSetting"),
    displayName: "系统设置",
  },
  {
    path: "/TransfersAgent",
    name: "TransfersAgent",
    perm: "transfer.ListApp",
    component: () => getComponent("TransfersAgent"),
    displayName: "查看代理提款",
  },
  {
    path: "/TransfersAgentStatus2",
    name: "TransfersAgentStatus2",
    perm: "",
    component: () => getComponent("TransfersAgent/indexStatus2"),
    displayName: "申请中的代理提款",
  },
  {
    path: "/TransfersAgentStatus7",
    name: "TransfersAgentStatus7",
    perm: "",
    component: () => getComponent("TransfersAgent/indexStatus7"),
    displayName: "我认领的代理汇款",
  },
  {
    path: "/TransfersAgentStatus8",
    name: "TransfersAgentStatus8",
    perm: "",
    component: () => getComponent("TransfersAgent/indexStatus8"),
    displayName: "我的出款中列表",
  },

  {
    path: "/TransfersApp",
    name: "TransfersApp",
    perm: "transfer.ListApp",
    component: () => getComponent("TransfersApp"),
    displayName: "查看商户提款",
  },
  {
    path: "/TransfersAppStatus2",
    name: "TransfersAppStatus2",
    perm: "",
    component: () => getComponent("TransfersApp/indexStatus2"),
    displayName: "申请中的商户提款",
  },
  {
    path: "/TransfersAppStatus7",
    name: "TransfersAppStatus7",
    perm: "",
    component: () => getComponent("TransfersApp/indexStatus7"),
    displayName: "我认领的商户汇款",
  },
  {
    path: "/TransfersAppStatus8",
    name: "TransfersAppStatus8",
    perm: "",
    component: () => getComponent("TransfersApp/indexStatus8"),
    displayName: "我的出款中列表",
  },
  {
    path: "/OrderAgent",
    name: "OrderAgent",
    perm: "",
    component: () => getComponent("OrderAgent"),
    displayName: "代理出售USDT",
  },
  {
    path: "/AgentWhitelist",
    name: "AgentWhitelist",
    perm: "agentwhitelist.List",
    component: () => getComponent("Whitelist/AgentWhitelist"),
    displayName: "代理白名单",
  },
  {
    path: "/DeveloperWhitelist",
    name: "DeveloperWhitelist",
    perm: "developerwhitelist.List",
    component: () => getComponent("Whitelist/DeveloperWhitelist"),
    displayName: "商户白名单",
  },
  {
    path: "/Tool",
    name: "Tool",
    perm: "",
    component: () => getComponent("Tool"),
    displayName: "小工具",
  },
  {
    path: "/AppAcctLog",
    name: "AppAcctLog",
    perm: "appacctlog.List",
    component: () => getComponent("FundsLog/AppAcctLog"),
    displayName: "商户资金记录",
  },
  {
    path: "/AgentAcctLog",
    name: "AgentAcctLog",
    perm: "agentacctlog.List",
    component: () => getComponent("FundsLog/AgentAcctLog"),
    displayName: "代理帐户资金纪录",
  },
  {
    path: "/AcctSwapLog",
    name: "AcctSwapLog",
    perm: "acctswaplog.List",
    component: () => getComponent("FundsLog/AcctSwapLog"),
    displayName: "余额置换记录",
  },
  {
    path: "/GatewayAcctLogs",
    name: "GatewayAcctLogs",
    perm: "gatewayacctlog.List",
    component: () => getComponent("FundsLog/GatewayAcctLogs"),
    displayName: "第三方余额帐户纪录",
  },
  {
    path: "/AgentNotices",
    name: "AgentNotices",
    perm: "agent_notice.List",
    component: () => getComponent("AgentNotice"),
    displayName: "代理公告设置",
  },
  {
    path: "/Products",
    name: "Products",
    perm: "product.List",
    component: () => getComponent("Product"),
    displayName: "商品管理",
  },
  {
    path: "/Product/Images",
    name: "ProductImages",
    perm: "product_image.List",
    component: () => getComponent("ProductImage"),
    displayName: "商品圖片管理",
  },
];
export default routes;
