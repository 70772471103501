import React, { useState } from "react";
import { Button, Space } from "antd";
import { useList, useDetail, useUserPerms } from "@/utils/hook";
import {
  getAcctSwapLog,
  getAcctSwap,
  selectAcctSwapLog,
} from "@/store/slice/acctSwapLog";
import { SearchFormFactory } from "@/components/factory/FormFactory";
import { NormalTable } from "@/components/factory/TableFactory";
import Detail from "@/components/Detail";
import { Currency, AcctSwapType, AcctSwapUserType } from "@/utils/enum";
import { priceFormat, dateFormat } from "@/utils/format";

const UserType = { 1: "商戶", 2: "代理" };

function AcctSwapLog() {
  const perms = useUserPerms();
  const searchFields = {
    source_user_id__in: { type: "string", label: "划转方ID" },
    source_user_name__k: { type: "string", label: "划转方名称" },
    source_user_type__eq: {
      type: "select",
      label: "划转方类型",
      options: UserType,
    },
    target_user__in: { type: "string", label: "收款方ID" },
    target_user_name__k: { type: "string", label: "收款方名称" },
    target_user_type__eq: {
      type: "select",
      label: "收款方类型",
      options: UserType,
    },
    op_user_id__in: { type: "string", label: "操作者ID" },
    type: { type: "select", label: "纪录类型", options: AcctSwapType },
    created__btw: { type: "rangeDate", label: "创建日期" },
  };
  const {
    res: { list, meta },
    loading: listLoading,
    handleSearch,
    handleChangePage,
    handleChange,
  } = useList(getAcctSwapLog, selectAcctSwapLog);

  const [detailId, setDetailId] = useState(null);
  const { currentRow, loading: detailLoading } = useDetail(
    { action: getAcctSwap, id: detailId },
    selectAcctSwapLog,
  );
  const [detailVisible, setDetailVisible] = useState(false);
  const handleDetailClick = id => {
    setDetailId(id);
    setDetailVisible(true);
  };

  const columns = [
    { title: "ID", dataIndex: "id", sorter: true },
    {
      title: "划转方",
      dataIndex: "source_user_username",
      render: (_, record) => `${_}(${record.source_user_name})`,
    },
    {
      title: "划转方类型",
      dataIndex: "source_user_type",
      render: val => AcctSwapUserType[val],
    },
    {
      title: "收款方",
      dataIndex: "target_user_username",
      render: (_, record) => `${_}(${record.target_user_name})`,
    },
    {
      title: "收款方类型",
      dataIndex: "target_user_type",
      render: val => AcctSwapUserType[val],
    },
    {
      title: "操作者",
      dataIndex: "op_user_name",
    },
    {
      title: "金额",
      dataIndex: "amount",
      render: (val, record) => priceFormat({ val, currency: record.currency }),
      className: "text-nowrap",
      sorter: true,
    },
    {
      title: "货币类型",
      dataIndex: "currency",
      render: val => Currency[val] || "",
      className: "text-nowrap",
    },
    {
      title: "创建日期",
      dataIndex: "created",
      render: val => dateFormat(val),
      className: "text-nowrap",
      sorter: true,
    },
    {
      title: "纪录类型",
      dataIndex: "type",
      render: val => AcctSwapType[val],
      className: "text-nowrap",
    },
    { title: "备注", dataIndex: "note" },
    {
      title: "动作",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      render: (_, record) => (
        <Space>
          <Button
            size="small"
            onClick={() => handleDetailClick(record.id)}
            type="link"
            className="p-0"
            disabled={!perms["acctswaplog.Get"]}
          >
            查看
          </Button>
        </Space>
      ),
    },
  ];
  const defaultColumns = [
    "id",
    "source_user_username",
    "target_user_username",
    "amount",
    "currency",
    "created",
    "type",
    "action",
  ];
  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchFormFactory fields={searchFields} handleSubmit={handleSearch} />
      <NormalTable
        allColumns={columns}
        defaultColumns={defaultColumns}
        dataSource={list}
        meta={meta}
        onChangePage={handleChangePage}
        onChange={handleChange}
        loading={listLoading}
        onShowSizeChange={handleChangePage}
      />
      <Detail
        title="划转账户明细"
        visible={detailVisible}
        data={currentRow}
        onCancel={() => setDetailVisible(false)}
        loading={detailLoading}
        columns={columns.filter(i => i.dataIndex !== "action")}
      />
    </Space>
  );
}

export default AcctSwapLog;
