import { useState, useEffect } from "react";
import { Tree, Card, Space, Spin, Button, message } from "antd";
import {
  PermsPageTree,
  RoleKeys,
  getDefaultCheckKeys,
} from "@/utils/enum/perms";
import { useParams } from "react-router-dom";
import { selectRole, getRole, editPerms } from "@/store/slice/role";
import { useDetail } from "@/utils/hook";

const EditPerm = () => {
  const { id } = useParams();
  const { currentRow, loading, setLoading } = useDetail(
    { action: getRole, id },
    selectRole,
  );
  const [selectedKeys, setSelectedKeys] = useState([]);
  const handleCheck = checkedKeys => {
    setSelectedKeys(checkedKeys);
  };

  const handleOk = async () => {
    setLoading(true);
    let perms = {};
    RoleKeys.forEach(key => {
      perms[key] = selectedKeys.indexOf(key) >= 0;
    });
    const { status } = await editPerms({ id, perms });
    setLoading(false);
    status === 200 && message.success("更新成功！");
  };

  useEffect(() => {
    if (currentRow.perms) {
      setSelectedKeys(getDefaultCheckKeys(currentRow.perms));
    }
  }, [currentRow]);

  return (
    <Spin spinning={loading}>
      <Space direction="vertical" className="w-100">
        <Card>
          <h2>{currentRow.name}</h2>
          <Tree
            showIcon
            checkable
            treeData={PermsPageTree}
            onCheck={handleCheck}
            checkedKeys={selectedKeys}
          />
        </Card>
        <div className="text-right">
          <Button type="primary" onClick={handleOk}>
            储存
          </Button>
        </div>
      </Space>
    </Spin>
  );
};

export default EditPerm;
