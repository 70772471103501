import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "@/utils/request";
import { metaToPagin } from "@/utils/format";

export const getBanks = createAsyncThunk(
  "bank/getList",
  async (params = {}) => {
    const res = await request({ url: "/api/banks", method: "get", params });
    return res;
  },
);
export const getBank = createAsyncThunk("bank/getDetail", async id => {
  const res = await request({ url: `/api/banks/${id}`, method: "get" });
  return res;
});
export const addBank = async data => {
  const res = await request({ url: `/api/banks`, method: "post", data });
  return res;
};
export const editBank = async params => {
  const res = await request({
    url: `/api/banks/${params.id}`,
    method: "post",
    data: params.formModel,
  });
  return res;
};
export const deleteBank = async id => {
  const res = await request({ url: `/api/banks/${id}`, method: "delete" });
  return res;
};

// 下載銀行範例json檔案
export const getBankFormat = async () => {
  const res = await request({ url: `/api/banks/format`, method: "get" });
  return res;
};

// 上傳銀行json檔案
export const batchBank = async data => {
  const res = await request({ url: `/api/banks/batch`, method: "post", data });
  return res;
};

// 上傳銀行json檔案
export const postBankLogo = async ({ id, formData }) => {
  const res = await request({
    url: `/api/banks/${id}/set-logo`,
    method: "post",
    data: formData,
  });
  return res;
};

export const slice = createSlice({
  name: "bank",
  initialState: {
    list: [],
    meta: {},
    currentRow: {},
  },
  reducers: {
    setBanks: (state, action) => {
      state.list = action.payload;
    },
  },
  extraReducers: {
    [getBanks.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.list = data.data;
      state.meta = metaToPagin(data.meta);
    },
    [getBank.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.currentRow = data;
    },
  },
});
export const { setBanks } = slice.actions;
export const selectBank = state => state.bank;
export default slice.reducer;
