import React from "react";
import { Form, InputNumber } from "antd";
import { formLayout } from "@/utils/enum";
import { CurrencyHelpTextFormItemFactory } from "@/components/factory/FormFactory";
import RechargeRateFormList from "@/components/RechargeRateFormList";

const transferFeeTypeMap = {
  // 固定
  0: () => (
    <Form.Item shouldUpdate noStyle>
      {({ getFieldValue }) => (
        <CurrencyHelpTextFormItemFactory
          name="transfer_fee"
          label="第三方代付手续费"
          defaultValKey="transfer_fee"
          defaultValue={getFieldValue("transfer_fee")}
        />
      )}
    </Form.Item>
  ),
  // 百分比
  1: () => (
    <Form.Item name="transfer_fee_rate" label="第三方代付手续费百分比">
      <InputNumber />
    </Form.Item>
  ),
  // 阶层利率
  2: ({ form }) => (
    <Form.Item
      shouldUpdate
      wrapperCol={{
        span: formLayout.wrapperCol.span + formLayout.labelCol.span,
      }}
    >
      <RechargeRateFormList name="transfer_fee_class_rate" form={form} />
    </Form.Item>
  ),
  // 固定＋一般利率
  3: () => (
    <>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => (
          <CurrencyHelpTextFormItemFactory
            name="transfer_fee"
            label="第三方代付手续费"
            defaultValKey="transfer_fee"
            defaultValue={getFieldValue("transfer_fee")}
          />
        )}
      </Form.Item>
      <Form.Item name="transfer_fee_rate" label="第三方代付手续费百分比">
        <InputNumber />
      </Form.Item>
    </>
  ),
  // 固定＋阶层利率
  4: ({ form }) => (
    <>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => (
          <CurrencyHelpTextFormItemFactory
            name="transfer_fee"
            label="第三方代付手续费"
            defaultValKey="transfer_fee"
            defaultValue={getFieldValue("transfer_fee")}
          />
        )}
      </Form.Item>
      <Form.Item
        shouldUpdate
        wrapperCol={{
          span: formLayout.wrapperCol.span + formLayout.labelCol.span,
        }}
      >
        <RechargeRateFormList name="transfer_fee_class_rate" form={form} />
      </Form.Item>
    </>
  ),
};

function FeeTypeFormFields({ type = "", form = null }) {
  if (type === void 0) return null;
  if (!transferFeeTypeMap[type]) return null;
  return transferFeeTypeMap[type]({ form });
}

export default FeeTypeFormFields;
