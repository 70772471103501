import { useEffect } from "react";
import { Modal, Form, Input } from "antd";
import { selectApp, getAppsSearch } from "@/store/slice/app";
import { selectGateway, getGateways } from "@/store/slice/gateway";
import { selectBank, getBanks } from "@/store/slice/bank";
import { formLayout, mode } from "@/utils/enum";
import SearchSelect from "@/components/SearchSelect";
import { CurrencyHelpTextFormItemFactory } from "@/components/factory/FormFactory";
import Spin from "@/components/Spin";

const AddEdit = props => {
  const [form] = Form.useForm();
  const handleOk = async () => {
    const formModel = form.getFieldsValue();
    await props.onOk({ ...formModel });
    form.resetFields();
  };
  const onSelectGateway = obj => {
    form.setFieldsValue({ currency: obj.currency });
  };
  useEffect(() => {
    props.visible && form.setFieldsValue(props.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Modal
      title={`${mode[props.mode]}订单`}
      visible={props.visible}
      onOk={handleOk}
      onCancel={props.onCancel}
      cancelText="取消"
      okText="送出"
      confirmLoading={props.loading}
      // destroyOnClose
    >
      <Spin spinning={props.loading}>
        <Form {...formLayout} form={form}>
          <Form.Item
            name="app_id"
            label="商户名称"
            rules={[{ required: true, message: "请选择商户" }]}
          >
            <SearchSelect
              action={getAppsSearch}
              selector={selectApp}
              selectorMetaKey="searchMeta"
              searchKey="name"
              val="id"
              label={i => `${i.id} ${i.name}`}
            />
          </Form.Item>
          <Form.Item
            name="gateway_id"
            label="渠道名称"
            rules={[{ required: true, message: "请选择渠道" }]}
          >
            <SearchSelect
              action={getGateways}
              selector={selectGateway}
              searchKey="name"
              val="id"
              label={i => `${i.id} ${i.name}`}
              onSelect={onSelectGateway}
            />
          </Form.Item>
          <Form.Item name="currency" hidden>
            <Input />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => (
              <CurrencyHelpTextFormItemFactory
                name="amount"
                label="金额"
                required={true}
                row={{ currency: getFieldValue("currency") || 0 }}
              />
            )}
          </Form.Item>
          <Form.Item
            name="payer_name"
            label="转帐人姓名"
            rules={[{ required: true, message: "请输入" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="payer_cred"
            label="转帐人卡号"
            rules={[{ required: true, message: "请输入" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="payer_bank_code"
            label="转帐人银行代码"
            rules={[{ required: true, message: "请输入开户行" }]}
          >
            <SearchSelect
              action={getBanks}
              selector={selectBank}
              searchKey="name"
              val="code"
              params={{ per_page: 9999 }}
              label={i => `${i.code} ${i.name}`}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};
export default AddEdit;
