import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Space, Button } from "antd";
import {
  selectAppProfitSummary,
  getAppProfitSummary,
  getAppProfitSummarySum,
  exportAppProfitSummary,
  getAppProfitSummaryRefresh,
} from "@/store/slice/appProfitSummary";
import { selectApp, getApps } from "@/store/slice/app";
import {
  useList,
  useUserPerms,
  useExportExcel,
  useSearchCache,
} from "@/utils/hook";
import { Currency, IsBoolEnum } from "@/utils/enum";
import { SearchFormFactory } from "@/components/factory/FormFactory";
import { NormalTable } from "@/components/factory/TableFactory";
import { SumTableList } from "@/components/SumTable";
import RefreshDataModal from "@/components/RefreshDataModal";
import {
  sumColumns,
  columns,
  statisticsColumns,
  exportColumnsRenderMap,
} from "./AppProfitSummaryColumn";

const AppProfitSummary = () => {
  const perms = useUserPerms();
  const searchFields = {
    id__in: { type: "string", label: "ID" },
    ...(perms["app.List"] && {
      app_id__in: {
        type: "searchSelect",
        label: "商户名称",
        action: getApps,
        selector: selectApp,
        searchKey: "name",
        val: "id",
        mode: "multiple",
        optionLabel: i => `${i.id} ${i.name}`,
      },
    }),
    created__btw: { type: "rangeDate", label: "报表时间", showTime: false },
    currency: { type: "select", label: "货币类型", options: Currency },
    is_online: {
      type: "select",
      label: "是否线上渠道",
      options: IsBoolEnum,
    },
  };
  const {
    res: { list, meta, sum: resSum },
    loading: listLoading,
    handleSearch,
    handleGetList,
    handleChangePage,
    handleChange,
  } = useList(getAppProfitSummary, selectAppProfitSummary);
  const sum = useMemo(() => {
    if (!resSum?.length) return [];
    return resSum.map((el, id) => ({ ...el, id }));
  }, [resSum]);

  const dispatch = useDispatch();
  const { handleGetPageSearchCache } = useSearchCache();
  const getSum = (params = {}) => {
    perms["app.profit.summary.sum"] &&
      dispatch(
        getAppProfitSummarySum({ ...handleGetPageSearchCache(), ...params }),
      );
  };
  useEffect(() => {
    getSum();
    // eslint-disable-next-line
  }, []);

  const [searchData, setSearchData] = useState({});
  const handleSearchClick = params => {
    setSearchData(params);
    handleSearch(params);
    getSum(params);
  };

  const {
    loading: exportLoading,
    handleExport,
    handleSelectedColumns,
  } = useExportExcel({
    exportColumnsRenderMap,
    exportAction: exportAppProfitSummary,
  });
  const [refreshVisible, setRefreshVisible] = useState(false);

  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchFormFactory
        fields={searchFields}
        handleSubmit={handleSearchClick}
        showExportBtn={true}
        handleExport={handleExport}
        exportLoading={exportLoading}
        otherBtn={
          <Button type="primary" onClick={() => setRefreshVisible(true)}>
            更新报表
          </Button>
        }
      />
      {perms["app.profit.summary.sum"] && (
        <SumTableList data={sum} labels={sumColumns} loading={listLoading} />
      )}
      <NormalTable
        allColumns={columns}
        defaultColumns={columns.map(i => i.dataIndex)}
        dataSource={list}
        meta={meta}
        onChangePage={handleChangePage}
        onChange={handleChange}
        loading={listLoading}
        onShowSizeChange={handleChangePage}
        statisticsColumns={statisticsColumns}
        setSelectedColumns={handleSelectedColumns}
      />
      <RefreshDataModal
        visible={refreshVisible}
        setVisible={setRefreshVisible}
        refreshAction={getAppProfitSummaryRefresh}
        callback={() => {
          handleGetList();
          getSum(searchData);
        }}
      />
    </Space>
  );
};
export default AppProfitSummary;
