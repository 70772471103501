import { useEffect } from "react";
import { Modal, Form, Input, Switch, InputNumber } from "antd";
import { formLayout, mode as Mode } from "@/utils/enum";
import { getGateways, selectGateway } from "@/store/slice/gateway";
import SearchSelect from "@/components/SearchSelect";
import Spin from "@/components/Spin";

const AddEditGateway = ({
  mode = "add",
  visible = false,
  data = null,
  loading = false,
  onOk = () => {},
  onCancel = () => {},
}) => {
  const [form] = Form.useForm();
  const handleOk = async () => {
    form.validateFields().then(async formModel => {
      if (!formModel) return;
      await onOk({ ...formModel });
    });
  };

  const onSelectGatewayName = ({ id }) => {
    form.setFieldsValue({ gateway_id: id });
  };

  useEffect(() => {
    if (visible) {
      // mode === "add" && addModeInit();
      // mode === "edit" && editModeInit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, data]);

  return (
    <Modal
      destroyOnClose={true}
      title={`${Mode[mode]}商户管理`}
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      cancelText="取消"
      okText="送出"
      confirmLoading={loading}
    >
      <Spin spinning={loading}>
        <Form {...formLayout} form={form} preserve={false}>
          <Form.Item name="is_enabled" label="是否启用" valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item name="gateway_id" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="gateway_name" label="渠道名稱">
            <SearchSelect
              action={getGateways}
              selector={selectGateway}
              onSelect={onSelectGatewayName}
              searchKey="name"
              val="name"
              label={i => `${i.id} ${i.name}`}
              allowClear
            />
          </Form.Item>
          <Form.Item name="recharge_rate" label="代收费率">
            <InputNumber stringMode step="0.01" />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};
export default AddEditGateway;
