import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Space, Button } from "antd";
import {
  selectAppBalanceSummaryDaily,
  getAppBalanceSummaryDaily,
  getAppBalanceSummaryDailySum,
  exportAppBalanceSummaryDaily,
  getBalanceSummaryDailyRefresh,
} from "@/store/slice/appBalanceSummaryDaily";
import { selectApp, getApps } from "@/store/slice/app";
import {
  useList,
  useUserPerms,
  useExportExcel,
  useSearchCache,
} from "@/utils/hook";
import { Currency } from "@/utils/enum";
import { SearchFormFactory } from "@/components/factory/FormFactory";
import { NormalTable } from "@/components/factory/TableFactory";
import SumTable from "@/components/SumTable";
import RefreshDataModal from "@/components/RefreshDataModal";
import {
  sumColumns,
  columns,
  exportColumnsRenderMap,
} from "./AppBalanceSummaryColumn";

const AppBalanceSummary = () => {
  const perms = useUserPerms();
  const searchFields = {
    id__in: { type: "string", label: "ID" },
    ...(perms["app.List"] && {
      app_id__in: {
        type: "searchSelect",
        label: "商户名称",
        action: getApps,
        selector: selectApp,
        searchKey: "name",
        val: "id",
        mode: "multiple",
        optionLabel: i => `${i.id} ${i.name}`,
      },
    }),
    created__btw: { type: "rangeDate", label: "报表时间", showTime: false },
    currency: { type: "select", label: "货币类型", options: Currency },
  };

  const {
    res: { list, meta, sum },
    loading: listLoading,
    handleSearch,
    handleGetList,
    handleChangePage,
    handleChange,
  } = useList(getAppBalanceSummaryDaily, selectAppBalanceSummaryDaily);
  const handleList = resList =>
    resList.map(ll => {
      // 当天盈利 = 當天收款金额 - 出款金额 - 商户提现金额 - 减所有手续费
      const current_day_total_profit =
        ll.total_order_amount -
        ll.total_transfer_amount -
        ll.total_withdrawal -
        ll.total_order_fee -
        ll.total_transfer_fee -
        ll.total_withdrawal_fee;
      // 当前余额 ＝ 昨天餘額 + 当天盈利
      const current_day_total_balance =
        ll.yesterday_balance + current_day_total_profit;
      return { ...ll, current_day_total_profit, current_day_total_balance };
    });

  const dispatch = useDispatch();
  const { handleGetPageSearchCache } = useSearchCache();
  const getSum = (params = {}) => {
    perms["app.balance.summary.daily.sum"] &&
      dispatch(
        getAppBalanceSummaryDailySum({
          ...handleGetPageSearchCache(),
          ...params,
        }),
      );
  };
  useEffect(() => {
    getSum();
    // eslint-disable-next-line
  }, []);

  const [searchData, setSearchData] = useState({});
  const handleSearchClick = params => {
    setSearchData(params);
    handleSearch(params);
    getSum(params);
  };

  const {
    loading: exportLoading,
    handleExport,
    handleSelectedColumns,
  } = useExportExcel({
    exportColumnsRenderMap,
    exportAction: exportAppBalanceSummaryDaily,
    handleExportData: handleList,
  });

  const [refreshVisible, setRefreshVisible] = useState(false);
  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchFormFactory
        fields={searchFields}
        handleSubmit={handleSearchClick}
        showExportBtn={true}
        handleExport={handleExport}
        exportLoading={exportLoading}
        otherBtn={
          <Button type="primary" onClick={() => setRefreshVisible(true)}>
            更新报表
          </Button>
        }
      />
      {perms["app.balance.summary.daily.sum"] && (
        <SumTable data={sum} labels={sumColumns} />
      )}
      <NormalTable
        allColumns={columns}
        defaultColumns={columns.map(i => i.dataIndex)}
        dataSource={handleList(list)}
        meta={meta}
        onChangePage={handleChangePage}
        onChange={handleChange}
        loading={listLoading}
        onShowSizeChange={handleChangePage}
        setSelectedColumns={handleSelectedColumns}
      />
      <RefreshDataModal
        visible={refreshVisible}
        setVisible={setRefreshVisible}
        refreshAction={getBalanceSummaryDailyRefresh}
        callback={() => {
          handleGetList();
          getSum(searchData);
        }}
      />
    </Space>
  );
};
export default AppBalanceSummary;
