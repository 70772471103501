import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Space, Button, Modal } from "antd";
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import {
  selectAppGroup,
  getAppGroups,
  getAppGroup,
  addAppGroup,
  editAppGroup,
  deleteAppGroup,
} from "@/store/slice/appGroup";
import { useList, useDetail, useUserPerms } from "@/utils/hook";
import { SearchFormFactory } from "@/components/factory/FormFactory";
import { NormalTable } from "@/components/factory/TableFactory";
import Detail from "@/components/Detail";
import JsonModal from "@/components/JsonModal";
import { dateFormat } from "@/utils/format";
import { selectConfig } from "@/store/slice/config";
import AddEdit from "./AddEdit";

const searchFields = {
  id__in: { type: "string", label: "ID" },
  name__k: { type: "string", label: "群组名称" },
  created__btw: { type: "rangeDate", label: "创建日期" },
};

function AppGroup() {
  const { payTypeList } = useSelector(selectConfig);
  const perms = useUserPerms();

  const {
    res: { list, meta },
    loading: listLoading,
    handleSearch,
    handleChangePage,
    handleChange,
    handleGetList,
    handleAdd: handleAddHook,
  } = useList(getAppGroups, selectAppGroup);
  const [addVisible, setAddVisible] = useState(false);
  const handleAddClick = () => {
    setAddVisible(true);
  };
  const handleAdd = async formModel => {
    handleAddHook({ action: addAppGroup, ...formModel });
    setAddVisible(false);
  };
  const [editVisible, setEditVisible] = useState(false);
  const handleEditClick = async id => {
    setDetailId(id);
    setEditVisible(true);
  };
  const handleEdit = async formModel => {
    const { status } = await handleEditHook({
      action: editAppGroup,
      id: currentRow.id,
      ...formModel,
    });
    if (status !== 200) return;
    setEditVisible(false);
    handleGetList({ page: meta.current });
  };

  const handleDeleteClick = id => {
    Modal.confirm({
      title: "是否删除",
      icon: <ExclamationCircleOutlined />,
      content: `即将删除 ${id}，是否继续？`,
      okText: "确认",
      cancelText: "取消",
      onOk: close => handleDelete(close, id),
    });
  };
  const handleDelete = async (close, id) => {
    const { status } = await deleteAppGroup(id);
    close();
    if (status !== 200) return;
    handleGetList({ page: meta.current });
  };

  const [detailId, setDetailId] = useState(null);
  const {
    currentRow,
    loading: detailLoading,
    handleEdit: handleEditHook,
  } = useDetail({ action: getAppGroup, id: detailId }, selectAppGroup);

  const [jsonVisible, setJsonVisible] = useState(false);
  const handleJsonClick = async id => {
    setDetailId(id);
    setJsonVisible(true);
  };
  const [detailVisible, setDetailVisible] = useState(false);
  const handleDetailClick = async id => {
    setDetailId(id);
    setDetailVisible(true);
  };
  useEffect(() => {
    jsonVisible || detailVisible || editVisible || setDetailId(null);
  }, [jsonVisible, detailVisible, editVisible]);

  const rateRender = (val = 0) => `${val}%`;

  const columns = [
    { title: "ID", dataIndex: "id", sorter: true },
    { title: "群组名称", dataIndex: "name", sorter: true },
    ...payTypeList.map(item => ({
      title: `${item.label}费率`,
      dataIndex: `${item.value}_recharge_rate`,
      // sorter: true,
      render: rateRender,
    })),
    // {
    //   title: "微信费率",
    //   dataIndex: "wx_recharge_rate",
    //   sorter: true,
    //   render: rateRender,
    // },
    // {
    //   title: "支付宝费率",
    //   dataIndex: "alipay_recharge_rate",
    //   sorter: true,
    //   render: rateRender,
    // },
    // {
    //   title: "网银费率",
    //   dataIndex: "ebank_recharge_rate",
    //   sorter: true,
    //   render: rateRender,
    // },
    // {
    //   title: "银行卡费率",
    //   dataIndex: "bank_recharge_rate",
    //   sorter: true,
    //   render: rateRender,
    // },
    // {
    //   title: "虚拟货币费率",
    //   dataIndex: "crypto_recharge_rate",
    //   sorter: true,
    //   render: rateRender,
    // },
    // {
    //   title: "qq费率",
    //   dataIndex: "qq_recharge_rate",
    //   sorter: true,
    //   render: rateRender,
    // },
    // {
    //   title: "数字人民币费率",
    //   dataIndex: "ecny_recharge_rate",
    //   sorter: true,
    //   render: rateRender,
    // },
    // {
    //   title: "云闪付费率",
    //   dataIndex: "union_recharge_rate",
    //   sorter: true,
    //   render: rateRender,
    // },
    // {
    //   title: "NoPay费率",
    //   dataIndex: "nopay_recharge_rate",
    //   sorter: true,
    //   render: rateRender,
    // },
    // {
    //   title: "银联存款费率",
    //   dataIndex: "'union_pay_recharge_rate",
    //   sorter: true,
    //   render: rateRender,
    // },
    // {
    //   title: "抖音存款费率",
    //   dataIndex: "douyin_recharge_rate",
    //   sorter: true,
    //   render: rateRender,
    // },

    {
      title: "创建日期",
      dataIndex: "created_at",
      render: val => dateFormat(val),
      className: "text-nowrap",
      sorter: true,
    },
    {
      title: "更新日期",
      dataIndex: "updated_at",
      render: val => dateFormat(val),
      className: "text-nowrap",
      sorter: true,
    },
    {
      title: "动作",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      render: (_, record) => (
        <Space>
          <Button
            size="small"
            onClick={() => handleJsonClick(record.id)}
            type="link"
            className="p-0"
            disabled={!perms["appGroup.Get"]}
          >
            json
          </Button>
          <Button
            size="small"
            onClick={() => handleDetailClick(record.id)}
            type="link"
            className="p-0"
            disabled={!perms["appGroup.Get"]}
          >
            查看
          </Button>
          <Button
            size="small"
            onClick={() => handleEditClick(record.id)}
            type="text"
            className="p-0"
            disabled={!perms["appGroup.Get"] || !perms["appGroup.Update"]}
          >
            编辑
          </Button>
          <Button
            size="small"
            type="text"
            className="p-0"
            danger
            onClick={() => handleDeleteClick(record.id)}
            disabled={!perms["appGroup.Deleted"]}
          >
            删除
          </Button>
        </Space>
      ),
    },
  ];

  const defaultColumns = [
    "id",
    "name",
    "wx_recharge_rate",
    "alipay_recharge_rate",
    "ebank_recharge_rate",
    "bank_recharge_rate",
    "crypto_recharge_rate",
    "qq_recharge_rate",
    "ecny_recharge_rate",
    "union_recharge_rate",
    "action",
  ];

  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchFormFactory fields={searchFields} handleSubmit={handleSearch} />
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={handleAddClick}
        disabled={!perms["appGroup.Create"]}
      >
        添加
      </Button>
      <NormalTable
        allColumns={columns}
        defaultColumns={defaultColumns}
        dataSource={list}
        meta={meta}
        onChangePage={handleChangePage}
        onChange={handleChange}
        loading={listLoading}
        onShowSizeChange={handleChangePage}
      />
      <AddEdit
        visible={addVisible}
        onOk={handleAdd}
        onCancel={() => setAddVisible(false)}
        loading={listLoading}
        mode="add"
      />
      <JsonModal
        visible={jsonVisible}
        data={currentRow}
        onCancel={() => setJsonVisible(false)}
        loading={detailLoading}
      />
      <Detail
        title="商户群组明细"
        visible={detailVisible}
        data={currentRow}
        onCancel={() => setDetailVisible(false)}
        loading={detailLoading}
        columns={columns.filter(i => i.dataIndex !== "action")}
      />
      <AddEdit
        visible={editVisible}
        onOk={handleEdit}
        onCancel={() => setEditVisible(false)}
        loading={detailLoading}
        data={currentRow}
        mode="edit"
      />
    </Space>
  );
}

export default AppGroup;
