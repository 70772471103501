import { dateFormat, priceFormat } from "@/utils/format";
import { Currency, isActiveLang } from "@/utils/enum";
import Tag from "@/components/Tag";

const moneyRender = () => {
  return {
    render: (val, record) =>
      priceFormat({ val: val || 0, currency: record.currency }),
    className: "text-nowrap",
    sorter: true,
  };
};

export const columns = [
  { title: "ID", dataIndex: "id", sorter: true },
  { title: "用户ID", dataIndex: "agent_id", sorter: true },
  { title: "代理名称", dataIndex: "agent_name", sorter: true },
  {
    title: "报表时间",
    dataIndex: "created",
    className: "text-nowrap",
    render: val => dateFormat(val, "YYYY-MM-DD"),
    sorter: true,
  },
  {
    title: "是否线上渠道",
    dataIndex: "is_online",
    render: val => <Tag val={val} />,
    sorter: true,
  },
  {
    title: "货币类型",
    dataIndex: "currency",
    render: val => Currency[val] || "",
    className: "text-nowrap",
  },
  {
    title: "帐户余额",
    dataIndex: "total_balance",
    ...moneyRender(),
  },
  {
    title: "圈存余额",
    dataIndex: "total_block",
    ...moneyRender(),
  },
  {
    title: "代收成功金额",
    dataIndex: "total_succeeded_amount",
    ...moneyRender(),
  },
  {
    title: "代收成功数",
    dataIndex: "total_succeeded_times",
    sorter: true,
  },
  {
    title: "代收订单数",
    dataIndex: "total_order_times",
    sorter: true,
  },
  {
    title: "代收订单金额",
    dataIndex: "total_order_amount",
    ...moneyRender(),
  },
  {
    title: "代收订单分润",
    dataIndex: "total_order_profit",
    ...moneyRender(),
  },
  {
    title: "代付订单数",
    dataIndex: "total_trans_times",
    sorter: true,
  },
  {
    title: "代付订单金额",
    dataIndex: "total_trans_amount",
    ...moneyRender(),
  },
  {
    title: "代付订单收受手续费",
    dataIndex: "total_trans_fee",
    ...moneyRender(),
  },
];

export const sumColumns = [
  {
    title: "订单成功次数",
    dataIndex: "total_succeeded_times",
  },
  {
    title: "订单成功金额",
    dataIndex: "total_succeeded_amount",
    render: val => priceFormat({ val, currency: 0 }),
  },
  {
    title: "总代付收入分润金额",
    dataIndex: "total_order_profit",
    render: val => priceFormat({ val, currency: 0 }),
  },
  {
    title: "总代付笔数",
    dataIndex: "total_trans_times",
  },
  {
    title: "总代付金额",
    dataIndex: "total_trans_amount",
    render: val => priceFormat({ val, currency: 0 }),
  },
  {
    title: "总代付收受手续费",
    dataIndex: "total_trans_fee",
    render: val => priceFormat({ val, currency: 0 }),
  },
];

export const statisticsColumns = columns.filter(
  i =>
    [
      "total_succeeded_times",
      "total_trans_times",
      "total_order_times",
      "total_order_profit",
    ].indexOf(i.dataIndex) !== -1,
);

const excelPriceFormat = (val, currency) =>
  priceFormat({ val, currency, showUnit: false });

export const exportColumnsRenderMap = {
  created: val => dateFormat(val, "YYYY-MM-DD"),
  is_online: val => isActiveLang(val),
  currency: val => Currency[val] || "",
  total_balance: (val, record) => excelPriceFormat(val, record.currency),
  total_block: (val, record) => excelPriceFormat(val, record.currency),
  total_succeeded_amount: (val, record) =>
    excelPriceFormat(val, record.currency),
  total_order_amount: (val, record) => excelPriceFormat(val, record.currency),
  total_order_profit: (val, record) => excelPriceFormat(val, record.currency),
  total_trans_amount: (val, record) => excelPriceFormat(val, record.currency),
  total_trans_fee: (val, record) => excelPriceFormat(val, record.currency),
};
