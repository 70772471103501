import React from "react";
import { Space } from "antd";
import { useUserPerms } from "@/utils/hook";
import Opt from "./Opt";
import DefaultAgent from "./DefaultAgent";
import AgentCardLimit from "./AgentCardLimit";
import AutoOrder from "./AutoOrder";
import CardRecord from "./CardRecord";
import AgentPayoutApp from "./AgentPayoutApp";
import AgentOrderUSDTApp from "./AgentOrderUSDTApp";
import CustomerService from "./CustomerService";
import GatewaySucceedRateTime from "./GatewaySucceedRateTime";

const formLayout = { labelCol: { span: 4 }, wrapperCol: { span: 8 } };
const SystemSetting = () => {
  const perms = useUserPerms();
  return (
    <Space direction="vertical" className="w-100">
      <Opt formLayout={formLayout} />
      {perms["config.agent"] && <DefaultAgent formLayout={formLayout} />}
      {perms["config.agent_card"] && <AgentCardLimit formLayout={formLayout} />}
      {perms["config.auto_order_confirm"] && (<AutoOrder formLayout={formLayout} />)}
      {perms["config.bankrecord"] && <CardRecord formLayout={formLayout} />}
      {perms["config.agent_payout_app"] && <AgentPayoutApp formLayout={formLayout} />}
      {perms["config.agent_order_usdt_app"] && <AgentOrderUSDTApp formLayout={formLayout} />}
      {perms["config.customer_service"] && <CustomerService formLayout={formLayout} />}
      {perms["config.gateway_succeed_rate_time"] && <GatewaySucceedRateTime formLayout={formLayout} />}
    </Space>
  );
};

export default SystemSetting;
