import { useEffect } from "react";
import { Modal, Form, Input } from "antd";
import { formLayout, mode as Mode } from "@/utils/enum";
import Spin from "@/components/Spin";

const AddEdit = ({ visible, mode, data, onCancel, onOk, loading }) => {
  const [form] = Form.useForm();

  const handleOk = async () => {
    form.validateFields().then(async formModel => {
      if (!formModel) return;
      await onOk({ ...formModel });
      form.resetFields();
    });
  };

  useEffect(() => {
    visible && form.setFieldsValue(mode === "edit" ? data : {});
  }, [visible, data, form, mode]);

  return (
    <Modal
      destroyOnClose={true}
      title={`${Mode[mode]}银行`}
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      cancelText="取消"
      okText="送出"
      confirmLoading={loading}
    >
      <Spin spinning={loading}>
        <Form {...formLayout} form={form}>
          <Form.Item
            name="code"
            label="银行代号"
            rules={[{ required: true, message: "请输入银行代号" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="name"
            label="银行名称"
            rules={[{ required: true, message: "请输入银行名称" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};
export default AddEdit;
