import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "@/utils/request";
import { metaToPagin } from "@/utils/format";

export const getCardBankLog = createAsyncThunk(
  "cardBankLog/getList",
  async (params = {}) => {
    const res = await request({
      url: "/api/bankrecords",
      method: "get",
      params,
    });
    return res;
  },
);

export const postCardBankLog = async (data = {}) => {
  const res = await request({
    url: `/api/bankrecords/${data.id}`,
    method: "post",
    data: data.formModel,
  });
  return res;
};

export const slice = createSlice({
  name: "cardBankLog",
  initialState: {
    list: [],
    meta: {},
  },
  extraReducers: {
    [getCardBankLog.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.list = data.data;
      state.meta = metaToPagin(data.meta);
    },
  },
});
export const selectCardBankLog = state => state.cardBankLog;
export default slice.reducer;
