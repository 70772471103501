import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Space } from "antd";
import {
  selectTransferAppDaily,
  getTransferAppDaily,
  getTransferAppDailySum,
  exportTransferAppDaily,
} from "@/store/slice/transferAppDaily";
import { selectApp, getApps } from "@/store/slice/app";
import {
  useList,
  useUserPerms,
  useExportExcel,
  useSearchCache,
} from "@/utils/hook";
import { Currency } from "@/utils/enum";
import { SearchFormFactory } from "@/components/factory/FormFactory";
import { NormalTable } from "@/components/factory/TableFactory";
import SumTable from "@/components/SumTable";
import {
  sumColumns,
  columns,
  statisticsColumns,
  exportColumnsRenderMap,
} from "./TransferAppDailyColumn";

const TransferAppDaily = () => {
  const perms = useUserPerms();
  const searchFields = {
    id__in: { type: "string", label: "ID" },
    ...(perms["app.List"] && {
      app_id__in: {
        type: "searchSelect",
        label: "商户名称",
        action: getApps,
        selector: selectApp,
        searchKey: "name",
        val: "id",
        mode: "multiple",
        optionLabel: i => `${i.id} ${i.name}`,
      },
    }),
    created__btw: { type: "rangeDate", label: "报表时间", showTime: false },
    currency: { type: "select", label: "货币类型", options: Currency },
  };
  const {
    res: { list, meta, sum },
    loading: listLoading,
    handleSearch,
    handleChangePage,
    handleChange,
  } = useList(getTransferAppDaily, selectTransferAppDaily);

  const dispatch = useDispatch();
  const { handleGetPageSearchCache } = useSearchCache();
  const getSum = (params = {}) => {
    perms["transfer.app.daily.sum"] &&
      dispatch(
        getTransferAppDailySum({ ...handleGetPageSearchCache(), ...params }),
      );
  };
  useEffect(() => {
    getSum();
    // eslint-disable-next-line
  }, []);
  const handleSearchClick = params => {
    handleSearch(params);
    getSum(params);
  };

  const {
    loading: exportLoading,
    handleExport,
    handleSelectedColumns,
  } = useExportExcel({
    exportColumnsRenderMap,
    exportAction: exportTransferAppDaily,
  });

  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchFormFactory
        fields={searchFields}
        handleSubmit={handleSearchClick}
        showExportBtn={true}
        handleExport={handleExport}
        exportLoading={exportLoading}
      />
      {perms["transfer.app.daily.sum"] && (
        <SumTable data={sum} labels={sumColumns} />
      )}
      <NormalTable
        allColumns={columns}
        defaultColumns={columns.map(i => i.dataIndex)}
        dataSource={list}
        meta={meta}
        onChangePage={handleChangePage}
        onChange={handleChange}
        loading={listLoading}
        onShowSizeChange={handleChangePage}
        statisticsColumns={statisticsColumns}
        setSelectedColumns={handleSelectedColumns}
      />
    </Space>
  );
};
export default TransferAppDaily;
