import React, { useEffect, useState } from "react";
import { Card, Form, Spin, message, Button } from "antd";
import {
  getConfigAgentCardMin,
  getConfigAgentCardMax,
  postConfigAgentCardMin,
  postConfigAgentCardMax,
} from "@/store/slice/config";
import { useUserPerms } from "@/utils/hook";
import { CurrencyHelpTextFormItemFactory } from "@/components/factory/FormFactory";

// 代理銀行卡 大小限額設定
function AgentCardLimit({ formLayout }) {
  const perms = useUserPerms();
  const [agentCardLimitLdg, setAgentCardLimitLdg] = useState(false);
  const [agentCardLimitForm] = Form.useForm();
  const onFinishCardLimit = async ({ min, max }) => {
    setAgentCardLimitLdg(true);
    Promise.all([
      postConfigAgentCardMin({ amount: min }),
      postConfigAgentCardMax({ amount: max }),
    ])
      .then(([res1, res2]) => {
        if (res1.status === 200 && res2.status === 200) {
          message.success("更新成功");
          handleGetAgentCardLimitConfig();
        }
      })
      .finally(() => setAgentCardLimitLdg(false));
  };

  const [cardLimitData, setCardLimitData] = useState({});
  const handleGetAgentCardLimitConfig = () => {
    setAgentCardLimitLdg(true);
    Promise.all([getConfigAgentCardMin(), getConfigAgentCardMax()])
      .then(([res1, res2]) => {
        let formInit = { min: 0, max: 0 };
        if (res1.status === 200) formInit.min = res1.data?.amount || 0;
        if (res2.status === 200) formInit.max = res2.data?.amount || 0;
        setCardLimitData({ ...formInit });
        agentCardLimitForm.setFieldsValue({ ...formInit });
      })
      .finally(() => setAgentCardLimitLdg(false));
  };

  useEffect(() => {
    handleGetAgentCardLimitConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Card title="代理银行卡限额范围" size="small">
      <Spin spinning={agentCardLimitLdg}>
        <Form
          form={agentCardLimitForm}
          onFinish={onFinishCardLimit}
          {...formLayout}
        >
          <CurrencyHelpTextFormItemFactory
            name="min"
            label="最小限额"
            row={{ min: cardLimitData.min, currency: 0 }}
            defaultValKey="min"
            key="min"
          />
          <CurrencyHelpTextFormItemFactory
            name="max"
            label="最大限额"
            row={{ max: cardLimitData.max, currency: 0 }}
            defaultValKey="max"
            key="max"
          />
          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!perms["config.agent_card_update"]}
            >
              确认提交
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Card>
  );
}

export default AgentCardLimit;
