import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

const InfoTip = ({ t = "", i = "" }) => (
  <Tooltip placement="topLeft" title={i}>
    <span style={{ marginRight: "3px" }}>{t}</span>
    <InfoCircleOutlined />
  </Tooltip>
);

export const PermsPageTree = [
  {
    title: "商户管理",
    key: "app.Menu",
    children: [
      {
        title: "群组管理",
        key: "appGroup.SubMenu",
        children: [
          {
            title: "新增",
            key: "appGroup.Create",
          },
          {
            title: "删除",
            key: "appGroup.Deleted",
          },
          {
            title: "列表",
            key: "appGroup.List",
          },
          {
            title: "查看",
            key: "appGroup.Get",
          },
          {
            title: <InfoTip t="编辑" i="可一并开启「查看」" />,
            key: "appGroup.Update",
          },
        ],
      },
      {
        title: "商户管理",
        key: "app.SubMenu",
        children: [
          {
            title: "新增",
            key: "app.Create",
          },
          {
            title: "删除",
            key: "app.Delete",
          },
          {
            title: "列表",
            key: "app.List",
          },
          {
            title: "查看",
            key: "app.Get",
          },
          {
            title: <InfoTip t="编辑" i="可一并开启「查看」" />,
            key: "app.Update",
          },
          {
            title: "查看渠道清单",
            key: "appgateways.List",
          },
          {
            title: "新增/编辑渠道清单",
            key: "appgateways.Create",
          },
          {
            title: "删除渠道清单",
            key: "appgateways.Delete",
          },
        ],
      },
      {
        title: "商户用户",
        key: "appUser.SubMenu",
        children: [
          {
            title: "列表",
            key: "appuser.List",
          },
          {
            title: "查看",
            key: "appuser.Get",
          },
          {
            title: <InfoTip t="编辑" i="需一并开启「查看」" />,
            key: "appuser.Update",
          },
        ],
      },
      {
        title: "商户帐户",
        key: "appAcct.SubMenu",
        children: [
          {
            title: "新增",
            key: "appacct.Create",
          },
          {
            title: "列表",
            key: "appacct.List",
          },
          {
            title: "查看",
            key: "appacct.Get",
          },
          {
            title: <InfoTip t="编辑" i="需一并开启「查看」" />,
            key: "appacct.Update",
          },
          {
            title: "修改余额",
            key: "appacct.UpdateBalance",
          },
          {
            title: "修改圈存金额",
            key: "appacct.UpdateBlockAmount",
          },
          {
            title: "退还圈存金额",
            key: "appacct.RefundBlockAmount",
          },
        ],
      },
    ],
  },
  {
    title: <InfoTip t="支付管理" i="设置将连动底下所有子目录" />,
    key: "gateway.Menu",
    children: [
      {
        title: (
          <InfoTip
            t="新增"
            i="需一併开启「商户管理/商户管理列表」、「加密货币/钱包管理/查看钱包列表」"
          />
        ),
        key: "gateway.Create", // app.List, cryptowallet.List
      },
      {
        title: "查看列表",
        key: "gateway.List",
      },
      {
        title: "查看",
        key: "gateway.Get",
      },
      {
        title: (
          <InfoTip
            t="编辑"
            i="需一併开启「查看」、「商户管理/商户管理列表」、「加密货币/钱包管理/查看钱包列表」」、「银行卡/查看银行卡列表」"
          />
        ),
        key: "gateway.Update", // gateway.Get, app.List, cryptowallet.List, card.List
      },
      {
        title: "查看代码",
        key: "gateway.GetCode",
      },
      {
        title: <InfoTip t="编辑代码" i="需一併开启「查看代码」" />,
        key: "gateway.UpdateCode",
      },
      {
        title: "删除",
        key: "gateway.Delete",
      },
      {
        title: "第三方余额帐户",
        key: "gatewayAcct.SubMenu",
        children: [
          {
            title: "新增",
            key: "gatewayacct.Create",
          },
          {
            title: "列表",
            key: "gatewayacct.List",
          },
          {
            title: "查看",
            key: "gatewayacct.Get",
          },
          {
            title: <InfoTip t="编辑" i="需一并开启「查看」" />,
            key: "gatewayacct.Update",
          },
          {
            title: "编辑帐户余额",
            key: "gatewayacct.UpdateBalance",
          },
        ],
      },
    ],
  },
  {
    title: "代收",
    key: "order.Menu",
    children: [
      {
        title: <InfoTip t="新增订单" i="需一并开启「银行管理/列表」" />,
        key: "order.Create", // bank.List
      },
      {
        title: "订单列表",
        key: "order.List",
      },
      {
        title: "查看订单",
        key: "order.Get",
      },
      {
        title: "手动通过",
        key: "order.Approve",
      },
      {
        title: "拒绝订单",
        key: "order.Deny",
      },
      {
        title: "收回订单",
        key: "order.Recycle",
      },
      {
        title: "通知订单",
        key: "order.Notify",
      },
      {
        title: "完成订单",
        key: "order.Complete",
      },
    ],
  },
  {
    title: "加密货币",
    key: "crypto.Menu",
    children: [
      {
        title: "钱包管理",
        key: "cryptowallet.SubMenu",
        children: [
          {
            title: "新增",
            key: "cryptowallet.Create",
          },
          {
            title: "列表",
            key: "cryptowallet.List",
          },
          {
            title: "查看",
            key: "cryptowallet.Get",
          },
          {
            title: "删除",
            key: "cryptowallet.Delete",
          },
          {
            title: "启停用",
            key: "cryptowallet.SetActive",
          },
          {
            title: (
              <InfoTip
                t="编辑"
                i="需一併开启「查看」、「加密货币/收款地址/列表」、「商户管理/列表」"
              />
            ),
            key: "cryptowallet.Update", // cryptowallet.Get, cryptoacct.List, app.List
          },
        ],
      },
      {
        title: "收款地址",
        key: "cryptoacct.SubMenu",
        children: [
          {
            title: "新增",
            key: "cryptoacct.Create",
          },
          {
            title: "列表",
            key: "cryptoacct.List",
          },
          {
            title: "查看",
            key: "cryptoacct.Get",
          },
          {
            title: <InfoTip t="编辑" i="需一併开启「查看」" />,
            key: "cryptoacct.Update",
          },
          {
            title: "停启用",
            key: "cryptoacct.SetActive",
          },
        ],
      },
      {
        title: "资金动账记录",
        key: "cryptoacctlog.SubMenu",
        children: [
          {
            title: "列表",
            key: "cryptoacctlog.List",
          },
          {
            title: "查看",
            key: "cryptoacctlog.Get",
          },
          {
            title: "绑定订单",
            key: "order.Bind",
          },
        ],
      },
    ],
  },
  {
    title: "银行卡",
    key: "card.Menu",
    children: [
      {
        title: "银行管理",
        key: "bank.SubMenu",
        children: [
          {
            title: "新增",
            key: "bank.Create",
          },
          {
            title: "批次新增",
            key: "bank.BatchCreate",
          },
          {
            title: "列表",
            key: "bank.List",
          },
          {
            title: "查看",
            key: "bank.Get",
          },
          {
            title: "删除",
            key: "bank.Delete",
          },
          {
            title: <InfoTip t="编辑" i="需一併开启「查看」、「列表」" />,
            key: "bank.Update", // bank.Get, bank.List
          },
        ],
      },
      {
        title: "银行卡管理",
        key: "card.SubMenu",
        children: [
          {
            title: "新增",
            key: "card.Create",
          },
          {
            title: "列表",
            key: "card.List",
          },
          {
            title: "查看",
            key: "card.Get",
          },
          {
            title: "启用",
            key: "card.SetActive",
          },
          {
            title: "锁定",
            key: "card.SetLocked",
          },
          {
            title: "删除",
            key: "card.Delete",
          },
          {
            title: (
              <InfoTip t="编辑" i="需一併开启「查看」、「银行管理/列表」" />
            ),
            key: "card.Update", // bank.List
          },
        ],
      },
      {
        title: "银行卡帐户管理",
        key: "cardacct.SubMenu",
        children: [
          {
            title: "新增",
            key: "cardacct.Create",
          },
          {
            title: "列表",
            key: "cardacct.List",
          },
          {
            title: "查看",
            key: "cardacct.Get",
          },
          {
            title: (
              <InfoTip
                t="编辑"
                i="需一併开启「查看银行卡帐户」、「银行卡/查看」"
              />
            ),
            key: "cardacct.Update", // cardacct.Get, card.List
          },
          {
            title: "更新余额",
            key: "cardacct.UpdateBalance",
          },
        ],
      },
      {
        title: "银行卡帐变日志",
        key: "cardacctlog.SubMenu",
        children: [
          {
            title: "列表",
            key: "cardacctlog.List",
          },
          {
            title: "查看",
            key: "cardacctlog.Get",
          },
        ],
      },
      {
        title: "银行卡网银纪录",
        key: "bankrecord.SubMenu",
        children: [
          {
            title: "列表",
            key: "bankrecord.List",
          },
          {
            title: "编辑",
            key: "bankrecord.Update",
          },
        ],
      },
    ],
  },
  {
    title: "代理管理",
    key: "agent.Menu",
    children: [
      {
        title: "代理管理",
        key: "agent.SubMenu",
        children: [
          {
            title: (
              <InfoTip
                t="新增"
                i="需一併开启「系统设置/查看默认代理」、「银行卡/查看」"
              />
            ),
            key: "agent.Create", // config.agent
          },
          {
            title: "列表",
            key: "agent.List",
          },
          {
            title: "查看",
            key: "agent.Get",
          },
          {
            title: "查看上级代理",
            key: "agent.UpperList",
          },
          {
            title: <InfoTip t="编辑" i="需一併开启「查看」" />,
            key: "agent.Update",
          },
        ],
      },
      {
        title: "代理帐户",
        key: "agentacct.SubMenu",
        children: [
          {
            title: <InfoTip t="新增" i="需一併开启「代理管理/列表」" />,
            key: "agentacct.Create", // agent.List
          },
          {
            title: "列表",
            key: "agentacct.List",
          },
          {
            title: "查看",
            key: "agentacct.Get",
          },
          // {
          //   title: "删除",
          //   key: "agentacct.Delete",
          // },
          {
            title: "更新",
            key: "agentacct.Update",
          },
          {
            title: "佣金转移",
            key: "agentacct.ProfitSwap",
          },
          {
            title: "修改余额",
            key: "agentacct.UpdateBalance",
          },
          {
            title: "修改圈存金额",
            key: "agentacct.UpdateBlockAmount",
          },
          {
            title: "退还圈存金额",
            key: "agentacct.RefundBlockAmount",
          },
        ],
      },
    ],
  },
  {
    title: "登录日志",
    key: "loginlog.Menu",
    children: [
      {
        title: "查看登录日志列表",
        key: "loginlog.List",
      },
      {
        title: "查看登录日志",
        key: "loginlog.Get",
      },
    ],
  },
  {
    title: "审计日志管理",
    key: "audit.Menu",
    children: [
      {
        title: "查看审计日志列表",
        key: "audit.List",
      },
      {
        title: "编辑审计日志",
        key: "audit.Get",
      },
    ],
  },
  {
    title: "用户管理",
    key: "user.Menu",
    children: [
      {
        title: "用户管理",
        key: "user.SubMenu",
        children: [
          {
            title: "新增用户",
            key: "user.Create",
          },
          // 删除此权限api 默认就能拿取
          // {
          //   title: "查看用户列表",
          //   key: "user.List",
          // },
          {
            title: "查看用户",
            key: "user.Get",
          },
          {
            title: <InfoTip t="编辑用户" i="需一并开启「查看用户」" />,
            key: "user.Update",
          },
          {
            title: "重置密码",
            key: "user.UpdatePassword",
          },
          {
            title: "重置资金密码",
            key: "user.UpdateFundPassword",
          },
        ],
      },
      {
        title: "角色管理",
        key: "role",
        children: [
          {
            title: "新增角色",
            key: "role.Create",
          },
          {
            title: "查看角色列表",
            key: "role.List",
          },
          {
            title: "查看角色",
            key: "role.Get",
          },
          {
            title: <InfoTip t="编辑角色" i="需一并开启「查看角色」" />,
            key: "role.Update",
          },
          {
            title: "删除角色",
            key: "role.Delete",
          },
          {
            title: "角色使用者管理",
            key: "role.UserList",
          },
          {
            title: "更新权限",
            key: "role.UpdatePerms",
          },
        ],
      },
    ],
  },
  {
    title: "帐户纪录",
    key: "fundlog.Menu",
    children: [
      {
        title: "商户资金记录",
        key: "appacctlog.SubMenu",
        children: [
          {
            title: "列表",
            key: "appacctlog.List",
          },
        ],
      },
      {
        title: "代理帐户资金纪录",
        key: "agentacctlog.SubMenu",
        children: [
          {
            title: "列表",
            key: "agentacctlog.List",
          },
        ],
      },
      {
        title: "余额置换记录",
        key: "acctswaplog.SubMenu",
        children: [
          {
            title: "列表",
            key: "acctswaplog.List",
          },
          {
            title: "查看",
            key: "acctswaplog.Get",
          },
        ],
      },
      {
        title: "第三方余额帐户纪录",
        key: "gatewayacctlog.SubMenu",
        children: [
          {
            title: "列表",
            key: "gatewayacctlog.List",
          },
        ],
      },
    ],
  },
  {
    title: "报表",
    key: "report.Menu",
    children: [
      {
        title: "代理日报",
        key: "agentDaily.SubMenu",
        children: [
          {
            title: "汇总",
            key: "agent.daily.sum",
          },
          {
            title: "列表",
            key: "agent.daily",
          },
        ],
      },
      // {
      //   title: "开发者日报",
      //   key: "developerDaily.SubMenu",
      //   children: [
      //     {
      //       title: "汇总",
      //       key: "developer.daily.sum",
      //     },
      //     {
      //       title: "列表",
      //       key: "developer.daily",
      //     },
      //   ],
      // },
      {
        title: "订单日报",
        key: "orderDaily.SubMenu",
        children: [
          {
            title: "汇总",
            key: "order.daily.sum",
          },
          {
            title: "列表",
            key: "order.daily",
          },
        ],
      },
      {
        title: "商户报表",
        key: "appDaily.SubMenu",
        children: [
          {
            title: "汇总",
            key: "app.daily.sum",
          },
          {
            title: "列表",
            key: "app.daily",
          },
        ],
      },
      {
        title: "代付商户报表",
        key: "transferAppDaily.SubMenu",
        children: [
          {
            title: "汇总",
            key: "transfer.app.daily.sum",
          },
          {
            title: "列表",
            key: "transfer.app.daily",
          },
        ],
      },
      {
        title: "渠道报表",
        key: "gatewayDaily.SubMenu",
        children: [
          {
            title: "汇总",
            key: "gateway.daily.sum",
          },
          {
            title: "列表",
            key: "gateway.daily",
          },
        ],
      },
      {
        title: "支付商户渠道报表",
        key: "appGatewayDaily.SubMenu",
        children: [
          {
            title: "汇总",
            key: "app.gateway.daily.sum",
          },
          {
            title: "列表",
            key: "app.gateway.daily",
          },
        ],
      },
      {
        title: "代付商户渠道报表",
        key: "transferAppGatewayDaily.SubMenu",
        children: [
          {
            title: "汇总",
            key: "transfer.app.gateway.daily.sum",
          },
          {
            title: "列表",
            key: "transfer.app.gateway.daily",
          },
        ],
      },
      {
        title: "支付盈利汇总报表",
        key: "appProfitSummary.SubMenu",
        children: [
          {
            title: "汇总",
            key: "app.profit.summary.sum",
          },
          {
            title: "列表",
            key: "app.profit.summary",
          },
        ],
      },
      {
        title: "商户余额汇总报表",
        key: "appBalanceSummary.SubMenu",
        children: [
          {
            title: "汇总",
            key: "app.balance.summary.daily.sum",
          },
          {
            title: "列表",
            key: "app.balance.summary.daily",
          },
        ],
      },
    ],
  },
  {
    title: "系统设置",
    key: "config.Menu",
    children: [
      {
        title: "USDT 汇率设置",
        key: "exchange.SubMenu",
        children: [
          {
            title: "查看 USDT 汇率设置",
            key: "config.exchange",
          },
          {
            title: "编辑 USDT 汇率设置",
            key: "config.exchange_update",
          },
        ],
      },
      {
        title: "系统设置",
        key: "system.SubMenu",
        children: [
          {
            title: "谷歌验证码",
            key: "config.opt_update",
          },
          {
            title: "查看默认代理",
            key: "config.agent",
          },
          {
            title: "编辑默认代理",
            key: "config.agent_update",
          },
          {
            title: "查看代理银行卡限额",
            key: "config.agent_card",
          },
          {
            title: "编辑查看代理银行卡限额",
            key: "config.agent_card_update",
          },
          {
            title: "查看自动上分",
            key: "config.auto_order_confirm",
          },
          {
            title: "编辑自动上分",
            key: "config.auto_order_confirm_update",
          },
          {
            title: "查看银行卡纪录自动绑定",
            key: "config.bankrecord",
          },
          {
            title: "编辑银行卡纪录自动绑定",
            key: "config.bankrecord_update",
          },
          {
            title: "查看代理提款商户",
            key: "config.agent_payout_app",
          },
          {
            title: "编辑代理提款商户",
            key: "config.agent_payout_app_update",
          },
          {
            title: "查看代理出售USDT商户",
            key: "config.agent_order_usdt_app",
          },
          {
            title: "编辑代理出售USDT商户",
            key: "config.agent_order_usdt_app_update",
          },
          {
            title: "查看客服网址",
            key: "config.customer_service",
          },
          {
            title: "编辑客服网址",
            key: "config.customer_service_update",
          },
          {
            title: "查看渠道成功率时间",
            key: "config.gateway_succeed_rate_time",
          },
          {
            title: "编辑渠道成功率时间",
            key: "config.gateway_succeed_rate_time_update",
          },
        ],
      },
      {
        title: "代理公告设置",
        key: "agentNotice.SubMenu",
        children: [
          {
            title: "新增",
            key: "agent_notice.Create",
          },
          {
            title: "删除",
            key: "agent_notice.Delete",
          },
          {
            title: "列表",
            key: "agent_notice.List",
          },
          {
            title: <InfoTip t="编辑" i="可一并开启「列表」" />,
            key: "agent_notice.Update",
          },
        ],
      },
    ],
  },
  {
    title: <InfoTip t="代付" i="设置将连动底下所有子目录以及「商户提款」" />,
    key: "transfers.Menu",
    children: [
      {
        title: "查看代付列表",
        key: "transfer.List",
      },
      {
        title: "查看代付",
        key: "transfer.Get",
      },
      // {
      //   title: "删除代付",
      //   key: "transfer.Delete",
      // },
      {
        title: "更新代付",
        key: "transfer.Update",
      },
      {
        title: "认领",
        key: "transfer.PaidClaim",
      },
      {
        title: "取消认领",
        key: "transfer.PaidCancel",
      },
      {
        title: "出款网关",
        key: "transfer.Gateway",
      },
      {
        title: <InfoTip t="出款" i="需一并开启「出款网关」" />,
        key: "transfer.Paid",
      },
      {
        title: "出款离线网关",
        key: "transfer.OfflineGateway",
      },
      {
        title: <InfoTip t="重新出款" i="需一并开启「出款离线网关」" />,
        key: "transfer.RePaid",
      },
      {
        title: "手动通过",
        key: "transfer.Succeeded",
      },
      {
        title: "退回认领",
        key: "transfer.CancelClaim",
      },
      {
        title: "出款失败",
        key: "transfer.Failed",
      },
      {
        title: "审核拒绝",
        key: "transfer.Deny",
      },
      {
        title: "回调",
        key: "transfer.Notify",
      },
      {
        title: "收回",
        key: "transfer.Recycle",
      },
      {
        title: "查询出款",
        key: "transfer.Query",
      },
    ],
  },
  {
    title: "代理出售USDT",
    key: "orderAgent.Menu",
    children: [
      {
        title: "查看代理出售USDT",
        key: "order.ListAgent",
      },
      {
        title: "确认汇款",
        key: "order.Recipient",
      },
    ],
  },
  {
    title: <InfoTip t="代理提款" i="其他操作权限至「代付」设置" />,
    key: "TransfersAgent.Menu",
    children: [
      {
        title: "查看代理提款列表",
        key: "transfer.ListAgent",
      },
    ],
  },
  {
    title: <InfoTip t="商户提款" i="其他操作权限至「代付」设置" />,
    key: "TransfersApp.Menu",
    children: [
      {
        title: "查看商户提款列表",
        key: "transfer.ListApp",
      },
    ],
  },
  {
    title: "白名单管理",
    key: "whitelist.Menu",
    children: [
      {
        title: "代理白名单",
        key: "agentwhitelist.SubMenu",
        children: [
          {
            title: "新增",
            key: "agentwhitelist.Create",
          },
          {
            title: "列表",
            key: "agentwhitelist.List",
          },
          {
            title: "删除",
            key: "agentwhitelist.Delete",
          },
        ],
      },
      {
        title: "商户白名单",
        key: "developerwhitelist.SubMenu",
        children: [
          {
            title: "新增",
            key: "developerwhitelist.Create",
          },
          {
            title: "列表",
            key: "developerwhitelist.List",
          },
          {
            title: "删除",
            key: "developerwhitelist.Delete",
          },
        ],
      },
    ],
  },
  {
    title: "小工具",
    key: "tool.Menu",
    children: [
      {
        title: "帐户划转",
        key: "tool.AcctSwap",
      },
      {
        title: "抽选渠道",
        key: "tool.PickGateway",
      },
      {
        title: "更新回调域名",
        key: "tool.UpdateGatewayCallbackHost",
      },
    ],
  },
  {
    title: "商品管理",
    key: "product.Menu",
    children: [
      {
        title: "商品管理",
        key: "product.SubMenu",
        children: [
          {
            title: "新增",
            key: "product.Create",
          },
          {
            title: "列表",
            key: "product.List",
          },
          {
            title: "删除",
            key: "product.Delete",
          },
          {
            title: <InfoTip t="编辑" i="可一并开启「列表」" />,
            key: "product.Update",
          },
        ],
      },
      {
        title: "商品圖片",
        key: "productImage.SubMenu",
        children: [
          {
            title: "新增",
            key: "product_image.Create",
          },
          {
            title: "列表",
            key: "product_image.List",
          },
          {
            title: "删除",
            key: "product_image.Delete",
          },
        ],
      },
    ],
  },
];

/**
 * 取出所有最后一层的 key
 * @returns {Array} [key1, key2, ... ]
 */
export const deepFlattenPermKeys = arr =>
  [].concat(
    ...arr.map(v =>
      Array.isArray(v.children) ? deepFlattenPermKeys(v.children) : v.key,
    ),
  );

export const RoleKeys = deepFlattenPermKeys(PermsPageTree);

/**
 * 返回所有为 true 的 key
 * @returns {Obj} {key:'name', key:'name', key:'name'}
 */
export const getPermKeyNameObj = () => {
  let tempData = {};
  const deep = arr => {
    arr.forEach(el => {
      if (Array.isArray(el.children)) {
        deep(el.children);
      } else {
        tempData[el.key] = el.title;
      }
    });
  };
  deep(PermsPageTree);
  return tempData;
};

export const RoleKeyNameObj = getPermKeyNameObj();

export const getDefaultCheckKeys = perms => {
  if (perms === null) return [];
  try {
    let defaultCheckedKeys = [];
    Object.keys(perms).forEach(i => {
      perms[i] && defaultCheckedKeys.push(i);
    });
    return defaultCheckedKeys;
  } catch (e) {
    console.log(e);
    return [];
  }
};
