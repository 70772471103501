import React from "react";
import { message } from "antd";
import EditableConfirm from "@/components/EditableConfirm";
import { searchFieldsFormat } from "@/utils/format";

const defaultRefreshFields = [
  {
    label: "更新日期",
    name: "created",
    inputType: "date",
    required: true,
  },
];

function RefreshDataModal({
  visible = false,
  setVisible = () => {},
  refreshFields = defaultRefreshFields,
  refreshAction = () => {},
  callback = () => {},
}) {
  const handleRefetch = async ({ created }) => {
    const params = searchFieldsFormat({
      fields: { created: { type: "date" } },
      formModel: { created },
    });
    const { status } = await refreshAction(params);
    setVisible(false);
    if (status !== 200) return;
    message.success("更新成功！");
    callback();
  };

  return (
    <EditableConfirm
      title="更新报表"
      visible={visible}
      fields={refreshFields}
      onCancel={() => setVisible(false)}
      onOk={handleRefetch}
      formLayout={{ labelCol: { span: 5 } }}
    />
  );
}

export default RefreshDataModal;
