import React from "react";
import {
  selectUpperLayerAgentList,
  getUpperLayerAgentList,
} from "@/store/slice/agent";
import NormalTableModal from "@/components/NormalTableModal";
import { dateFormat } from "@/utils/format";

const UpperLayerAgentListModal = ({
  modalVisible = false,
  onModalCancel = () => {},
  originParams = {},
}) => {
  const allColumns = [
    { title: "ID", dataIndex: "id", sorter: true },
    {
      title: "使用者ID",
      dataIndex: "user_id",
      sorter: true,
    },
    {
      title: "使用者",
      dataIndex: "username",
      sorter: true,
    },
    {
      title: "姓名",
      dataIndex: "name",
      editable: true,
      inputType: "string",
      sorter: true,
    },
    {
      title: "代收费率",
      dataIndex: "recharge_rate",
      inputType: "string",
      sorter: true,
    },
    {
      title: "代付费率",
      dataIndex: "withdraw_rate",
      inputType: "string",
      sorter: true,
    },
    {
      title: "创建日期",
      dataIndex: "created",
      render: val => dateFormat(val),
      sorter: true,
      className: "text-nowrap",
    },
    {
      title: "更新日期",
      dataIndex: "updated",
      render: val => dateFormat(val),
      sorter: true,
      className: "text-nowrap",
    },
    { title: "备注", dataIndex: "note" },
  ];
  const defaultColumns = [
    "id",
    "user_id",
    "username",
    "name",
    "is_active",
    "action",
  ];
  return (
    <NormalTableModal
      modalVisible={modalVisible}
      modalTitle="上级代理"
      modalWidth={1200}
      onModalCancel={onModalCancel}
      tableConfig={{
        allColumns,
        defaultColumns,
        asyncThunk: getUpperLayerAgentList,
        selector: selectUpperLayerAgentList,
        originParams,
      }}
    />
  );
};
export default UpperLayerAgentListModal;
