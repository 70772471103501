import React from "react";
import QRCode from "qrcode-react";
import { Typography, Space, Statistic, Button } from "antd";
import { priceFormat, copyText } from "@/utils/format";

function QRCodeForm({
  payType = 0,
  credentialData = {},
  url = "",
  expired_at = 0,
}) {
  const _amountFormat = credentialData
    ? priceFormat({
        val: credentialData?.amount,
        currency: 3,
        showUnit: false,
      })
    : 0;

  const rmb = credentialData
    ? (_amountFormat * credentialData.price).toLocaleString("en-US", {
        maximumFractionDigits: 2,
      })
    : 0;

  return (
    <>
      <Typography.Title level={3} className="header">
        扫描二维码付款
      </Typography.Title>

      {payType === 4 && (
        <>
          <Space
            style={{
              width: "100%",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <Typography.Title
              level={4}
              style={{ textAlign: "center", marginBottom: 0 }}
            >
              支付数量-不含手续费
              <span style={{ color: "red" }}>{_amountFormat}</span> USDT
            </Typography.Title>
            <Button size="small" onClick={() => copyText(_amountFormat)}>
              复制
            </Button>
          </Space>
          <div className="list">
            <div className="list--item">
              <div className="list--item--label">参考汇率</div>
              <div className="list--item--value">
                ￥{credentialData?.price} /USDT
              </div>
              <button
                className="list--item--extra"
                onClick={() => copyText(credentialData?.price)}
              >
                复制
              </button>
            </div>
            <div className="list--item" style={{ marginBottom: 0 }}>
              <div className="list--item--label" style={{ width: "130px" }}>
                预计到账≈人民币
              </div>
              <div className="list--item--value">￥{rmb}</div>
              <button
                className="list--item--extra"
                onClick={() => copyText(_amountFormat)}
              >
                复制
              </button>
            </div>
            <p
              style={{
                color: "#e11515",
                marginBottom: "10px",
                paddingLeft: "8px",
              }}
            >
              请按要求转账后两位小数点，否则可能无法确认到账，造成资金损失
            </p>
            <div className="list--item">
              <div className="list--item--label">收款地址</div>
              <div className="list--item--value">{credentialData?.address}</div>
              <button
                className="list--item--extra"
                onClick={() => copyText(credentialData?.address)}
              >
                复制
              </button>
            </div>
            <div className="list--item">
              <div className="list--item--label">收款币种</div>
              <div className="list--item--value">
                {credentialData?.currency}
              </div>
              <button
                className="list--item--extra"
                onClick={() => copyText(credentialData?.currency)}
              >
                复制
              </button>
            </div>
          </div>

          {/* 参考汇率：￥ <% credential.data.price %> /USDT */}
          {/* 预计到账≈人民币：￥ (format.USDT(<% credential.data.amount %>)*<% credential.data.price %>).Flex(2) */}
        </>
      )}

      <Space style={{ width: "100%", justifyContent: "center" }}>
        订单关闭倒计时:
        <Statistic.Countdown
          value={expired_at ? +new Date(expired_at) : 0}
          valueStyle={{ fontSize: "18px", color: "red" }}
        />
      </Space>
      <Space
        style={{
          width: "100%",
          justifyContent: "center",
          marginBottom: "10px",
        }}
      >
        <QRCode value={url} size={200} />;
      </Space>
      <div className="note">
        <p>温馨提示:</p>
        {payType === 4 && (
          <>
            <p>
              请勿向右方二维码支付任何非{" "}
              <span style={{ color: "red" }}>{credentialData?.currency}</span>
              协议的资产，否则资金无法到账及找回
            </p>
            <p>充值前务必确认地址及币种协议信息无误，否则无法到账。</p>
          </>
        )}
        <p>请使用手机原相机扫描二维码支付</p>
      </div>
    </>
  );
}

export default QRCodeForm;
