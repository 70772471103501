import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "@/utils/request";
import { metaToPagin } from "@/utils/format";

export const getProductImages = createAsyncThunk(
  "productImage/getList",
  async (params = {}) => {
    const res = await request({
      url: "/api/product_images",
      method: "get",
      params,
    });
    return res;
  },
);

export const getProductImage = createAsyncThunk(
  "productImage/getDetail",
  async id => {
    const res = await request({
      url: `/api/product_images/${id}`,
      method: "get",
    });
    return res;
  },
);

export const addProductImage = async params => {
  const res = await request({
    url: `/api/product_images`,
    method: "post",
    data: params.formData,
  });
  return res;
};

export const editProductImage = async params => {
  const res = await request({
    url: `/api/product_images/${params.id}`,
    method: "post",
    data: params.formModel,
  });
  return res;
};

export const deleteProductImage = async id => {
  const res = await request({
    url: `/api/product_images/${id}`,
    method: "delete",
  });
  return res;
};

export const slice = createSlice({
  name: "ProductImage",
  initialState: {
    list: [],
    meta: {},
    currentRow: {},
  },
  reducers: {
    setProductImages: (state, action) => {
      state.list = action.payload;
    },
  },
  extraReducers: {
    [getProductImages.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.list = data.data.sort((a, b) => a.seq - b.seq);
      state.meta = metaToPagin(data.meta);
    },
    [getProductImage.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.currentRow = data;
    },
  },
});

export const { setProductImages } = slice.actions;
export const selectProductImage = state => state.productImage;
export default slice.reducer;
