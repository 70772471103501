import { configureStore } from "@reduxjs/toolkit";
import layoutReducer from "./slice/layout";
import routerTabReducer from "./slice/routerTab";
import authReducer from "./slice/auth";
import userReducer from "./slice/user";
import developerReducer from "./slice/developer";
import appUserReducer from "./slice/appUser";
import orderReducer from "./slice/order";
import cryptoWalletReducer from "./slice/cryptoWallet";
import cryptoAcctReducer from "./slice/cryptoAcct";
import cryptoAcctLogReducer from "./slice/cryptoAcctLog";
import bankReducer from "./slice//bank";
import cardReducer from "./slice/card";
import cardAcctReducer from "./slice/cardAcct";
import cardAcctLogReducer from "./slice/cardAcctLog";
import agentReducer from "./slice/agent";
import agentAcctReducer from "./slice/agentAcct";
import agentAcctLogReducer from "./slice/agentAcctLog";
import loginLogReducer from "./slice/loginLog";
import appReducer from "./slice/app";
import appGroupReducer from "./slice/appGroup";
import appAcctReducer from "./slice/appAcct";
import appAcctLogReducer from "./slice/appAcctLog";
import appGatewaysReducer from "./slice/appGateway";
import appProfitSummaryReducer from "./slice/appProfitSummary";
import appBalanceSummaryDailyReducer from "./slice/appBalanceSummaryDaily";
import gatewayReducer from "./slice/gateway";
import gatewayAcctReducer from "./slice/gatewayAcct";
import gatewayAcctLogReducer from "./slice/gatewayAcctLog";
import auditReducer from "./slice/audit";
import roleReducer from "./slice/role";
import agentDailyReducer from "./slice/agentDaily";
import developerDailyReducer from "./slice/developerDaily";
import orderDailyReducer from "./slice/orderDaily";
import appDailyReducer from "./slice/appDaily";
import transferAppDailyReducer from "./slice/transferAppDaily";
import gatewayDailyReducer from "./slice/gatewayDaily";
import appGatewayDailyReducer from "./slice/appGatewayDaily";
import transferAppGatewayDailyReducer from "./slice/transferAppGatewayDaily";
import configReducer from "./slice/config";
import transferReducer from "./slice/transfer";
import transfersAppReducer from "./slice/transfersApp";
import transfersAgentReducer from "./slice/transfersAgent";
import agentWhitelistReducer from "./slice/agentWhitelist";
import developerWhitelistReducer from "./slice/developerWhitelist";
import acctSwapLogReducer from "./slice/acctSwapLog";
import cardBankLogReducer from "./slice/cardBankLog";
import agentNoticeReducer from "./slice/agentNotice";
import productReducer from "./slice/product";
import productImageReducer from "./slice/productImage";

export default configureStore({
  reducer: {
    layout: layoutReducer,
    routerTab: routerTabReducer,
    auth: authReducer,
    user: userReducer,
    developer: developerReducer,
    appUser: appUserReducer,
    order: orderReducer,
    cryptoWallet: cryptoWalletReducer,
    cryptoAcct: cryptoAcctReducer,
    cryptoAcctLog: cryptoAcctLogReducer,
    bank: bankReducer,
    card: cardReducer,
    cardAcct: cardAcctReducer,
    cardAcctLog: cardAcctLogReducer,
    agent: agentReducer,
    agentAcct: agentAcctReducer,
    agentAcctLog: agentAcctLogReducer,
    loginLog: loginLogReducer,
    app: appReducer,
    appGroup: appGroupReducer,
    appAcct: appAcctReducer,
    appAcctLog: appAcctLogReducer,
    appGateway: appGatewaysReducer,
    appProfitSummary: appProfitSummaryReducer,
    appBalanceSummaryDaily: appBalanceSummaryDailyReducer,
    gateway: gatewayReducer,
    gatewayAcct: gatewayAcctReducer,
    gatewayAcctLog: gatewayAcctLogReducer,
    audit: auditReducer,
    role: roleReducer,
    agentDaily: agentDailyReducer,
    developerDaily: developerDailyReducer,
    orderDaily: orderDailyReducer,
    appDaily: appDailyReducer,
    transferAppDaily: transferAppDailyReducer,
    gatewayDaily: gatewayDailyReducer,
    appGatewayDaily: appGatewayDailyReducer,
    transferAppGatewayDaily: transferAppGatewayDailyReducer,
    config: configReducer,
    transfer: transferReducer,
    transfersApp: transfersAppReducer,
    transfersAgent: transfersAgentReducer,
    agentWhitelist: agentWhitelistReducer,
    developerWhitelist: developerWhitelistReducer,
    acctSwapLog: acctSwapLogReducer,
    cardBankLog: cardBankLogReducer,
    agentNotice: agentNoticeReducer,
    product: productReducer,
    productImage: productImageReducer,
  },
});
