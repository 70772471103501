import React, { useEffect, useState } from "react";
import { Card, Form, Spin, message } from "antd";
import { selectApp, getApps } from "@/store/slice/app";
import {
    getConfigAgentPayoutApp,
    postConfigAgentPayoutApp,
} from "@/store/slice/config";
import { useUserPerms } from "@/utils/hook";
import SearchSelect from "@/components/SearchSelect";

// 默认代理
function AgentPayoutApp({ formLayout }) {
    const perms = useUserPerms();
    const [appLdg, setAppLdg] = useState(false);
    const [appForm] = Form.useForm();
    const handleAppSelect = async ({ id, name }, postApi) => {
        setAppLdg(true);
        const { status } = await postApi({ app_id: id, name });
        setAppLdg(false);
        if (status !== 200) return;
        message.success("更新成功");
        handleGetAgentPayoutAppConfig();
    };
    const handleGetAgentPayoutAppConfig = async () => {
        setAppLdg(true);
        const { status, data } = await getConfigAgentPayoutApp();
        setAppLdg(false);
        if (status === 200) appForm.setFieldsValue({ ...data });
    };

    useEffect(() => {
        handleGetAgentPayoutAppConfig();
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Card title="代理提款商户" size="small">
            <Spin spinning={appLdg}>
                <Form form={appForm} {...formLayout}>
                    <Form.Item name="app_id" label="商户">
                        <SearchSelect
                            action={getApps}
                            selector={selectApp}
                            onSelect={data => handleAppSelect(data, postConfigAgentPayoutApp)}
                            searchKey="name"
                            val="id"
                            label={i => `${i.id} ${i.name}`}
                            disabled={!perms["config.agent_payout_app_update"]}
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </Card>
    );
}

export default AgentPayoutApp;
