import React, { useEffect, useState } from "react";
import { Card, Form, Spin, message, Button, InputNumber } from "antd";
import {
    getConfigGatewaySucceedRateTime,
    postGatewaySucceedRateTime,
} from "@/store/slice/config";
import { useUserPerms } from "@/utils/hook";

// 设置客服网址
function GatewaySucceedRateTime({ formLayout }) {
    const perms = useUserPerms();
    const [gatewaySucceedRateTimeLdg, setGatewaySucceedRateTimeLdg] = useState(false);
    const [gatewaySucceedRateTimeForm] = Form.useForm();

    const onFinishGatewaySucceedRateTime = async ({ minute }) => {
        setGatewaySucceedRateTimeLdg(true);
        const { status } = await postGatewaySucceedRateTime({ minute: minute })
        setGatewaySucceedRateTimeLdg(false)
        if (status === 200) {
            message.success("更新成功");
            handleGetGatewaySucceedRateTimeConfig();
        }
    };

    const [gatewaySucceedRateTimeData, setGatewaySucceedRateTimeData] = useState({});
    const handleGetGatewaySucceedRateTimeConfig = async () => {
        setGatewaySucceedRateTimeLdg(true);
        const { status, data } = await getConfigGatewaySucceedRateTime()
        setGatewaySucceedRateTimeLdg(false);
        if (status === 200) setGatewaySucceedRateTimeData({ ...data });
        gatewaySucceedRateTimeForm.setFieldsValue({ ...data });
    };

    useEffect(() => {
        handleGetGatewaySucceedRateTimeConfig();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Card title="设置渠道成功率取样时间" size="small">
            <Spin spinning={gatewaySucceedRateTimeLdg}>
                <Form
                    form={gatewaySucceedRateTimeForm}
                    onFinish={onFinishGatewaySucceedRateTime}
                    initialValues={{ url: gatewaySucceedRateTimeData.minute }}
                    {...formLayout}
                >
                    <Form.Item
                        name="minute"
                        label="时间(分钟)"
                        rules={[
                            {
                                required: true,
                                message: "请输入取样时间",
                            },
                            {
                                type: "number",
                                max: 129600,
                                message: "The input is not a number, max = 129600"
                            }
                        ]}
                    >
                        <InputNumber defaultValue={gatewaySucceedRateTimeData.minute} step="5" min="0" />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={!perms["config.gateway_succeed_rate_time_update"]}
                        >
                            确认提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </Card>
    );
}

export default GatewaySucceedRateTime;