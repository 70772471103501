import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectConfig } from "@/store/slice/config";
import { OrderStatus, PayMethod, Currency, PayStatus } from "@/utils/enum";
import { priceFormat, dateFormat } from "@/utils/format";
import { isActiveLang, isSuccessLang } from "@/utils/enum";
import Tag from "@/components/Tag";

const excelPriceFormat = (val, currency) =>
  priceFormat({ val, currency, showUnit: false });

const defaultColumns = [
  "id",
  "order_no",
  "gateway_name",
  "agent_name",
  "app_name_cn",
  "is_online",
  "amount",
  "amount_paid",
  "succeeded",
  "paid_at",
  "status",
  "created",
  "action",
];

const sumColumns = [
  {
    title: "订单次数",
    dataIndex: "total_times",
  },
  {
    title: "订单成功次数",
    dataIndex: "total_succeeded_times",
  },
  {
    title: "订单成功金额",
    dataIndex: "total_succeeded_amount",
    render: val => priceFormat({ val, currency: 0 }),
  },
];

function useColumn() {
  const { payTypeMap } = useSelector(selectConfig);

  const tableColumns = useMemo(
    () => [
      { title: "ID", dataIndex: "id", sorter: true },
      { title: "订单号", dataIndex: "order_no" },
      { title: "第三方订单号", dataIndex: "trans_no" },
      { title: "AppID", dataIndex: "app_id", sorter: true },
      { title: "商戶ID", dataIndex: "developer_id", sorter: true },
      { title: "会员ID", dataIndex: "userid", sorter: true },
      { title: "会员姓名", dataIndex: "payer_name", width: 100 },
      { title: "商戶名称", dataIndex: "app_name_cn", width: 100 },
      { title: "帐户名称", dataIndex: "acct_name" },
      {
        title: "通道名称",
        dataIndex: "gateway_name",
      },
      {
        title: "代理名称",
        dataIndex: "agent_name",
      },
      {
        title: "订单状态",
        dataIndex: "status",
        render: val => OrderStatus[val] || "",
        width: 100,
      },
      {
        title: "支付类别",
        dataIndex: "pay_type",
        render: val => payTypeMap[val] || "",
        width: 100,
      },
      {
        title: "付款方式",
        dataIndex: "pay_method",
        className: "text-nowrap",
        render: val => PayMethod[val] || "",
      },
      {
        title: "付款人信息",
        dataIndex: "payer_cred",
        width: 100,
        render: val => JSON.stringify(val),
      },
      { title: "设备类型", dataIndex: "device_type", width: 100 },
      {
        title: "支付状态",
        dataIndex: "pay_status",
        render: val => PayStatus[val] || "",
        width: 100,
      },
      {
        title: "支付时间",
        dataIndex: "paid_at",
        className: "text-nowrap",
        render: val => dateFormat(val),
        sorter: true,
      },
      {
        title: "审核状态",
        dataIndex: "approval_status",
        render: val => OrderStatus[val] || "",
      },
      {
        title: "审核人员",
        dataIndex: "approvers",
      },
      {
        title: "通知状态",
        dataIndex: "notify_status",
        render: val => OrderStatus[val] || "",
      },
      {
        title: "通知时间",
        dataIndex: "notified_at",
        className: "text-nowrap",
        render: val => dateFormat(val),
        sorter: true,
      },
      { title: "IP", dataIndex: "client_ip" },
      { title: "错误代码", dataIndex: "failure_code", width: 100 },
      { title: "错误信息", dataIndex: "failure_msg", width: 100 },
      {
        title: "支付金额",
        dataIndex: "amount",
        render: (val, record) =>
          priceFormat({ val, currency: record.currency }),
        className: "text-nowrap",
        sorter: true,
      },
      {
        title: "实际付款金额",
        dataIndex: "amount_paid",
        render: (val, record) =>
          priceFormat({ val, currency: record.currency }),
        className: "text-nowrap",
        sorter: true,
      },
      {
        title: "赠送金额",
        dataIndex: "bonus",
        render: (val, record) =>
          priceFormat({ val, currency: record.currency }),
        className: "text-nowrap",
        sorter: true,
      },
      {
        title: "随机金额",
        dataIndex: "amount_rand",
        render: (val, record) =>
          priceFormat({ val, currency: record.currency }),
        className: "text-nowrap",
        sorter: true,
      },
      {
        title: "货币类型",
        dataIndex: "currency",
        render: val => Currency[val] || "",
        className: "text-nowrap",
      },
      {
        title: "是否成功",
        dataIndex: "succeeded",
        render: (val, { status }) => {
          const succeededVal = val;
          return [4, 5, 6, 7, 9, 10, 13, 14, 15].includes(status) ? (
            <Tag val={succeededVal}>{isSuccessLang(succeededVal)}</Tag>
          ) : null;
        },
      },
      {
        title: "是否已付款",
        dataIndex: "paid",
        render: val => <Tag val={val} />,
      },
      {
        title: "审核通过",
        dataIndex: "approved",
        render: val => <Tag val={val} />,
      },
      {
        title: "是否加密货币",
        dataIndex: "is_crypto",
        render: val => <Tag val={val} />,
      },
      {
        title: "是否在线订单",
        dataIndex: "is_online",
        render: val => <Tag val={val} />,
      },
      {
        title: "清算成功",
        dataIndex: "settled",
        render: val => <Tag val={val} />,
      },
      {
        title: "扣除通道费后金额汇总",
        dataIndex: "amount_gateway",
        render: (val, record) =>
          priceFormat({ val, currency: record.currency }),
      },
      {
        title: "扣除商户费(商户+通道)后金额汇总",
        dataIndex: "amount_app",
        render: (val, record) =>
          priceFormat({ val, currency: record.currency }),
      },
      {
        title: "累计盈利金额",
        dataIndex: "amount_app_fee",
        render: (val, record) =>
          priceFormat({ val, currency: record.currency }),
      },
      {
        title: "创建日期",
        dataIndex: "created",
        render: val => dateFormat(val),
        className: "text-nowrap",
        sorter: true,
      },
      {
        title: "更新日期",
        dataIndex: "updated",
        render: val => dateFormat(val),
        className: "text-nowrap",
        sorter: true,
      },
    ],
    [payTypeMap],
  );

  const exportColumnsRenderMap = useMemo(
    () => ({
      status: val => OrderStatus[val] || "",
      pay_type: val => payTypeMap[val] || "",
      pay_method: val => PayMethod[val] || "",
      payer_cred: val => JSON.stringify(val),
      pay_status: val => PayStatus[val] || "",
      paid_at: val => dateFormat(val),
      approval_status: val => OrderStatus[val] || "",
      notify_status: val => OrderStatus[val] || "",
      notified_at: val => dateFormat(val),
      amount: (val, record) => excelPriceFormat(val, record.currency),
      amount_paid: (val, record) => excelPriceFormat(val, record.currency),
      bonus: (val, record) => excelPriceFormat(val, record.currency),
      amount_rand: (val, record) => excelPriceFormat(val, record.currency),
      currency: val => Currency[val] || "",
      succeeded: (val, { status }) =>
        [4, 5, 6, 7, 9, 10, 13, 14, 15].includes(status)
          ? isSuccessLang(val)
          : null,
      paid: val => isActiveLang(val),
      approved: val => isActiveLang(val),
      is_crypto: val => isActiveLang(val),
      is_online: val => isActiveLang(val),
      settled: val => isActiveLang(val),
      amount_gateway: (val, record) => excelPriceFormat(val, record.currency),
      amount_app: (val, record) => excelPriceFormat(val, record.currency),
      amount_app_fee: (val, record) => excelPriceFormat(val, record.currency),
      created: val => dateFormat(val),
      updated: val => dateFormat(val),
    }),
    [payTypeMap],
  );

  return { tableColumns, exportColumnsRenderMap, sumColumns, defaultColumns };
}

export default useColumn;
