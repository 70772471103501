import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Space, Button } from "antd";
import {
  selectAppDaily,
  getAppDaily,
  getAppDailySum,
  getAppDailyRefresh,
  exportOrder,
} from "@/store/slice/appDaily";
import { selectApp, getApps } from "@/store/slice/app";
import { SearchFormFactory } from "@/components/factory/FormFactory";
import {
  useList,
  useUserPerms,
  useExportExcel,
  useSearchCache,
} from "@/utils/hook";
import { Currency, IsBoolEnum } from "@/utils/enum";
import { NormalTable } from "@/components/factory/TableFactory";
import RefreshDataModal from "@/components/RefreshDataModal";
import SumTable from "@/components/SumTable";
import {
  columns,
  sumColumns,
  statisticsColumns,
  exportColumnsRenderMap,
} from "./AppDailyColumns";

const AppDaily = () => {
  const perms = useUserPerms();
  const searchFields = {
    id__in: { type: "string", label: "ID" },
    ...(perms["app.List"] && {
      app_id__in: {
        type: "searchSelect",
        label: "商户名称",
        action: getApps,
        selector: selectApp,
        searchKey: "name",
        val: "id",
        mode: "multiple",
        optionLabel: i => `${i.id} ${i.name}`,
      },
    }),
    created__btw: { type: "rangeDate", label: "报表时间", showTime: false },
    currency: { type: "select", label: "货币类型", options: Currency },
    is_online: {
      type: "select",
      label: "是否线上渠道",
      options: IsBoolEnum,
    },
  };

  const {
    res: { list, meta, sum },
    loading: listLoading,
    handleGetList,
    handleSearch,
    handleChangePage,
    handleChange,
  } = useList(getAppDaily, selectAppDaily);
  const handleList = useCallback(
    resList =>
      resList.map(ll => {
        // 存提差
        const difference =
          ll.total_succeeded_amount - ll.transfer_succeeded_amount;
        // 代收手续费
        const order_fee = ll.total_amount_gateway_fee + ll.total_profit_amount;
        // 代付手续费
        const transfer_fee =
          ll.transfer_succeeded_app_fee + ll.transfer_succeeded_gateway_fee;
        // 今天盈利
        const profit_today = difference - order_fee - transfer_fee;
        // YL盈利
        const yl_profit_today =
          ll.total_profit_amount + ll.transfer_succeeded_app_fee;
        return {
          ...ll,
          difference,
          order_fee,
          transfer_fee,
          profit_today,
          yl_profit_today,
        };
      }),
    [],
  );

  const dispatch = useDispatch();
  const { handleGetPageSearchCache } = useSearchCache();
  const getSum = (params = {}) => {
    perms["app.daily.sum"] &&
      dispatch(getAppDailySum({ ...handleGetPageSearchCache(), ...params }));
  };
  useEffect(() => {
    getSum();
    // eslint-disable-next-line
  }, []);
  const [searchData, setSearchData] = useState({});
  const handleSearchClick = params => {
    setSearchData(params);
    handleSearch(params);
    getSum(params);
  };

  const {
    loading: exportLoading,
    handleExport,
    handleSelectedColumns,
  } = useExportExcel({
    exportColumnsRenderMap,
    exportAction: exportOrder,
    handleExportData: handleList,
  });

  const [refreshVisible, setRefreshVisible] = useState(false);

  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchFormFactory
        fields={searchFields}
        handleSubmit={handleSearchClick}
        showExportBtn={true}
        handleExport={handleExport}
        exportLoading={exportLoading}
        otherBtn={
          <Button type="primary" onClick={() => setRefreshVisible(true)}>
            更新报表
          </Button>
        }
      />
      {perms["app.daily.sum"] && (
        <SumTable
          data={sum}
          labels={sumColumns}
          columnSize={{ xs: 1, sm: 3, xl: 4, xxl: 8 }}
        />
      )}
      <NormalTable
        allColumns={columns}
        defaultColumns={columns.map(i => i.dataIndex)}
        dataSource={handleList(list)}
        meta={meta}
        onChangePage={handleChangePage}
        onChange={handleChange}
        loading={listLoading}
        onShowSizeChange={handleChangePage}
        statisticsColumns={statisticsColumns}
        setSelectedColumns={handleSelectedColumns}
      />
      <RefreshDataModal
        visible={refreshVisible}
        setVisible={setRefreshVisible}
        refreshAction={getAppDailyRefresh}
        callback={() => {
          handleGetList();
          getSum(searchData);
        }}
      />
    </Space>
  );
};
export default AppDaily;
