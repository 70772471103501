import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Space, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  selectAppAcct,
  getAppAccts,
  getAppAcct,
  addAppAcct,
  editAppAcct,
  balanceAppAcct,
  blockAmount,
  refundBlockAmount,
} from "@/store/slice/appAcct";
import { selectAuth } from "@/store/slice/auth";
import { SearchFormFactory } from "@/components/factory/FormFactory";
import { NormalTable } from "@/components/factory/TableFactory";
import EditableConfirm from "@/components/EditableConfirm";
import Detail from "@/components/Detail";
import JsonModal from "@/components/JsonModal";
import { useList, useDetail, useUserPerms } from "@/utils/hook";
// import { IsBoolEnum, AppStatus } from "@/utils/enum";
import { dateFormat, priceFormat } from "@/utils/format";
import { Currency, FeeType } from "@/utils/enum";
import AddEdit from "./AddEdit";

const searchFields = {
  id__in: { type: "string", label: "ID" },
  app_id__in: { type: "string", label: "商户ID" },
  app_name__k: { type: "string", label: "商户姓名" },
  created__btw: { type: "rangeDate", label: "创建日期" },
};

const balanceFields = [
  {
    label: "增加或减少金額",
    name: "amount",
    inputType: "price",
    required: true,
    min: null,
  },
  {
    label: "备注",
    name: "note",
    inputType: "string",
  },
];
const blockAmountFields = [
  {
    label: "增加或减少金額",
    name: "amount",
    inputType: "price",
    required: true,
    min: null,
  },
  {
    label: "备注",
    name: "note",
    inputType: "string",
  },
];
const refundBlockAmountFields = [
  {
    label: "退还金額",
    name: "amount",
    inputType: "price",
    required: true,
    min: 0,
  },
  {
    label: "备注",
    name: "note",
    inputType: "string",
  },
];

const AppAcct = () => {
  const perms = useUserPerms();
  const { user } = useSelector(selectAuth);
  const {
    res: { list, meta },
    loading: listLoading,
    handleSearch,
    handleChangePage,
    handleChange,
    handleGetList,
    handleAdd: handleAddHook,
  } = useList(getAppAccts, selectAppAcct);

  const [addVisible, setAddVisible] = useState(false);
  const handleAddClick = () => {
    setAddVisible(true);
  };
  const handleAdd = async formModel => {
    handleAddHook({ action: addAppAcct, ...formModel });
    setAddVisible(false);
  };

  const [detailId, setDetailId] = useState(null);
  const {
    currentRow,
    loading: detailLoading,
    setLoading: setDetailLoading,
    handleEdit: handleEditHook,
  } = useDetail({ action: getAppAcct, id: detailId }, selectAppAcct);
  const [detailVisible, setDetailVisible] = useState(false);
  const handleDetailClick = async id => {
    setDetailId(id);
    setDetailVisible(true);
  };

  const [jsonVisible, setJsonVisible] = useState(false);
  const handleJsonClick = async id => {
    setDetailId(id);
    setJsonVisible(true);
  };
  useEffect(() => {
    jsonVisible || detailVisible || setDetailId(null);
  }, [jsonVisible, detailVisible]);

  const [editVisible, setEditVisible] = useState(false);
  const handleEditClick = async id => {
    setDetailId(id);
    setEditVisible(true);
  };
  const handleEdit = async formModel => {
    const { status } = await handleEditHook({
      action: editAppAcct,
      id: currentRow.id,
      ...formModel,
    });
    if (status !== 200) return;
    setEditVisible(false);
    setDetailId(null);
    handleGetList({ page: meta.current });
  };

  const [balanceVisible, setBalanceVisible] = useState(false);
  const handleBalanceClick = record => {
    setDetailId(record.id);
    setBalanceVisible(true);
  };
  const handleCancelBalance = () => {
    setBalanceVisible(false);
    setDetailId(null);
  };
  const handleBalance = async formModel => {
    setDetailLoading(true);
    const { status } = await balanceAppAcct({
      id: currentRow.id,
      formModel: { ...formModel, user_id: user.id, username: user.username },
    });
    setDetailLoading(false);
    if (status !== 200) return;
    handleCancelBalance();
    message.success(`已更新余额`);
    handleGetList({ page: meta.current });
  };

  /**
   * 修改 圈存金額
   */
  const [blockAmountVisible, setBlockAmountVisible] = useState(false);
  const handleBlockAmountClick = record => {
    setDetailId(record.id);
    setBlockAmountVisible(true);
  };
  const handleCancelBlockAmount = () => {
    setBlockAmountVisible(false);
    setDetailId(null);
  };
  const handleBlockAmount = async formModel => {
    setDetailLoading(true);
    const { status } = await blockAmount({
      id: currentRow.id,
      formModel,
    });
    setDetailLoading(false);
    if (status !== 200) return;
    handleCancelBlockAmount();
    message.success("已修改圈存金额");
    handleGetList({ page: meta.current });
  };

  /**
   * 退還 圈存金額
   */
  const [refundBlockAmountVisible, setRefundBlockAmountVisible] =
    useState(false);
  const handleRefundBlockAmountClick = record => {
    setDetailId(record.id);
    setRefundBlockAmountVisible(true);
  };
  const handleCancelRefundBlockAmount = () => {
    setRefundBlockAmountVisible(false);
    setDetailId(null);
  };
  const handleRefundBlockAmount = async formModel => {
    setDetailLoading(true);
    const { status } = await refundBlockAmount({
      id: currentRow.id,
      formModel,
    });
    setDetailLoading(false);
    if (status !== 200) return;
    handleCancelRefundBlockAmount();
    message.success("已退还圈存金额");
    handleGetList({ page: meta.current });
  };

  const columns = [
    { title: "ID", dataIndex: "id", sorter: true },
    { title: "商户名称", dataIndex: "app_name", sorter: true },
    {
      title: "余额",
      dataIndex: "balance",
      render: (val, record) => priceFormat({ val, currency: record.currency }),
      className: "text-nowrap",
      sorter: true,
    },
    {
      title: "圈存金额",
      dataIndex: "block_amount",
      render: (val, record) => priceFormat({ val, currency: record.currency }),
      className: "text-nowrap",
      sorter: true,
    },
    {
      title: "币种",
      dataIndex: "currency",
      render: val => Currency[val] || "",
      className: "text-nowrap",
    },
    {
      title: "第三方代付手续费类型",
      dataIndex: "transfer_fee_type",
      render: val => FeeType[val] || "",
      className: "text-nowrap",
    },
    {
      title: "第三方代付手续费",
      dataIndex: "transfer_fee",
      render: (val, record) => priceFormat({ val, currency: record.currency }),
      className: "text-nowrap",
    },
    {
      title: "第三方代付手续费百分比",
      dataIndex: "transfer_fee_rate",
      render: val => `${val}%`,
      className: "text-nowrap",
    },
    { title: "商户ID", dataIndex: "app_id", sorter: true },
    {
      title: "线下代付手续费",
      dataIndex: "agent_fee",
      render: val => `${val}%`,
      className: "text-nowrap",
    },
    {
      title: "创建日期",
      dataIndex: "created",
      render: val => dateFormat(val),
      className: "text-nowrap",
      sorter: true,
    },
    {
      title: "更新日期",
      dataIndex: "updated",
      render: val => dateFormat(val),
      className: "text-nowrap",
      sorter: true,
    },
    {
      title: "动作",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      render: (_, record) => (
        <Space>
          <Button
            size="small"
            onClick={() => handleJsonClick(record.id)}
            type="link"
            className="p-0"
            disabled={!perms["appacct.Get"]}
          >
            json
          </Button>
          <Button
            size="small"
            onClick={() => handleDetailClick(record.id)}
            type="link"
            className="p-0"
            disabled={!perms["appacct.Get"]}
          >
            查看
          </Button>
          <Button
            size="small"
            onClick={() => handleBalanceClick(record)}
            type="link"
            className="p-0"
            disabled={!perms["appacct.UpdateBalance"]}
          >
            修改余额
          </Button>
          <Button
            size="small"
            onClick={() => handleBlockAmountClick(record)}
            type="link"
            className="p-0"
            disabled={!perms["appacct.UpdateBlockAmount"]}
          >
            修改圈存金额
          </Button>
          <Button
            size="small"
            onClick={() => handleRefundBlockAmountClick(record)}
            type="link"
            className="p-0"
            disabled={!perms["appacct.RefundBlockAmount"]}
          >
            退还圈存金额
          </Button>
          <Button
            size="small"
            onClick={() => handleEditClick(record.id)}
            type="text"
            className="p-0"
            disabled={!perms["appacct.Get"] || !perms["appacct.Update"]}
          >
            编辑
          </Button>
        </Space>
      ),
    },
  ];
  const defaultColumns = [
    "id",
    "app_name",
    "balance",
    "block_amount",
    "currency",
    "app_id",
    "created",
    "action",
  ];
  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchFormFactory fields={searchFields} handleSubmit={handleSearch} />
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={handleAddClick}
        disabled={!perms["appacct.Create"]}
      >
        添加
      </Button>
      <NormalTable
        allColumns={columns}
        defaultColumns={defaultColumns}
        dataSource={list}
        meta={meta}
        onChangePage={handleChangePage}
        onChange={handleChange}
        loading={listLoading}
        onShowSizeChange={handleChangePage}
      />
      <AddEdit
        visible={addVisible}
        onOk={handleAdd}
        onCancel={() => setAddVisible(false)}
        loading={listLoading}
        mode="add"
      />
      <JsonModal
        visible={jsonVisible}
        data={currentRow}
        onCancel={() => setJsonVisible(false)}
        loading={detailLoading}
      />
      <Detail
        title="商户账户明细"
        visible={detailVisible}
        data={currentRow}
        onCancel={() => setDetailVisible(false)}
        loading={detailLoading}
        columns={columns.filter(i => i.dataIndex !== "action")}
      />
      <EditableConfirm
        title="修改余额"
        fields={balanceFields}
        visible={balanceVisible}
        data={{ ...currentRow, currency: 0 }}
        onCancel={handleCancelBalance}
        loading={detailLoading}
        onOk={handleBalance}
      />
      <EditableConfirm
        title="修改圈存金额"
        fields={blockAmountFields}
        visible={blockAmountVisible}
        data={{ ...currentRow, currency: 0 }}
        onCancel={handleCancelBlockAmount}
        loading={detailLoading}
        onOk={handleBlockAmount}
      />
      <EditableConfirm
        title="退还圈存金额"
        fields={refundBlockAmountFields}
        visible={refundBlockAmountVisible}
        data={{ ...currentRow, currency: 0 }}
        onCancel={handleCancelRefundBlockAmount}
        loading={detailLoading}
        onOk={handleRefundBlockAmount}
      />
      <AddEdit
        visible={editVisible}
        onOk={handleEdit}
        onCancel={() => setEditVisible(false)}
        loading={detailLoading}
        data={currentRow}
        mode="edit"
      />
    </Space>
  );
};
export default AppAcct;
