import React, { useState } from "react";
import { Space, Button, Modal } from "antd";
import {
  selectAgentNotice,
  getAgentNotices,
  getAgentNotice,
  addAgentNotice,
  editAgentNotice,
  deleteAgentNotice,
} from "@/store/slice/agentNotice";
import { SearchFormFactory } from "@/components/factory/FormFactory";
import { useList, useDetail, useUserPerms } from "@/utils/hook";
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { EditableTable } from "@/components/factory/TableFactory";
import AddEdit from "./AddEdit";
import { dateFormat } from "@/utils/format";


function AgentNotice() {
  const perms = useUserPerms();
  const searchFields = {
    id__in: { type: "string", label: "ID" },
    content__k: { type: "string", label: "公告内容" },
  };
  const {
    res: { list, meta },
    loading: listLoading,
    handleSearch,
    handleGetList,
    handleChangePage,
    handleChange,
    handleAdd: handleAddHook,
  } = useList(getAgentNotices, selectAgentNotice);
  const [addVisible, setAddVisible] = useState(false);
  const handleAddClick = () => {
    setAddVisible(true);
  };
  const handleAdd = async formModel => {
    handleAddHook({ action: addAgentNotice, ...formModel });
    setAddVisible(false);
  };
  const [detailId, setDetailId] = useState(null);
  const {
    currentRow,
    loading: detailLoading,
    handleEdit: handleEditHook,
  } = useDetail({ action: getAgentNotice, id: detailId }, selectAgentNotice);
  const [editVisible, setEditVisible] = useState(false);
  const handleEditClick = async id => {
    setDetailId(id);
    setEditVisible(true);
  };
  const handleEdit = async formModel => {
    const { status } = await handleEditHook({
      action: editAgentNotice,
      id: currentRow.id,
      ...formModel,
    });
    if (status !== 200) return;
    setEditVisible(false);
    setDetailId(null);
    handleGetList({ page: meta.current });
  };
  const handleDelete = async (close, id) => {
    const { status } = await deleteAgentNotice(id);
    close();
    if (status !== 200) return;
    handleGetList({ page: meta.current });
  };

  const handleDeleteClick = id => {
    Modal.confirm({
      title: "是否删除",
      icon: <ExclamationCircleOutlined />,
      content: `即将删除 ${id}，是否继续？`,
      okText: "确认",
      cancelText: "取消",
      onOk: close => handleDelete(close, id),
    });
  };
  const handleRowEditSubmit = async ({ id, ...params }) => {
    await handleEditHook({ action: editAgentNotice, id, ...params });
    handleGetList({ page: meta.current });
  };
  const columns = [
    { title: "id", dataIndex: "id" },
    { title: "公告內容", dataIndex: "content", editable: true },
    { title: "创建者ID", dataIndex: "create_id" },
    { title: "创建者", dataIndex: "create_name"},
    {
      title: "创建日期",
      dataIndex: "created",
      render: val => dateFormat(val),
      className: "text-nowrap",
      sorter: true,
    },
    { title: "更新者ID", dataIndex: "update_id" },
    { title: "更新者", dataIndex: "update_name" },
    {
      title: "更新日期",
      dataIndex: "updated",
      render: val => dateFormat(val),
      className: "text-nowrap",
      sorter: true,
    },
    {
      title: "动作",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      render: (_, record) => (
        <Space>
          <Button
            size="small"
            type="text"
            className="p-0"
            onClick={() => handleEditClick(record.id)}
            disabled={!perms["agent_notice.List"] || !perms["agent_notice.Update"]}
          >
            编辑
          </Button>
          <Button
            size="small"
            type="text"
            className="p-0"
            danger
            onClick={() => handleDeleteClick(record.id)}
            disabled={!perms["agent_notice.Delete"]}
          >
            删除
          </Button>
        </Space>
      ),
    },
  ];
  const defaultColumns = ["id", "content", "create_id", "create_name", "created", "update_id", "update_name", "updated", "action"];
  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchFormFactory fields={searchFields} handleSubmit={handleSearch} />
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={handleAddClick}
        disabled={!perms["agent_notice.Create"]}
      >
        添加
      </Button>
      <EditableTable
        allColumns={columns}
        dataSource={list}
        loading={listLoading}
        defaultColumns={defaultColumns}
        onChangePage={handleChangePage}
        onChange={handleChange}
        onRowEditSubmit={handleRowEditSubmit}
        onShowSizeChange={handleChangePage}
        meta={meta}
        editDisabled={!perms["agent_notice.Update"]}
      />
      <AddEdit
        visible={addVisible}
        onOk={handleAdd}
        onCancel={() => setAddVisible(false)}
        loading={listLoading}
        mode="add"
      />
      <AddEdit
        visible={editVisible}
        onOk={handleEdit}
        onCancel={() => setEditVisible(false)}
        loading={detailLoading}
        data={currentRow}
        mode="edit"
      />
    </Space>
  );
}
export default AgentNotice;
