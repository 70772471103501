import React from "react";
import { Form } from "antd";
import { getApps, selectApp } from "@/store/slice/app";
import SearchSelect from "@/components/SearchSelect";

function AppUserSelect({ name = "", onSelect = () => {} }) {
  return (
    <Form.Item
      name={name}
      label="来源使用者"
      rules={[{ required: true, message: "请选择" }]}
    >
      <SearchSelect
        action={getApps}
        selector={selectApp}
        searchKey="name"
        onSelect={onSelect}
        val="id"
        label={i => `${i.developer_id} ${i.developer_name}`}
        allowClear
      />
    </Form.Item>
  );
}

export default AppUserSelect;
