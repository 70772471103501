import { useEffect, useState } from "react";
import { Modal, Form, Select, Input } from "antd";
import { formLayout, mode as Mode, Currency } from "@/utils/enum";
import { selectGateway, getGateways } from "@/store/slice/gateway";
import SearchSelect from "@/components/SearchSelect";
import Spin from "@/components/Spin";
import { CurrencyHelpTextFormItemFactory } from "@/components/factory/FormFactory";
const { Option } = Select;
const AddEdit = ({
  visible = false,
  loading = false,
  data = {},
  mode = "add",
  onOk = () => {},
  onCancel = () => {},
}) => {
  const [currGateway, setCurrGateway] = useState({});
  const onSelectGateway = data => {
    setCurrGateway({
      gateway_id: data.id,
      gateway_name: data.name,
      gateway_alias: data.alias,
    });
  };

  const [form] = Form.useForm();
  const handleOk = async () => {
    form.validateFields().then(async formModel => {
      if (!formModel) return;
      onOk({ ...formModel, ...currGateway });
    });
  };

  useEffect(() => {
    visible && mode === "edit" && form.setFieldsValue({ ...data });
    // eslint-disable-next-line
  }, [visible, data]);

  return (
    <Modal
      title={`${Mode[mode]}第三方余额帐户`}
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      cancelText="取消"
      okText="送出"
      confirmLoading={loading}
      destroyOnClose
    >
      <Spin spinning={loading}>
        <Form {...formLayout} form={form} preserve={false}>
          {mode === "add" ? (
            <>
              <Form.Item
                name="gateway_id"
                label="渠道列表"
                rules={[{ required: true, message: "请选择渠道" }]}
              >
                <SearchSelect
                  action={getGateways}
                  selector={selectGateway}
                  searchKey="name"
                  val="id"
                  label={i => `${i.id} ${i.name}`}
                  onSelect={onSelectGateway}
                />
              </Form.Item>
              <Form.Item
                name="currency"
                label="货币类型"
                rules={[{ required: true, message: "请选择货币类型" }]}
              >
                <Select>
                  {Object.keys(Currency).map(i => (
                    <Option value={Number(i)} key={i}>
                      {Currency[i]}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <CurrencyHelpTextFormItemFactory
                name="balance"
                label="余额"
                defaultValKey="balance"
              />
            </>
          ) : (
            <>
              <Form.Item
                name="gateway_name"
                label="渠道名称"
                rules={[{ required: true, message: "请輸入渠道名称" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="gateway_alias"
                label="渠道別名"
                rules={[{ required: true, message: "请輸入渠道別名" }]}
              >
                <Input />
              </Form.Item>
            </>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};
export default AddEdit;
