import React from "react";
import { Space } from "antd";
import {
  selectGatewayAcctLog,
  getGatewayAcctLogs,
} from "@/store/slice/gatewayAcctLog";
import { SearchFormFactory } from "@/components/factory/FormFactory";
import { NormalTable } from "@/components/factory/TableFactory";
import { dateFormat, priceFormat } from "@/utils/format";
import { Currency, GatewayAcctLogType } from "@/utils/enum";
import { useList } from "@/utils/hook";

const GatewayAcctLogs = () => {
  const searchFields = {
    id__in: { type: "string", label: "ID" },
    gateway_acct_id__in: { type: "string", label: "渠道帐户ID" },
    gateway_id__in: { type: "string", label: "渠道ID" },
    gateway_name__k: { type: "string", label: "渠道名称" },
    order_no__in: { type: "string", label: "订单号" },
    created__btw: { type: "rangeDate", label: "创建日期" },
  };
  const {
    res: { list, meta },
    loading: listLoading,
    handleSearch,
    handleChangePage,
    handleChange,
  } = useList(getGatewayAcctLogs, selectGatewayAcctLog);

  const handleSearchSubmit = (params = {}) => {
    handleSearch(params);
  };

  const columns = [
    { title: "ID", dataIndex: "id", sorter: true },
    { title: "渠道帐户ID", dataIndex: "gateway_acct_id", sorter: true },
    { title: "渠道ID", dataIndex: "gateway_id", sorter: true },
    { title: "渠道名称", dataIndex: "gateway_name", sorter: true },
    { title: "渠道別名", dataIndex: "gateway_alias", sorter: true },
    {
      title: "款项类型",
      dataIndex: "type",
      render: val => GatewayAcctLogType[val],
      sorter: true,
    },
    {
      title: "变动前金额",
      dataIndex: "b1",
      render: (val, record) => priceFormat({ val, currency: record.currency }),
      sorter: true,
      className: "text-nowrap",
    },
    {
      title: "变动后金额",
      dataIndex: "b2",
      render: (val, record) => priceFormat({ val, currency: record.currency }),
      sorter: true,
      className: "text-nowrap",
    },
    {
      title: "变动金额",
      dataIndex: "amount",
      render: (val, record) => priceFormat({ val, currency: record.currency }),
      sorter: true,
      className: "text-nowrap",
    },
    {
      title: "币种",
      dataIndex: "currency",
      render: val => Currency[val] || "",
      className: "text-nowrap",
    },
    { title: "订单号", dataIndex: "order_no" },
    { title: "第三方订单号", dataIndex: "trans_no" },
    { title: "客户端IP", dataIndex: "client_ip" },
    { title: "备注", dataIndex: "note" },
    { title: "操作者ID", dataIndex: "op_user_id" },
    { title: "操作者帐号", dataIndex: "op_username" },
    { title: "操作者", dataIndex: "op_user_name" },
    {
      title: "创建日期",
      dataIndex: "created",
      className: "text-nowrap",
      render: val => dateFormat(val),
      sorter: true,
    },
    {
      title: "更新日期",
      dataIndex: "updated",
      className: "text-nowrap",
      render: val => dateFormat(val),
      sorter: true,
    },
  ];
  const defaultColumns = [
    "id",
    "gateway_acct_id",
    "gateway_id",
    "gateway_name",
    "gateway_alias",
    "type",
    "b1",
    "b2",
    "amount",
    "currency",
    "order_no",
    "trans_no",
    "note",
    "created",
    "action",
  ];

  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchFormFactory
        fields={searchFields}
        handleSubmit={handleSearchSubmit}
      />
      <NormalTable
        allColumns={columns}
        defaultColumns={defaultColumns}
        dataSource={list}
        meta={meta}
        onChangePage={handleChangePage}
        onChange={handleChange}
        loading={listLoading}
        onShowSizeChange={handleChangePage}
      />
    </Space>
  );
};

export default GatewayAcctLogs;
