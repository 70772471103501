export const errorCodeMessage = {
  200: "服务器成功返回请求的数据。",
  201: "新建或修改数据成功。",
  202: "一个请求已经进入后台排队（异步任务）。",
  204: "删除数据成功。",
  400: "发出的请求有误。", // 发出的请求有错误，服务器没有进行新建或修改数据的操作。
  401: "用户没有权限（令牌、用户名、密码错误）。",
  403: "没有权限。",
  404: "找不到对象。",
  405: "方法不允许。",
  406: "请求的格式不可得。",
  410: "请求的资源被永久删除，且不会再得到的。",
  422: "当创建一个对象时，发生一个验证错误。",
  429: "请求太频繁了",
  500: "服务器发生错误",
  502: "网关错误。",
  503: "服务不可用，服务器暂时过载或维护。",
  504: "网关超时。",
};
export const formLayout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } };
export const mode = {
  add: "添加",
  edit: "编辑",
  approve: "审核",
  deny: "拒绝",
  batch: "批次",
};
export const isActiveLang = val => {
  return val ? "是" : "否";
};
export const isSuccessLang = val => {
  return val ? "成功" : "失败";
};

export const OrderStatus = {
  0: "初始化",
  1: "新订单",
  2: "待处理",
  3: "待付款",
  4: "已支付",
  5: "失败",
  6: "过期",
  7: "取消",
  8: "待审核",
  9: "审核通过",
  10: "审核拒绝",
  11: "待通知",
  12: "通知中",
  13: "通知成功",
  14: "通知失败",
  15: "成功",
  16: "订单支付失败",
  17: "订单检查中",
  18: "代理已付款",
};

// export const WXPayType = {
//   0: "微信", // wx_recharge_rate
//   1: "支付宝", // alipay_recharge_rate
//   2: "网银转账", // ebank_recharge_rate
//   3: "银行卡转账", // bank_recharge_rate
//   4: "加密货币", // crypto_recharge_rate
//   6: "QQ", // qq_recharge_rate
//   7: "数字人民币", // ecny_recharge_rate
//   8: "云闪付", // union_recharge_rate
//   9: "NoPay", // nopay_recharge_rate
//   10: "银联存款", // union_pay_recharge_rate
//   11: "抖音存款", // douyin_recharge_rate
// };

export const PayMethod = {
  0: "redirect 跳转收银台",
  1: "qrcode 二维码",
  2: "转账 ",
  3: "表单提交 ",
};

export const PayStatus = {
  3: "待付款",
  4: "已支付",
};

export const AddrRedirect = {
  0: "",
  1: "https://etherscan.io/address",
  2: "https://tronscan.org/#/address",
  3: "",
  4: "",
  5: "",
};
export const TransRedirect = {
  0: "",
  1: "https://etherscan.io/tx",
  2: "https://tronscan.org/#/transaction",
  3: "",
  4: "",
  5: "",
};

export const Currency = {
  0: "RMB",
  1: "USDT_ERC20",
  2: "USDT_TRC20",
  3: "USDT_OMNI",
  4: "BTC",
  5: "ETH",
  6: "TRX",
};

export const CurrencyKeys = {
  RMB: 0,
  USDT_ERC20: 1,
  USDT_TRC20: 2,
  USDT_OMNI: 3,
  BTC: 4,
  ETH: 5,
  TRX: 6,
};

export const CurrencyFormat = [
  { key: 0, unit: "¥", rate: 100, tofix: 2 },
  { key: 1, unit: "$", rate: 1000000, tofix: 6 },
  { key: 2, unit: "$", rate: 1000000, tofix: 6 },
  { key: 3, unit: "$", rate: 1000000, tofix: 6 },
  { key: 4, unit: "", rate: 100000000, tofix: 8 },
  { key: 5, unit: "", rate: 1000000000000000000, tofix: 18 },
  { key: 6, unit: "", rate: 1, tofix: 0 },
  { key: 7, unit: "¥", rate: 1, tofix: 0 },
];

export const IsBoolEnum = {
  1: "是",
  0: "否",
};

export const ApprovalStatus = {
  8: "待审核",
  9: "审核通过",
  10: "审核拒绝",
};
export const NotifyStatus = {
  11: "待通知",
  12: "通知中",
  13: "通知成功",
  14: "通知失败",
};

export const Network = {
  0: "TRX 波场",
  1: "ETH 以太坊",
  2: "BTC",
};

export const DeveloperStatus = {
  0: "审核中",
  1: "审核通过",
  2: "拒绝",
};

export const AppStatus = {
  0: "开发中",
  1: "提审中",
  2: "上线运营",
  3: "停用",
};

export const CryptoAcctLogsType = {
  0: "存款",
  1: "提款",
  2: "活动赠送",
  3: "扣罚",
  4: "其它",
};
export const CryptoAcctLogsStatus = {
  0: "交易未确认",
  1: "交易已确认",
  2: "待绑定",
  3: "已绑定订单",
};

export const ContentType = {
  1: "存款订单",
  2: "提款订单",
  3: "活动对象",
};

export const DirType = {
  0: "转入",
  1: "转出",
};

export const BankPayType = {
  0: "入帐",
  1: "出帐",
};

export const AuditType = {
  0: "未合帐",
  1: "已核帐",
  2: "核帐失败",
};

export const FeeType = {
  0: "固定",
  1: "百分比",
  2: "阶层利率",
  3: "固定＋一般利率",
  4: "固定＋阶层利率",
};

export const transferStatus = {
  0: "初始化代付",
  1: "下单失败",
  2: "待认领",
  3: "已认领待审核",
  4: "代付审核中",
  5: "代付已审核",
  6: "代付审核拒绝",
  7: "已经认领待出款",
  8: "出款中",
  9: "出款成功",
  10: "出款失败",
  11: "出款订单过期",
  12: "出款订单取消",
  13: "出款待回调",
  14: "出款回调中",
  15: "出款回调成功",
  16: "出款回调失败",
  17: "第三方代付失败",
  18: "出款中",
  19: "代理出款中",
  20: "代理撤回",
};

export const CardStatus = {
  0: "审核中",
  1: "启用中",
  2: "冻结",
  3: "已达到当日上限制",
};

export const CardDirection = { 0: "收入", 1: "支出" };

export const AmountType = {
  0: "任意金额",
  1: "固定金额",
};
export const TransfersCategory = {
  0: "用户代付订单",
  1: "商户提款订单",
};

export const AppAcctLogType = {
  0: "用户存款",
  1: "申请提款",
  2: "提款成功",
  3: "提款失败",
  4: "代理支付手续费",
  5: "代理支付手续费返还",
  6: "代理代收分润",
  7: "代理代收分润返还",
  8: "分润出帐",
  9: "分润入帐",
  10: "订单回收",
  11: "分润出帐返还",
  12: "分润入帐返还",
  13: "修改圈存金额",
  14: "划转入帐",
  15: "划转出帐",
  16: "其他",
  17: "支付渠道手续费",
  18: "支付渠道手续费返还",
};

export const AgentAcctLogType = {
  0: "其他",
  1: "代付出帐",
  2: "代付入帐",
  3: "代付手续费",
  4: "代付退回手续费",
  5: "代收入帐",
  6: "代收出帐",
  7: "代收分润",
  8: "代收分润返还",
  9: "修改圈存金额",
  10: "划转入帐",
  11: "划转出帐",
};

export const AcctSwapType = {
  0: "其他",
  1: "划转",
  2: "收回",
  3: "更新余额",
};

export const AcctSwapUserType = {
  0: "其他",
  1: "商户",
  2: "代理",
};

export const GatewayAcctLogType = {
  0: "其他",
  1: "用户存款",
  2: "用户提款",
  3: "更新余额",
};

export const ProductCategory = {
  0: "服装",
  1: "家居服饰",
  2: "数码产品",
};
export const IsSplitOrderStatus = {
  "": "全部",
  1: "总单",
  0: "子单",
};

// 安全提款
export const SaveWithdrawalStatus = {
  安全渠道: "是",
  "": "否",
};
