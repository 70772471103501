import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Space, Button } from "antd";
import {
  selectAppGatewayDaily,
  getAppGatewayDaily,
  getAppGatewayDailySum,
  exportAppGatewayDaily,
  getAppGatewayDailyRefresh,
} from "@/store/slice/appGatewayDaily";
import { selectApp, getApps } from "@/store/slice/app";
import { selectGateway, getGateways } from "@/store/slice/gateway";
import {
  useList,
  useUserPerms,
  useExportExcel,
  useSearchCache,
} from "@/utils/hook";
import { Currency, IsBoolEnum } from "@/utils/enum";
import { SearchFormFactory } from "@/components/factory/FormFactory";
import { NormalTable } from "@/components/factory/TableFactory";
import SumTable from "@/components/SumTable";
import RefreshDataModal from "@/components/RefreshDataModal";
import {
  sumColumns,
  columns,
  statisticsColumns,
  exportColumnsRenderMap,
} from "./AppGatewayDailyColumn";

const AppGatewayDaily = () => {
  const perms = useUserPerms();
  const searchFields = {
    id__in: { type: "string", label: "ID" },
    ...(perms["app.List"] && {
      app_id__in: {
        type: "searchSelect",
        label: "商户名称",
        action: getApps,
        selector: selectApp,
        searchKey: "name",
        val: "id",
        mode: "multiple",
        optionLabel: i => `${i.id} ${i.name}`,
      },
    }),
    ...(perms["gateway.List"] && {
      gateway_id__in: {
        type: "searchSelect",
        label: "渠道ID",
        action: getGateways,
        selector: selectGateway,
        searchKey: "id",
        val: "id",
        mode: "multiple",
        optionLabel: i => `${i.id} ${i.name}`,
      },
    }),
    // ...(perms["gateway.List"] && {
    //   gateway_name__k: {
    //     type: "searchSelect",
    //     label: "渠道名称",
    //     action: getGateways,
    //     selector: selectGateway,
    //     searchKey: "name",
    //     val: "name",
    //     allowClear: true,
    //     optionLabel: i => `${i.id} ${i.name}`,
    //   },
    // }),
    gateway_name__k: { type: "string", label: "渠道名称", allowClear: true },
    created__btw: { type: "rangeDate", label: "报表时间", showTime: false },
    currency: { type: "select", label: "货币类型", options: Currency },
    is_online: {
      type: "select",
      label: "是否线上渠道",
      options: IsBoolEnum,
    },
  };
  const {
    res: { list, meta, sum },
    loading: listLoading,
    handleSearch,
    handleGetList,
    handleChangePage,
    handleChange,
  } = useList(getAppGatewayDaily, selectAppGatewayDaily);
  const dispatch = useDispatch();
  const handleList = resList =>
    resList.map(ll => {
      // 成功率
      const succeeded_rate = `${(
        (ll.total_succeeded_times / ll.total_times) *
        100
      ).toFixed(2)}%`;
      // 累计手续费
      const total_fee = ll.total_amount_gateway_fee + ll.total_profit_amount;
      // 渠道余额
      const gateway_balance = ll.total_succeeded_amount - total_fee;
      // 实际渠道余额
      const total_gateway_balance =
        ll.total_succeeded_amount - ll.total_amount_gateway_fee;
      return {
        ...ll,
        succeeded_rate,
        total_fee,
        gateway_balance,
        total_gateway_balance,
      };
    });

  const { handleGetPageSearchCache } = useSearchCache();
  const getSum = (params = {}) => {
    perms["app.gateway.daily.sum"] &&
      dispatch(
        getAppGatewayDailySum({ ...handleGetPageSearchCache(), ...params }),
      );
  };
  useEffect(() => {
    getSum();
    // eslint-disable-next-line
  }, []);

  const [searchData, setSearchData] = useState({});
  const handleSearchClick = params => {
    setSearchData(params);
    handleSearch(params);
    getSum(params);
  };

  const {
    loading: exportLoading,
    handleExport,
    handleSelectedColumns,
  } = useExportExcel({
    exportColumnsRenderMap,
    exportAction: exportAppGatewayDaily,
    handleExportData: handleList,
  });

  const [refreshVisible, setRefreshVisible] = useState(false);

  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchFormFactory
        fields={searchFields}
        handleSubmit={handleSearchClick}
        showExportBtn={true}
        handleExport={handleExport}
        exportLoading={exportLoading}
        otherBtn={
          <Button type="primary" onClick={() => setRefreshVisible(true)}>
            更新报表
          </Button>
        }
      />
      {perms["app.gateway.daily.sum"] && (
        <SumTable data={sum} labels={sumColumns} />
      )}
      <NormalTable
        allColumns={columns}
        defaultColumns={columns.map(i => i.dataIndex)}
        dataSource={handleList(list)}
        meta={meta}
        onChangePage={handleChangePage}
        onChange={handleChange}
        loading={listLoading}
        onShowSizeChange={handleChangePage}
        statisticsColumns={statisticsColumns}
        setSelectedColumns={handleSelectedColumns}
      />
      <RefreshDataModal
        visible={refreshVisible}
        setVisible={setRefreshVisible}
        refreshAction={getAppGatewayDailyRefresh}
        callback={() => {
          handleGetList();
          getSum(searchData);
        }}
      />
    </Space>
  );
};
export default AppGatewayDaily;
