import moment from "moment";
import { message } from "antd";
import { CurrencyFormat } from "@/utils/enum";
import Area from "@/utils/enum/area";

export const dateFormat = (date, formatStr = "YYYY-MM-DD HH:mm:ss") => {
  return date && moment(date)._isValid && typeof date === "string"
    ? moment(date).format(formatStr)
    : "";
};
export const priceFormat = ({ val, currency, showUnit = true }) => {
  try {
    const { unit, rate, tofix } = CurrencyFormat.find(i => i.key === currency);
    const tofixVal = (val / rate).toLocaleString("en-US", {
      maximumFractionDigits: tofix,
    });
    return showUnit ? `${unit} ${tofixVal}` : tofixVal;
  } catch (e) {
    return "";
  }
};

export const cnyPriceFormat = val => {
  if (!val) return "";
  const tofixVal = (val / 1000).toLocaleString("en-US", {
    maximumFractionDigits: 4,
  });
  return tofixVal;
};

export const rangeDateTimeFormat = (val, showTime = true) => {
  if (!val) return;
  if (showTime) return `${val[0]._d.toISOString()}~${val[1]._d.toISOString()}`;
  return `${moment(val[0]).startOf("day").toISOString()}~${moment(val[1])
    .endOf("day")
    .toISOString()}`;
};

export const rangeLimitDays = (limitDays = 0, dateStr = "") => {
  const dateArr = dateStr.split("~");
  let dt1 = new Date(dateArr[0]).getTime();
  let dt2 = new Date(dateArr[1]).getTime();
  const days = (dt2 - dt1) / (1000 * 60 * 60 * 24);
  return days <= limitDays;
};

const fieldVerifyHasVal = val => val !== undefined && val !== "";

export const objFormateToSearchFields = (obj = {}) => {
  let data = {};
  if (!obj) return data;
  Object.keys(obj).forEach(
    k => (data[k] = { type: "hidden", initialValue: obj[k] }),
  );
  return data;
};

/**
 * filter 所有 fields 的 initialValue
 * @param {*} fields
 * @returns {}
 */
export const searchFieldsFilterInitialValues = fields => {
  if (!Object.keys(fields).length) return {};
  let data = {};
  Object.keys(fields).forEach(f => {
    fields[f].initialValue && (data[f] = fields[f].initialValue);
  });
  return data;
};

/**
 * format 所有 fields for server
 * @param {*} param0
 * @returns {}
 */
export const searchFieldsFormat = ({ fields, formModel }) => {
  let params = {};
  Object.keys(fields).forEach(i => {
    const fieldsHandle = () => {
      fieldVerifyHasVal(formModel[i]) && (params[i] = formModel[i]);
    };
    const typeRangeDate = () => {
      if (fieldVerifyHasVal(formModel[i])) {
        params[i] =
          "showTime" in fields[i]
            ? rangeDateTimeFormat(formModel[i], fields[i].showTime)
            : rangeDateTimeFormat(formModel[i]);
      }
    };
    const typeDate = () => {
      fieldVerifyHasVal(formModel[i]) &&
        (params[i] = moment(formModel[i]).startOf("day").toISOString());
    };
    const typeSearchSelect = () => {
      formModel[i]?.length && (params[i] = formModel[i].join(","));
    };
    const typeSelect = () => {
      if (i === "funding_type") {
        fieldVerifyHasVal(formModel[i]) &&
          (params = { ...params, ...JSON.parse(formModel[i]) });
      } else {
        fieldsHandle();
      }
    };
    const typeObj = {
      rangeDate: typeRangeDate,
      date: typeDate,
      searchSelect: typeSearchSelect,
      select: typeSelect,
    };
    typeObj[fields[i].type] ? typeObj[fields[i].type]() : fieldsHandle();
  });
  return params;
};

/**
 * 處理 searchFields for server
 * @param {*} 頁面層定義的 searchFields
 * @returns {}
 */
export const searchFieldsInitialValuesFormat = (searchFields = {}) => {
  const formModel = searchFieldsFilterInitialValues(searchFields);
  return searchFieldsFormat({ fields: searchFields, formModel });
};

export const metaToPagin = meta => {
  return {
    pageSize: meta.per_page,
    current: meta.page,
    total: meta.total,
    pages: meta.pages,
  };
};

export const getRouterDisplayName = (path, router = []) =>
  router.find(i => i.path === path)?.displayName ||
  router.find(i => i.name === path.split("/")[1])?.displayName;

export const getRouter = (path, router = []) =>
  router.find(i => i.path === path) ||
  router.find(i => i.name === path.split("/")[1]);

export const getRouterParam = path => {
  const pathLength = path.split("/").length;
  return pathLength > 2 ? ` - ${path.split("/")[pathLength - 1]}` : "";
};

export const getColumns = () => {
  return JSON.parse(localStorage.getItem("columns") || "{}")[
    window.location.pathname
  ];
};
export const getCityArr = prov => {
  const cityItem = Object.entries(Area).filter(
    item => item[0].replace("省", "") === prov.replace("省", ""),
  );
  return prov && cityItem.length > 0 ? Object.keys(cityItem[0][1].items) : [];
};

export const copyText = (val = "") => {
  if (!val) return;
  navigator.clipboard.writeText(val);
  message.success("复制成功");
};

export const mapToValueEnum = (map, { value = "value", label = "label" }) => {
  return map.reduce((acc, curr) => {
    acc[curr[value]] = curr[label];
    return acc;
  }, {});
};

export const numberToChinese = num => {
  const units = ["", "十", "百", "千"];
  const chars = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];

  if (num === 0) return chars[0];
  let str = "";
  let digit,
    unitIndex = 0;

  while (num > 0) {
    digit = num % 10;
    if (digit === 0) {
      // 若个位数为零且字符串不为空
      if (str && !str.startsWith(chars[0])) str = chars[0] + str;
    } else {
      str = chars[digit] + units[unitIndex] + str;
    }
    num = Math.floor(num / 10);
    unitIndex++;
  }

  // 去掉"一十"的前导"一"，如123 => "一百二十三"或 10 => "十"
  if (str.startsWith("一十")) str = str.slice(1);

  return str;
};
