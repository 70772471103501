import React from "react";
import { Form } from "antd";
import { getAgents, selectAgent } from "@/store/slice/agent";
import SearchSelect from "@/components/SearchSelect";

function AgentUserSelect({ name = "" }) {
  return (
    <Form.Item
      name={name}
      label="来源使用者"
      rules={[{ required: true, message: "请选择" }]}
    >
      <SearchSelect
        action={getAgents}
        selector={selectAgent}
        searchKey="username"
        val="user_id"
        label={i => `${i.user_id} ${i.username}`}
        allowClear
      />
    </Form.Item>
  );
}

export default AgentUserSelect;
