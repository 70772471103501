import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Form, Select, InputNumber } from "antd";
import { formLayout, mode, Currency } from "@/utils/enum";
import Spin from "@/components/Spin";
import { selectAuth } from "@/store/slice/auth";
import { selectAgent, getAgents } from "@/store/slice/agent";
import SearchSelect from "@/components/SearchSelect";

const { Option } = Select;

const AddEdit = props => {
  const { user } = useSelector(selectAuth);

  const [form] = Form.useForm();
  const [currAgent, setCurrAgent] = useState({});
  const onSelect = agent => setCurrAgent(agent);

  const handleOk = () => {
    form.validateFields().then(async formModel => {
      if (!formModel) return;
      await props.onOk({
        agent_id: currAgent.id,
        agent_name: currAgent.name,
        agent_user_id: currAgent.user_id,
        agent_user_name: currAgent.username,
        balance: formModel.balance,
        currency: formModel.currency,
        userid: user.id,
      });
      form.resetFields();
    });
  };

  useEffect(() => {
    props.visible && props.mode === "edit" && form.setFieldsValue(props.data);
  });
  return (
    <Modal
      title={`${mode[props.mode]}代理帐户`}
      visible={props.visible}
      onOk={handleOk}
      onCancel={props.onCancel}
      cancelText="取消"
      okText="送出"
      confirmLoading={props.loading}
    >
      <Spin spinning={props.loading}>
        <Form {...formLayout} form={form}>
          {props.mode === "add" && (
            <>
              <Form.Item
                name="agent_id"
                label="代理ID"
                rules={[{ required: true, message: "请选择商户" }]}
              >
                <SearchSelect
                  action={getAgents}
                  selector={selectAgent}
                  searchKey="name"
                  val="user_id"
                  label={i => `${i.user_id} ${i.name}`}
                  onSelect={onSelect}
                />
              </Form.Item>
              <Form.Item name="currency" label="货币">
                <Select>
                  {Object.keys(Currency).map(i => (
                    <Option value={Number(i)} key={i}>
                      {Currency[i]}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="balance" label="余额">
                <InputNumber />
              </Form.Item>
            </>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};
export default AddEdit;
