import React from "react";
import { Typography, Form, Input, Button } from "antd";
function RealNameForm({ postPayerName }) {
  const [nameForm] = Form.useForm();

  return (
    <>
      <Typography.Title level={3} className="header">
        真实姓名补全
      </Typography.Title>

      {/* 注意 */}
      {/* <Alert
        message="本次充值需要使用 XX 姓名银行卡入款，不然无法到账"
        type="warning"
        style={{ marginBottom: "20px" }}
      /> */}

      <Form form={nameForm} onFinish={postPayerName}>
        <Form.Item
          label="真实姓名"
          name="payer_name"
          rules={[{ required: true, message: "必填" }]}
        >
          <Input placeholder="请填写真实姓名，如王小明" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            style={{ marginTop: "10px" }}
          >
            提交
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default RealNameForm;
