import React, { useState } from "react";
import { Space, Button, Modal, Switch } from "antd";
import {
  selectProduct,
  getProducts,
  getProduct,
  addProduct,
  editProduct,
  deleteProduct,
  batchProduct,
} from "@/store/slice/product";
import { SearchFormFactory } from "@/components/factory/FormFactory";
import { useList, useDetail, useUserPerms } from "@/utils/hook";
import {
  PlusOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { EditableTable } from "@/components/factory/TableFactory";
import AddEdit from "./AddEdit";
import Batch from "./Batch";
import { dateFormat } from "@/utils/format";
import { ProductCategory, IsBoolEnum } from "@/utils/enum";
import Tag from "@/components/Tag";

function Product() {
  const perms = useUserPerms();
  const searchFields = {
    id__in: { type: "string", label: "ID" },
    name__k: { type: "string", label: "商品名" },
    category: { type: "select", label: "商品类型", options: ProductCategory },
    is_active: {
      type: "select",
      label: "是否上架",
      options: IsBoolEnum,
    },
  };

  const {
    res: { list, meta },
    loading: listLoading,
    handleSearch,
    handleGetList,
    handleChangePage,
    handleChange,
    handleAdd: handleAddHook,
  } = useList(getProducts, selectProduct);

  const [addVisible, setAddVisible] = useState(false);
  const [batchVisible, setBatchVisible] = useState(false);

  const handleBatchClick = () => {
    setBatchVisible(true);
  };
  const handleBatch = async formData => {
    handleAddHook({ action: batchProduct, formData });
    setBatchVisible(false);
  };

  const handleAddClick = () => {
    setAddVisible(true);
  };
  const handleAdd = async formModel => {
    handleAddHook({ action: addProduct, ...formModel });
    setAddVisible(false);
  };
  const [detailId, setDetailId] = useState(null);
  const {
    currentRow,
    loading: detailLoading,
    handleEdit: handleEditHook,
  } = useDetail({ action: getProduct, id: detailId }, selectProduct);
  const [editVisible, setEditVisible] = useState(false);

  const handleEditClick = async id => {
    setDetailId(id);
    setEditVisible(true);
  };

  const handleEdit = async formModel => {
    const { status } = await handleEditHook({
      action: editProduct,
      id: currentRow.id,
      ...formModel,
    });
    if (status !== 200) return;
    setEditVisible(false);
    setDetailId(null);
    handleGetList({ page: meta.current });
  };

  const handleDelete = async (close, id) => {
    const { status } = await deleteProduct(id);
    close();
    if (status !== 200) return;
    handleGetList({ page: meta.current });
  };

  const handleDeleteClick = id => {
    Modal.confirm({
      title: "是否删除",
      icon: <ExclamationCircleOutlined />,
      content: `即将删除 ${id}，是否继续？`,
      okText: "确认",
      cancelText: "取消",
      onOk: close => handleDelete(close, id),
    });
  };

  const handleRowEditSubmit = async ({ id, ...params }) => {
    await handleEditHook({ action: editProduct, id, ...params });
    handleGetList({ page: meta.current });
  };

  const handleChangeIsActive = async (checked, { id, ...params }) => {
    params.is_active = checked;
    await handleEditHook({ action: editProduct, id, ...params });
    handleGetList({ page: meta.current });
  };

  const columns = [
    { title: "id", dataIndex: "id" },
    { title: "商品名称", dataIndex: "name", editable: true },
    { title: "最小金额", dataIndex: "min_amount", editable: true },
    { title: "最大金额", dataIndex: "max_amount", editable: true },
    {
      title: "商品类型",
      dataIndex: "category",
      render: val => ProductCategory[val] || "",
      width: 100,
    },
    {
      title: "是否上架",
      dataIndex: "is_active",
      dRender: val => <Tag val={val} />,
      render: (val, record) => (
        <Switch
          checked={val}
          onChange={checked => handleChangeIsActive(checked, record)}
          disabled={!perms["card.SetActive"]}
        />
      ),
    },
    { title: "创建ID", dataIndex: "create_id" },
    { title: "创建者", dataIndex: "create_name" },
    {
      title: "创建日期",
      dataIndex: "created",
      render: val => dateFormat(val),
      className: "text-nowrap",
      sorter: true,
    },
    { title: "更新者ID", dataIndex: "update_id" },
    { title: "更新者", dataIndex: "update_name" },
    {
      title: "更新日期",
      dataIndex: "updated",
      render: val => dateFormat(val),
      className: "text-nowrap",
      sorter: true,
    },
    {
      title: "动作",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      render: (_, record) => (
        <Space>
          <Button
            size="small"
            type="text"
            className="p-0"
            onClick={() => handleEditClick(record.id)}
            disabled={!perms["product.List"] || !perms["product.Update"]}
          >
            编辑
          </Button>
          <Button
            size="small"
            type="text"
            className="p-0"
            danger
            onClick={() => handleDeleteClick(record.id)}
            disabled={!perms["product.Delete"]}
          >
            删除
          </Button>
        </Space>
      ),
    },
  ];
  const defaultColumns = [
    "id",
    "name",
    "min_amount",
    "max_amount",
    "category",
    "is_active",
    "create_name",
    "created",
    "update_name",
    "updated",
    "action",
  ];
  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchFormFactory fields={searchFields} handleSubmit={handleSearch} />
      <Space className="w-100" style={{ justifyContent: "space-between" }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleAddClick}
          disabled={!perms["product.Create"]}
        >
          添加
        </Button>

        <Button
          type="primary"
          ghost
          icon={<UploadOutlined />}
          onClick={handleBatchClick}
        >
          批次汇入
        </Button>
      </Space>

      <EditableTable
        allColumns={columns}
        dataSource={list}
        loading={listLoading}
        defaultColumns={defaultColumns}
        onChangePage={handleChangePage}
        onChange={handleChange}
        onRowEditSubmit={handleRowEditSubmit}
        onShowSizeChange={handleChangePage}
        meta={meta}
        editDisabled={!perms["product.Update"]}
      />
      <AddEdit
        visible={addVisible}
        onOk={handleAdd}
        onCancel={() => setAddVisible(false)}
        loading={listLoading}
        mode="add"
      />
      <AddEdit
        visible={editVisible}
        onOk={handleEdit}
        onCancel={() => setEditVisible(false)}
        loading={detailLoading}
        data={currentRow}
        mode="edit"
      />
      <Batch
        visible={batchVisible}
        onOk={handleBatch}
        onCancel={() => setBatchVisible(false)}
        loading={listLoading}
        mode="batch"
      />
    </Space>
  );
}
export default Product;
