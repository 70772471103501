import { useEffect } from "react";
import { Modal, Form, Input, Switch, Select } from "antd";
import { formLayout, mode as Mode } from "@/utils/enum";
import Spin from "@/components/Spin";
import { CurrencyHelpTextFormItemFactory } from "@/components/factory/FormFactory";
import { ProductCategory } from "@/utils/enum";
const { Option } = Select;
const AddEdit = ({ visible, mode, data, onCancel, onOk, loading }) => {
  const [form] = Form.useForm();
  const handleOk = async () => {
    form.validateFields().then(async formModel => {
      if (!formModel) return;
      await onOk({ ...formModel });
      form.resetFields();
    });
  };

  useEffect(() => {
    visible && form.setFieldsValue(mode === "edit" ? data : {});
  }, [visible, data, form, mode]);

  return (
    <Modal
      destroyOnClose={true}
      title={`${Mode[mode]}商品`}
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      cancelText="取消"
      okText="送出"
      confirmLoading={loading}
    >
      <Spin spinning={loading}>
        <Form {...formLayout} form={form}>
          <Form.Item name="is_active" label="是否上架" valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item
            name="name"
            label="商品名称"
            rules={[{ required: true, message: "请输入商品名称" }]}
          >
            <Input />
          </Form.Item>
          <CurrencyHelpTextFormItemFactory
            name="min_amount"
            label="最小金额"
            row={{ ...data, currency: 0 }}
            defaultValKey="min_amount"
            key="min_amount"
          />
          <CurrencyHelpTextFormItemFactory
            name="max_amount"
            label="最大金额"
            row={{ ...data, currency: 0 }}
            defaultValKey="max_amount"
            key="max_amount"
          />
          <Form.Item name="category" label="商品类型">
            <Select>
              {Object.keys(ProductCategory).map(i => (
                <Option value={Number(i)} key={i}>
                  {ProductCategory[i]}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Spin>

    </Modal>
  );
};
export default AddEdit;
