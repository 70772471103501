import { Descriptions, Table } from "antd";

const SumTable = ({
  data = {},
  labels = [],
  columnSize = { xs: 2, sm: 4, md: 8 },
}) => {
  return (
    <Descriptions
      bordered
      layout="vertical"
      size="small"
      column={columnSize}
      style={{ backgroundColor: "#fff" }}
    >
      {labels.map(i => {
        return (
          <Descriptions.Item label={i.title} key={i.dataIndex}>
            {i.render ? i.render(data[i.dataIndex] || 0) : data[i.dataIndex]}
          </Descriptions.Item>
        );
      })}
    </Descriptions>
  );
};

export const SumTableList = ({ data = [], labels = [], ...props }) => {
  return (
    <Table
      size="small"
      rowKey="id"
      scroll={{ x: "auto" }}
      pagination={false}
      columns={labels}
      dataSource={data}
      {...props}
    />
  );
};

export default SumTable;
