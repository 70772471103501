import { useEffect } from "react";
import { Modal, Form, InputNumber, Input, DatePicker } from "antd";
import moment from "moment";
import zhCN from "antd/es/date-picker/locale/zh_CN";
import { formLayout, mode } from "@/utils/enum";
import Spin from "@/components/Spin";

const AddEdit = props => {
  const [form] = Form.useForm();
  const handleOk = () => {
    form.validateFields().then(async formModel => {
      if (!formModel) return;
      let params = {
        ...formModel,
        ...(formModel?.register_at && {
          register_at: formModel.register_at._d.toISOString(),
        }),
      };
      await props.onOk(params);
      form.resetFields();
    });
  };
  useEffect(() => {
    if (props.visible) {
      if (props.mode === "edit" && Object.keys(props.data).length) {
        const data = {
          ...props.data,
          ...(props.data.register_at && {
            register_at: moment(props.data.register_at),
          }),
        };
        form.setFieldsValue(data);
      }
    }
  });

  return (
    <Modal
      title={`${mode[props.mode]}商户用户`}
      visible={props.visible}
      onOk={handleOk}
      onCancel={props.onCancel}
      cancelText="取消"
      okText="送出"
      confirmLoading={props.loading}
    >
      <Spin spinning={props.loading}>
        <Form {...formLayout} form={form}>
          <Form.Item name="name" label="真实名称">
            <Input />
          </Form.Item>
          <Form.Item name="vip" label="等级">
            <InputNumber />
          </Form.Item>
          <Form.Item name="note" label="备注">
            <Input />
          </Form.Item>
          <Form.Item name="phone" label="手机号">
            <Input />
          </Form.Item>
          <Form.Item name="rating" label="rating">
            <InputNumber />
          </Form.Item>
          <Form.Item name="register_at" label="注册时间">
            <DatePicker
              locale={zhCN}
              showTime={true}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item name="register_ip" label="注册IP">
            <Input />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};
export default AddEdit;
