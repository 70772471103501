import React, { useEffect, useRef } from "react";
import RedirectSpin from "./RedirectSpin";

function FormPost({ formData = {} }) {
  const form = useRef(null);
  useEffect(() => {
    form?.current.submit();
  }, []);
  return (
    <>
      <form
        ref={form}
        id="form"
        name="form"
        method="post"
        action={formData.post_url}
      >
        {Object.keys(formData).map(k => (
          <input key={k} type="hidden" name={k} value={formData[k]} />
        ))}
      </form>
      <RedirectSpin />
    </>
  );
}

export default FormPost;
