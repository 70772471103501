import React, { useMemo } from "react";
import { Typography, Alert, Statistic, Space, message } from "antd";
import { priceFormat } from "@/utils/format";
import { CurrencyKeys } from "@/utils/enum";

function CardForm({ order }) {
  const state = useMemo(() => {
    const { amount, currency, order_no, expired_at, payer_name, credential } =
      order;
    return {
      order_no,
      payer_name,
      amount: priceFormat({ val: amount, currency: CurrencyKeys[currency] }),
      expired_at: expired_at
        ? new Date(expired_at).toLocaleDateString("zh-TW")
        : "", // 请求时间

      name: credential?.data?.name || "",
      account: credential?.data?.account || "", // 收款卡號
      open_bank: credential?.data?.open_bank || "", // 收款银行
      sub_bank: credential?.data?.sub_bank || "", // 银行分行
    };
  }, [order]);

  function copyText(val = "") {
    if (!val) return;
    navigator.clipboard.writeText(val);
    message.success("复制成功");
  }

  return (
    <>
      <Typography.Title level={3} className="header">
        订单信息
      </Typography.Title>

      <Space style={{ width: "100%", justifyContent: "center" }}>
        订单关闭倒计时:
        <Statistic.Countdown
          value={order.expired_at ? +new Date(order.expired_at) : 0}
          valueStyle={{ fontSize: "18px", color: "red" }}
        />
      </Space>

      {/* 注意 */}
      <Alert
        message={
          <Typography.Text strong type="danger">
            注意：
          </Typography.Text>
        }
        description={
          <>
            请用注册实名
            {state.payer_name ? (
              <span style={{ color: "red" }}>{state.payer_name}</span>
            ) : (
              ""
            )}
            的银行卡进行支付，非实名银行卡支付不到账，请联系客服原路退回！
          </>
        }
        type="warning"
        style={{ marginBottom: "20px" }}
      />

      <div className="list">
        <div className="list--item">
          <div className="list--item--label">金额</div>
          <div className="list--item--value">{state.amount || 0}</div>
          <button
            className="list--item--extra"
            onClick={() => copyText(state.amount)}
          >
            复制
          </button>
        </div>
        <div className="list--item">
          <div className="list--item--label">订单号</div>
          <div className="list--item--value">{state.order_no || ""}</div>
        </div>
        <div className="list--item">
          <div className="list--item--label">收款姓名</div>
          <div className="list--item--value">{state.name || ""}</div>
          <button
            className="list--item--extra"
            onClick={() => copyText(state.name)}
          >
            复制
          </button>
        </div>
        <div className="list--item">
          <div className="list--item--label">收款卡号</div>
          <div className="list--item--value">{state.account}</div>
          <button
            className="list--item--extra"
            onClick={() => copyText(state.account)}
          >
            复制
          </button>
        </div>
        <div className="list--item">
          <div className="list--item--label">收款银行</div>
          <div className="list--item--value">{state.open_bank}</div>
          <button
            className="list--item--extra"
            onClick={() => copyText(state.open_bank)}
          >
            复制
          </button>
        </div>
        <div className="list--item">
          <div className="list--item--label">银行分行</div>
          <div className="list--item--value">{state.sub_bank}</div>
          <button
            className="list--item--extra"
            onClick={() => copyText(state.sub_bank)}
          >
            复制
          </button>
        </div>
        <div className="list--item">
          <div className="list--item--label">请求时间</div>
          <div className="list--item--value">{state.expired_at}</div>
        </div>
      </div>
      <div className="note">
        <p>温馨提示:</p>
        <ol>
          <li>请按实际订单金额付款,修改金额造成的损失自行承担!</li>
          <li>请勿保存银行卡信息,重复支付导致资金损失自行承担!</li>
          <li>订单超时(20分钟之外)付款导致资金损失自行承担</li>
        </ol>
      </div>
    </>
  );
}

export default CardForm;
