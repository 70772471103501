import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Form, Input, InputNumber } from "antd";
import { formLayout, mode as Mode } from "@/utils/enum";
import Spin from "@/components/Spin";
import { selectConfig } from "@/store/slice/config";

const AddEdit = ({
  visible = false,
  mode = "add",
  data = {},
  loading = false,
  onOk = () => {},
  onCancel = () => {},
}) => {
  const { payTypeList } = useSelector(selectConfig);
  const [form] = Form.useForm();
  const handleOk = async () => {
    const formModel = await form.validateFields();
    Object.keys(formModel).forEach(k => formModel[k] || (formModel[k] = ""));
    await onOk(formModel);
  };
  useEffect(() => {
    visible && mode === "edit" && form.setFieldsValue(data);
  }, [visible, mode, data, form]);

  return (
    <Modal
      title={`${Mode[mode]}商户账户`}
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      cancelText="取消"
      okText="送出"
      confirmLoading={loading}
      destroyOnClose
    >
      <Spin spinning={loading}>
        <Form {...formLayout} form={form} preserve={false}>
          <Form.Item
            name="name"
            label="群组名称"
            rules={[{ required: true, message: "请输入群组名称" }]}
          >
            <Input />
          </Form.Item>
          {payTypeList.map(item=>(
            <Form.Item key={item.key} name={`${item.value}_recharge_rate`} label={`${item.label}费率`}>
            <InputNumber stringMode step="0.01" />
          </Form.Item>
          ))}
          {/* <Form.Item name="wx_recharge_rate" label="微信费率">
            <InputNumber stringMode step="0.01" />
          </Form.Item>
          <Form.Item name="alipay_recharge_rate" label="支付宝费率">
            <InputNumber stringMode step="0.01" />
          </Form.Item>
          <Form.Item name="ebank_recharge_rate" label="网银费率">
            <InputNumber stringMode step="0.01" />
          </Form.Item>
          <Form.Item name="bank_recharge_rate" label="银行卡费率">
            <InputNumber stringMode step="0.01" />
          </Form.Item>
          <Form.Item name="crypto_recharge_rate" label="虚拟货币费率">
            <InputNumber stringMode step="0.01" />
          </Form.Item>
          <Form.Item name="qq_recharge_rate" label="qq费率">
            <InputNumber stringMode step="0.01" />
          </Form.Item>
          <Form.Item name="ecny_recharge_rate" label="数字人民币费率">
            <InputNumber stringMode step="0.01" />
          </Form.Item>
          <Form.Item name="union_recharge_rate" label="云闪付费率">
            <InputNumber stringMode step="0.01" />
          </Form.Item>
          <Form.Item name="nopay_recharge_rate" label="NoPay费率">
            <InputNumber stringMode step="0.01" />
          </Form.Item>
          <Form.Item name="union_pay_recharge_rate" label="银联存款费率">
            <InputNumber stringMode step="0.01" />
          </Form.Item>
          <Form.Item name="douyin_recharge_rate" label="抖音存款费率">
            <InputNumber stringMode step="0.01" />
          </Form.Item> */}
        </Form>
      </Spin>
    </Modal>
  );
};
export default AddEdit;
