import React, { useState } from "react";
import { Typography, Button, Input, Modal, Row, Col, message } from "antd";
import QRCode from "qrcode-react";
import { verifyOpt } from "@/store/slice/auth";
import AppIconUrl from "@/assets/GoogleAuthenticatorAppIcon.webp";

function OtpModal({
  visible = false,
  otpAuthUrl = "",
  secret = "",
  handleClose = () => {},
}) {
  const [verifyOtp, setVerifyOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const handleOtpVerify = async () => {
    setLoading(true);
    const { status, data } = await verifyOpt({ otp: verifyOtp });
    setLoading(false);
    if (status !== 200) return;
    data ? message.success("验证成功") : message.error("验证失败");
  };

  const StepTitle = ({ step, title, subTitle }) => (
    <div className="step-title">
      <div className="step-title-step">{step}</div>
      <span className="step-title-text">{title}</span>
      <span className="step-title-subtext">{subTitle}</span>
    </div>
  );

  return (
    <Modal
      visible={visible}
      width="80%"
      centered
      closable={false}
      footer={
        <Button type="primary" onClick={handleClose}>
          进入后台
        </Button>
      }
    >
      <div className="otp-modal">
        <Row gutter={16} justify="center">
          <Col md={24} xl={7} style={{ marginBottom: "10px" }}>
            <div className="step-wrap">
              <StepTitle
                step="1"
                title="安裝 Google Authenticator"
                subTitle="在行動裝置上下載並安裝"
              />
              <div className="step1-content">
                <img src={AppIconUrl} alt="AppIconUrl" />
              </div>
            </div>
          </Col>
          <Col md={24} xl={10}>
            <div className="step-wrap">
              <StepTitle
                step="2"
                title="新增 Google Authenticator 帐号"
                subTitle="掃描 QR code 或是 輸入 Setup key"
              />
              <Row gutter={8} justify="center" className="step2-content">
                <Col style={{ textAlign: "center" }}>
                  <Typography.Title level={4}>QR code</Typography.Title>
                  <QRCode value={otpAuthUrl} size={150} />
                </Col>
                <Col style={{ textAlign: "center" }}>
                  <Typography.Title level={4}>Setup key</Typography.Title>
                  <div className="setup-key">{secret}</div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={24} xl={7}>
            <div className="step-wrap">
              <StepTitle
                step="3"
                title="测试 Google Authenticator 验证码"
                subTitle="完成设定后，可输入产生的六位验证码并按下确认"
              />
              <div className="step3-content">
                <Input
                  placeholder="輸入验证码"
                  val={verifyOtp}
                  onChange={e => setVerifyOtp(e.target.value)}
                  style={{ maxWidth: "200px" }}
                />
                <Button
                  type="primary"
                  style={{ marginTop: "5px" }}
                  onClick={handleOtpVerify}
                  loading={loading}
                >
                  测试
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}

export default OtpModal;
