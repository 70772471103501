import { useDispatch, useSelector } from "react-redux";
import {
  deleteSubTransfer,
  getTransfersSub,
  splitTransfer,
} from "@/store/slice/transfer";
import { Button, Form, List, Modal, Space, Typography, message } from "antd";
import { numberToChinese, priceFormat } from "@/utils/format";
import { useCallback, useEffect, useState } from "react";
import Spin from "@/components/Spin";
import { CurrencyHelpTextFormItemFactory } from "@/components/factory/FormFactory";
import { isSuccessLang, transferStatus } from "@/utils/enum";
import Tag from "@/components/Tag";

const InputSubAmount = ({
  visible,
  data,
  onCancel,
  onOk,
  loading,
  dispatch,
  refetchPageData,
}) => {
  const [form] = Form.useForm();
  const handleOk = async () => {
    form.validateFields().then(async formModel => {
      const { amount } = formModel;
      if (!formModel) return;
      await onOk(amount);
      form.resetFields();
    });
  };

  return (
    <Modal
      title="填入拆出子单金额"
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      cancelText="取消"
      okText="送出"
      confirmLoading={loading}
      destroyOnClose={true}
    >
      <Spin spinning={loading}>
        <Form form={form}>
          <Form.Item label="ID">{data.id}</Form.Item>

          <CurrencyHelpTextFormItemFactory
            name="amount"
            label="金額"
            row={data}
            rules={[{ required: true, message: "请输入金額" }]}
          />
        </Form>
      </Spin>
    </Modal>
  );
};

const SplitTransferView = ({
  list = [],
  record = {},
  visible,
  onCancel,
  loading,
  handleGetList = () => {},
}) => {
  const dispatch = useDispatch();
  const { Text } = Typography;
  const [inputSubAmountVisible, setInputSubAmountVisible] = useState(false);
  const [inputSubAmountLoading, setInputSubAmountLoading] = useState(false);
  const { subTransfer } = useSelector(state => state.transfer);

  // 加载

  const data = list.find(i => i.id === record.id) || {};
  useEffect(() => {
    dispatch(getTransfersSub(data.id));
  }, [data.id, dispatch]);
  const handleInputSubAmount = async amount => {
    setInputSubAmountLoading(true);

    const { status } = await splitTransfer({
      id: data.id,
      amount: Number(amount),
      paid_id: data.paid_id,
    });
    if (status !== 200) return;
    setInputSubAmountVisible(false);
    await handleGetList();
    await dispatch(getTransfersSub(data.id));
    setInputSubAmountLoading(false);

    message.success("已拆出子订单!");
  };
  const order_amount = useCallback(() => {
    return priceFormat({
      val: data.amount - data.sub_amount,
      currency: data.currency,
    });
  }, [data.amount, data.currency, data.sub_amount]);

  return (
    <Modal
      width="900px"
      title="拆单"
      visible={visible}
      onOk={onCancel}
      onCancel={onCancel}
      cancelText={null}
      okText="完成"
      confirmLoading={loading}
      destroyOnClose={true}
    >
      <InputSubAmount
        refetchPageData={handleGetList}
        dispatch={dispatch}
        visible={inputSubAmountVisible}
        data={data}
        loading={inputSubAmountLoading}
        onCancel={() => setInputSubAmountVisible(false)}
        onOk={async amount => {
          handleInputSubAmount(amount);
        }}
      />
      <List
        loading={inputSubAmountLoading}
        header={
          <Space
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
              gap: "10px",
              backgroundColor: "#E9E9E9",
              padding: "5px 10px",
              width: "100%",
            }}
          >
            <Text>订单ID: {data.id}</Text>
            <Text>是否成功</Text>
            <Text>成功时间</Text>
            <Text>订单状态</Text>
            <Text>剩余可拆分金额: {order_amount()}元</Text>
          </Space>
        }
        footer={
          <Space
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "5px",
              height: "auto",
              width: "100%",
              border: "1px solid #E9E9E9",
            }}
          >
            <Text
              style={{
                color: "#0295FF",
                cursor: "pointer",
              }}
              onClick={async () => {
                setInputSubAmountVisible(true);
              }}
            >
              添加新的拆分订单
            </Text>
          </Space>
        }
        dataSource={subTransfer[data.id]}
        renderItem={(item, index) =>
          item.m_transfer_id === data.id && (
            <List.Item
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                gap: "10px",
                width: "100%",
              }}
            >
              <Text>
                拆分订单{numberToChinese(index + 1)}: {item.id}
              </Text>
              <Text>
                {[10, 13, 14, 15, 16, 17].includes(item.status) ? (
                  <Tag val={item.succeeded}>
                    {isSuccessLang(item.succeeded)}
                  </Tag>
                ) : null}
              </Text>
              <Text>{item.succeeded_at}</Text>
              <Text>{transferStatus[item.status]}</Text>
              <Text>
                出款金额:{" "}
                {priceFormat({ val: item.amount, currency: item.currency })}
              </Text>
              <Button
                type="primary"
                onClick={async () => {
                  setInputSubAmountLoading(true);
                  await deleteSubTransfer(item.id);
                  await dispatch(getTransfersSub(data.id));
                  await setInputSubAmountLoading(false);
                  handleGetList();
                }}
              >
                退回
              </Button>
            </List.Item>
          )
        }
      />
    </Modal>
  );
};

export default SplitTransferView;
