import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Select,
  Input,
  Space,
  Spin,
  Radio,
  message,
} from "antd";
import { formLayout, Currency } from "@/utils/enum";
import { postAcctSwap } from "@/store/slice/tool";
import { useUserPerms } from "@/utils/hook";
import { CurrencyHelpTextFormItemFactory } from "@/components/factory/FormFactory";
import AppUserSelect from "./AppUserSelect";
import AgentUserSelect from "./AgentUserSelect";
// const UserType = { 1: "商戶", 2: "代理" };

function AcctSwap() {
  const perms = useUserPerms();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const onValuesChange = changeObj => {
    if ("source_user_type" in changeObj)
      form.setFieldsValue({ source_user_id: null });
    if ("target_user_type" in changeObj)
      form.setFieldsValue({ target_user_id: null });
  };
  const [currSourceAppUser, setCurrSourceAppUser] = useState({});
  const onSelectSourceAppUser = obj => setCurrSourceAppUser(obj);
  const [currTargetAppUser, setCurrTargetAppUser] = useState({});
  const onSelectTargetAppUser = obj => setCurrTargetAppUser(obj);

  const handleOnCancel = async () => {
    await form.resetFields();
    setVisible(false);
  };

  const handleAcctSwapOk = async () => {
    setLoading(true);
    const formData = await form.validateFields();
    let params = {
      ...formData,
      ...(formData.source_user_type === 1 && {
        source_user_id: currSourceAppUser.developer_id,
      }),
      ...(formData.target_user_type === 1 && {
        target_user_id: currTargetAppUser.developer_id,
      }),
    };
    const { status } = await postAcctSwap(params);
    setLoading(false);
    handleOnCancel();
    status === 200 && message.success("新增成功");
  };

  const initForm = async () => {
    await form.setFieldsValue({
      source_user_type: 1,
      target_user_type: 1,
      currency: 0,
    });
  };
  useEffect(() => {
    visible && initForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <>
      <Button
        type="primary"
        onClick={() => setVisible(true)}
        disabled={!perms["tool.AcctSwap"]}
      >
        帳戶划转
      </Button>
      <Modal
        title={"划转设定"}
        visible={visible}
        onOk={handleAcctSwapOk}
        onCancel={handleOnCancel}
        cancelText="取消"
        okText="送出"
      >
        <Space direction="vertical" className="w-100">
          <Spin spinning={loading}>
            <Form form={form} onValuesChange={onValuesChange} {...formLayout}>
              {/* 来源使用者 */}
              <Form.Item
                name="source_user_type"
                label="来源使用者类型"
                rules={[{ required: true, message: "请选择" }]}
              >
                <Radio.Group size="small">
                  <Radio.Button value={1}>商戶</Radio.Button>
                  <Radio.Button value={2}>代理</Radio.Button>
                </Radio.Group>
              </Form.Item>
              {/* <Form.Item
              name="source_user_id"
              label="来源使用者"
              rules={[{ required: true, message: "请选择" }]}
            >
              <SearchSelect
                action={getUsers}
                selector={selectUser}
                searchKey="name"
                val="id"
                label={i => `${i.id} ${i.name}`}
                allowClear
              />
            </Form.Item> */}
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                  getFieldValue("source_user_type") === 1 ? (
                    <AppUserSelect
                      name="source_user_id"
                      onSelect={onSelectSourceAppUser} // 因為 develop_id 不是唯一值.....
                    />
                  ) : (
                    <AgentUserSelect name="source_user_id" />
                  )
                }
              </Form.Item>

              {/* 目标使用者 */}
              <Form.Item
                name="target_user_type"
                label="目标使用者类型"
                rules={[{ required: true, message: "请选择" }]}
              >
                <Radio.Group size="small">
                  <Radio.Button value={1}>商戶</Radio.Button>
                  <Radio.Button value={2}>代理</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                  getFieldValue("target_user_type") === 1 ? (
                    <AppUserSelect
                      name="target_user_id"
                      onSelect={onSelectTargetAppUser} // 因為 develop_id 不是唯一值.....
                    />
                  ) : (
                    <AgentUserSelect name="target_user_id" />
                  )
                }
              </Form.Item>

              {/* <Form.Item
              name="target_user_id"
              label="目标使用者"
              rules={[{ required: true, message: "请选择" }]}
            >
              <SearchSelect
                action={getUsers}
                selector={selectUser}
                searchKey="name"
                val="id"
                label={i => `${i.id} ${i.name}`}
                allowClear
              />
            </Form.Item> */}

              <CurrencyHelpTextFormItemFactory
                name="amount"
                label="输入金额"
                row={{ currency: 0 }}
                defaultValKey="amount"
                key="amount"
                rules={[{ required: true, message: "请输入金额" }]}
              />
              <Form.Item
                name="currency"
                label="货币"
                rules={[{ required: true, message: "请选择货币" }]}
              >
                <Select>
                  {Object.keys(Currency).map(i => (
                    <Select.Option
                      value={Number(i)}
                      key={i}
                      disabled={Currency[i] !== "RMB"}
                    >
                      {Currency[i]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="note" label="备注">
                <Input />
              </Form.Item>
            </Form>
          </Spin>
        </Space>
      </Modal>
    </>
  );
}

export default AcctSwap;
