import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "@/utils/request";
import { metaToPagin } from "@/utils/format";

export const getTransfers = createAsyncThunk(
  "tranfser/getList",
  async (params = {}) => {
    if (params.sub_status === "0") {
      delete params.sub_status;
      params.m_transfer_id__gt = 0;
    }
    if (params.status === 7) {
      params.m_transfer_id = 0;
    }
    const res = await request({
      url: "/api/transfers",
      method: "get",
      params,
    });
    return res;
  },
);
export const getTransfersSum = createAsyncThunk(
  "tranfser/getListSum",
  async (params = {}) => {
    const res = await request({
      url: "/api/transfers/sum",
      method: "get",
      params,
    });
    return res;
  },
);
export const getTransfers2Total = createAsyncThunk(
  "tranfser/getStatus2List",
  async () => {
    const res = await request({
      url: "/api/transfers",
      method: "get",
      params: { status: 2 },
    });
    return res;
  },
);
export const getTransfersGateways = createAsyncThunk(
  "tranfser/getGatewayList",
  async ({ id, ...params }) => {
    const res = await request({
      url: `/api/transfers/${id}/gateways`,
      method: "get",
      params,
    });
    return res;
  },
);
export const getTransfersOfflineGateways = createAsyncThunk(
  "tranfser/getOfflineGatewayList",
  async ({ id }) => {
    const res = await request({
      url: `/api/transfers/${id}/offline/gateways`,
      method: "get",
    });
    return res;
  },
);
export const queryTransfer = async id => {
  const res = await request({
    url: `/api/transfers/${id}/query`,
    method: "post",
    data: {},
  });
  return res;
};
export const claimTransfer = async ({ id, formModel }) => {
  const res = await request({
    url: `/api/transfers/${id}/paid/claim`,
    method: "post",
    data: { id, ...formModel },
  });
  return res;
};
export const cancelClaimTransfer = async ({ id }) => {
  const res = await request({
    url: `/api/transfers/${id}/cancel/claim`,
    method: "post",
    data: { id },
  });
  return res;
};

export const approveTransfer = async ({ id, formModel }) => {
  const res = await request({
    url: `/api/transfers/${id}/approve`,
    method: "post",
    data: { id, ...formModel },
  });
  return res;
};

// 拒絕
export const denyTransfer = async ({ id, formModel }) => {
  const res = await request({
    url: `/api/transfers/${id}/deny`,
    method: "post",
    data: formModel,
  });
  return res;
};
export const paidTransfer = async ({ id, formModel }) => {
  const res = await request({
    url: `/api/transfers/${id}/paid`,
    method: "post",
    data: { id, ...formModel },
  });
  return res;
};

// 成功
export const succeededTransfer = async ({ id, formModel }) => {
  const res = await request({
    url: `/api/transfers/${id}/succeeded`,
    method: "post",
    data: { id, ...formModel },
  });
  return res;
};

// 失敗
export const failedTransfer = async ({ id, formModel }) => {
  const res = await request({
    url: `/api/transfers/${id}/failed`,
    method: "post",
    data: { id, ...formModel },
  });
  return res;
};

// 通知
export const notifyTransfer = async ({ id }) => {
  const res = await request({
    url: `/api/transfers/${id}/notify`,
    method: "post",
    data: {},
  });
  return res;
};

// 取消
export const cancelTransfer = async ({ id }) => {
  const res = await request({
    url: `/api/transfers/${id}/paid/cancel`,
    method: "post",
    data: {},
  });
  return res;
};

// 收回
export const recycleTransfer = async id => {
  const res = await request({
    url: `/api/transfers/${id}/recycle`,
    method: "post",
    data: {},
  });
  return res;
};

// 匯出 excel
export const exportTransfers = async (params = {}) => {
  const res = await request({
    url: `/api/transfers/export`,
    method: "get",
    params: { per_page: 9999, ...params },
  });
  return res;
};

export const repaidTransfer = async ({ id, formModel }) => {
  const res = await request({
    url: `/api/transfers/${id}/repaid`,
    method: "post",
    data: { id, ...formModel },
  });
  return res;
};

// 拆單請求
export const splitTransfer = async ({ id, amount, paid_id }) => {
  const res = await request({
    url: `/api/transfers/${id}/sub_transfer`,
    method: "post",
    data: { paid_id, amount },
  });
  return res;
};

// 删除子单
export const deleteSubTransfer = async id => {
  const res = await request({
    url: `/api/transfers/${id}/sub_transfer`,
    method: "delete",
  });
  return res;
};

// 解鎖子單使用狀態
export const unlockSubTransfer = async id => {
  const res = await request({
    url: `/api/transfers/${id}/unlock/sub_transfer`,
    method: "post",
  });
  return res;
};

// 獲取子單資訊
export const getTransfersSub = createAsyncThunk(
  "tranfser/getSubList",
  async id => {
    const res = await request({
      url: `/api/transfers?m_transfer_id=${
        typeof id === "object" ? id.id : id
      }`,
      method: "get",
    });
    return { ...res, id };
  },
);

// 提早結算
export const earlySettlement = async ({ id, amount_paid, paid_id }) => {
  const res = await request({
    url: `/api/transfers/${id}/succeeded`,
    method: "post",
    data: { paid_id, amount_paid: 0 },
  });
  return res;
};
export const slice = createSlice({
  name: "transfer",
  initialState: {
    list: [],
    meta: {},
    currentRow: {},
    gateways: [],
    offlineGateways: [],
    status2Total: 0,
    sum: {},
    subTransfer: {},
  },
  extraReducers: {
    [getTransfers.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.list = data.data;
      state.meta = metaToPagin(data.meta);
    },
    [getTransfersSum.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.sum = data;
    },
    [getTransfers2Total.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.status2Total = data.meta.total;
    },
    [getTransfersGateways.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.gateways = data;
    },
    [getTransfersOfflineGateways.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.offlineGateways = data;
    },
    [getTransfersSub.fulfilled]: (state, action) => {
      const { status, data, id } = action.payload;

      if (status !== 200) return;
      state.subTransfer[id] = data?.data;
    },
  },
});
export const selectTransfer = state => state.transfer;
export default slice.reducer;
