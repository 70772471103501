import { dateFormat, priceFormat } from "@/utils/format";
import { Currency } from "@/utils/enum";

const moneyRender = () => {
  return {
    render: (val, record) =>
      priceFormat({ val: val || 0, currency: record.currency }),
    className: "text-nowrap",
    sorter: true,
  };
};

export const columns = [
  // { title: "ID", dataIndex: "id", sorter: true },
  { title: "渠道ID", dataIndex: "gateway_id", sorter: true },
  { title: "渠道名称", dataIndex: "gateway_name", sorter: true },
  { title: "商户ID", dataIndex: "app_id", sorter: true },
  { title: "商户名称", dataIndex: "app_name", sorter: true },
  {
    title: "报表时间",
    dataIndex: "created",
    className: "text-nowrap",
    render: val => dateFormat(val, "YYYY-MM-DD"),
    sorter: true,
  },
  {
    title: "货币类型",
    dataIndex: "currency",
    render: val => Currency[val] || "",
    className: "text-nowrap",
  },
  {
    title: "累计订单次数",
    dataIndex: "total_times",
    sorter: true,
  },
  {
    title: "累计订单成功次数",
    dataIndex: "total_succeeded_times",
    sorter: true,
  },
  {
    title: "累计金额",
    dataIndex: "total_amount",
    ...moneyRender(),
  },
  {
    title: "累计成功金额",
    dataIndex: "total_succeeded_amount",
    ...moneyRender(),
  },
  {
    title: "累计手续费",
    dataIndex: "total_fee",
    ...moneyRender(),
  },
  {
    title: "实际手续费",
    dataIndex: "total_amount_gateway_fee",
    ...moneyRender(),
  },
];

export const sumColumns = [
  {
    title: "累计订单次数",
    dataIndex: "total_times",
  },
  {
    title: "累计订单成功次数",
    dataIndex: "total_succeeded_times",
  },
  {
    title: "累计成功金额",
    dataIndex: "total_succeeded_amount",
    render: val => priceFormat({ val, currency: 0 }),
  },
  {
    title: "累计实际手续费",
    dataIndex: "total_amount_gateway_fee",
    render: val => priceFormat({ val, currency: 0 }),
  },
];

export const statisticsColumns = columns.filter(
  i => ["total_times", "total_succeeded_times"].indexOf(i.dataIndex) !== -1,
);

const excelPriceFormat = (val, currency) =>
  priceFormat({ val, currency, showUnit: false });

export const exportColumnsRenderMap = {
  created: val => dateFormat(val, "YYYY-MM-DD"),
  currency: val => Currency[val] || "",
  total_amount: (val, record) => excelPriceFormat(val, record.currency),
  total_succeeded_amount: (val, record) =>
    excelPriceFormat(val, record.currency),
  total_fee: (val, record) => excelPriceFormat(val, record.currency),
  total_amount_gateway_fee: (val, record) =>
    excelPriceFormat(val, record.currency),
};
