import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Button, Form, Select, Spin, message } from "antd";
import { selectApp, getApps } from "@/store/slice/app";
import { selectGateway, getGateways } from "@/store/slice/gateway";
import { selectUser, getUsers } from "@/store/slice/user";
import { selectConfig } from "@/store/slice/config";
import { postPickGateway } from "@/store/slice/tool";
import { useUserPerms } from "@/utils/hook";
import { formLayout, Currency } from "@/utils/enum";
import SearchSelect from "@/components/SearchSelect";
import { CurrencyHelpTextFormItemFactory } from "@/components/factory/FormFactory";
import JsonModal from "@/components/JsonModal";

function PickGateway() {
  const perms = useUserPerms();
  const { payTypeList } = useSelector(selectConfig);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [jsonVisible, setJsonVisible] = useState(false);
  const [form] = Form.useForm();
  const handleOnCancel = async () => {
    await form.resetFields();
    setVisible(false);
  };

  const [currentRow, setCurrentRow] = useState(null);
  const handleOk = async () => {
    setLoading(true);
    const formData = await form.validateFields();
    const { status, data } = await postPickGateway(formData);
    setLoading(false);
    if (status !== 200) return;
    handleOnCancel();
    message.success("新增成功");
    console.log("data", data);
    setCurrentRow(data);
    setJsonVisible(true);
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => setVisible(true)}
        disabled={!perms["tool.PickGateway"]}
      >
        抽选渠道
      </Button>
      <Modal
        title={"抽选渠道设定"}
        visible={visible}
        onOk={handleOk}
        onCancel={handleOnCancel}
        cancelText="取消"
        okText="送出"
      >
        <Spin spinning={loading}>
          <Form form={form} {...formLayout}>
            <Form.Item
              name="app_id"
              label="商户ID"
              rules={[{ required: true, message: "请选择商户" }]}
            >
              <SearchSelect
                action={getApps}
                selector={selectApp}
                searchKey="name"
                val="id"
                label={i => `${i.id} ${i.name}`}
              />
            </Form.Item>
            <Form.Item
              name="pay_type"
              label="支付类别"
              rules={[{ required: true, message: "请选择" }]}
            >
              <Select>
                {payTypeList.map(item => (
                  <Select.Option value={Number(item.key)} key={item.key}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="currency"
              label="货币"
              rules={[{ required: true, message: "请选择" }]}
            >
              <Select>
                {Object.keys(Currency).map(i => (
                  <Select.Option value={Number(i)} key={i}>
                    {Currency[i]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <CurrencyHelpTextFormItemFactory
              name="amount"
              label="金额"
              defaultValKey="amount"
            />
            <Form.Item name="gateway_name" label="渠道名称">
              <SearchSelect
                action={getGateways}
                selector={selectGateway}
                searchKey="name"
                val="name"
                label={i => `${i.id} ${i.name}`}
              />
            </Form.Item>
            <Form.Item name="agent_id" label="代理使用者ID">
              <SearchSelect
                action={getUsers}
                selector={selectUser}
                searchKey="username"
                val="id"
                params={{ is_agent: 1 }}
                label={i => `${i.id} ${i.username}`}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
      <JsonModal
        visible={jsonVisible}
        data={currentRow}
        onCancel={() => setJsonVisible(false)}
      />
    </>
  );
}

export default PickGateway;
