import { useEffect } from "react";
import { Modal, Form, Row, Col } from "antd";
import { formLayout } from "@/utils/enum";
import Spin from "@/components/Spin";
import { CurrencyHelpTextFormItemFactory } from "@/components/factory/FormFactory";
const Edit = ({ visible, mode, data, onCancel, onOk, loading }) => {
  const [form] = Form.useForm();
  const handleOk = async () => {
    const formModel = await form.validateFields();
    await onOk({ ...formModel });
    form.resetFields();
  };
  useEffect(() => {
    visible &&
      mode === "succeeded" &&
      form.setFieldsValue({ amount_paid: data.amount });
  }, [visible, mode, form, data.amount]);

  return (
    <Modal
      title="是否出款成功"
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      cancelText="取消"
      okText="送出"
      confirmLoading={loading}
      destroyOnClose={true}
    >
      <Spin spinning={loading}>
        <Row style={{ marginBottom: "15px" }}>
          <Col offset={3}>即将出款成功 ID{data.id}，是否继续？</Col>
        </Row>
        <Form {...formLayout} form={form}>
          {mode === "succeeded" && (
            <CurrencyHelpTextFormItemFactory
              name="amount_paid"
              label="实际付款金额"
              row={data}
              defaultValKey="amount"
            />
          )}
        </Form>
      </Spin>
    </Modal>
  );
};
export default Edit;
