import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Form, Input, Switch, InputNumber, Radio, Checkbox } from "antd";
import moment from "moment";
import { formLayout, mode as Mode } from "@/utils/enum";
// import { selectAuth } from "@/store/slice/auth";
import { selectUser, getUsers } from "@/store/slice/user";
import {
  selectAgent,
  getAgentsSelect,
  apiAgentList,
  apiAgent,
} from "@/store/slice/agent";
import { getConfigAgent, getConfigAgentClass } from "@/store/slice/config";
import { CurrencyHelpTextFormItemFactory } from "@/components/factory/FormFactory";
import SearchSelect from "@/components/SearchSelect";
import Spin from "@/components/Spin";
import RechargeRateFormList from "@/components/RechargeRateFormList";

const configAgentType = {
  0: { api: getConfigAgent, title: "大额代理" },
  1: { api: getConfigAgentClass, title: "小额代理" },
  2: { api: getConfigAgent, title: "大额代理" },
};

const AddEdit = ({
  mode = "add",
  rateType = 0, // 0大額(單一) 1小額(階層) 2大額(時段)
  visible = false,
  data = null,
  loading = false,
  onOk = () => {},
  onCancel = () => {},
}) => {
  const [ldg, setLdg] = useState(false);
  // const { user } = useSelector(selectAuth);
  const { list: users } = useSelector(selectUser);
  const [form] = Form.useForm();
  const handleOk = async () => {
    form.validateFields().then(async formModel => {
      if (!formModel) return;
      const {
        is_upperLayer,
        upper_layer_id,
        recharge_time_class_rate,
        ...props
      } = formModel;
      const handleTimeRateToStr = list =>
        list.map(({ start_time, end_time, ...ll }) => ({
          ...ll,
          start_time: start_time.format("HH:mm"),
          end_time:
            end_time.format("HH:mm") === "23:59"
              ? "24:00"
              : end_time.format("HH:mm"),
        }));
      let params = {
        ...props,
        upper_layer_id: is_upperLayer ? 0 : upper_layer_id || 0, // 沒有預設代理給 upper_layer_id 為 0
        username: users.find(i => i.id === formModel.user_id)?.username,
        ...(recharge_time_class_rate && {
          recharge_time_class_rate: handleTimeRateToStr(
            recharge_time_class_rate,
          ),
        }),
      };
      await onOk(params);
    });
  };

  const handleTimeRateToMoment = list =>
    list.map(({ start_time, end_time, ...ll }) => ({
      ...ll,
      start_time: moment(start_time, "HH:mm"),
      end_time: moment(end_time === "24:00" ? "23:59" : end_time, "HH:mm"),
    }));
  const [currUpperLayer, setCurrUpperLayer] = useState(null);
  const onSelectUpperLayer = async selectObj => {
    const { status, data } = await apiAgent(selectObj.id);
    if (status !== 200) return;
    setCurrUpperLayer(data);
    form.setFieldsValue({
      recharge_rate_type: data.recharge_rate_type,
      recharge_class_rate: data.recharge_class_rate,
      recharge_time_class_rate:
        data.recharge_time_class_rate &&
        handleTimeRateToMoment(data.recharge_time_class_rate),
    });
  };

  const onValuesChange = changeObj => {
    if ("is_upperLayer" in changeObj) {
      form.setFieldsValue({
        upper_layer_id: changeObj.is_upperLayer
          ? 0
          : currUpperLayer?.user_id || null,
        recharge_class_rate: changeObj.is_upperLayer
          ? null
          : currUpperLayer?.recharge_class_rate || null,
      });
    }
  };

  const addModeInit = async () => {
    let initForm = {
      is_active: true,
      is_upperLayer: false,
      upper_layer_id: null,
      recharge_rate_type: rateType,
      recharge_class_rate: null,
      recharge_time_class_rate: null,
      is_order: true,
      is_transfer: true,
      is_transfer_limit: true,
    };
    setLdg(true);
    // 取得預設代理
    const { status, data } = await configAgentType[rateType].api();
    if (status === 200 && data.user_id) {
      // 取得預設代理 detail
      const { status, data: classData } = await apiAgentList({
        user_id__eq: data.user_id,
      });
      if (status === 200) {
        const agentData = classData?.data[0] || null;
        setCurrUpperLayer(agentData);
        // 代理列表為空就不管預設代理
        if (agentData) {
          initForm.upper_layer_id = agentData.user_id;
          initForm.recharge_class_rate = agentData.recharge_class_rate;
          initForm.recharge_time_class_rate =
            agentData.recharge_time_class_rate &&
            handleTimeRateToMoment(agentData.recharge_time_class_rate);
        }
      } else {
        initForm.is_upperLayer = true;
      }
    } else {
      initForm.is_upperLayer = true;
    }
    form.setFieldsValue({ ...initForm });
    setLdg(false);
  };
  const editModeInit = () => {
    form.setFieldsValue({
      ...data,
      is_upperLayer: data.upper_layer_id === data.user_id,
      ...(data.recharge_time_class_rate && {
        recharge_time_class_rate: handleTimeRateToMoment(
          data.recharge_time_class_rate,
        ),
      }),
    });
  };

  const resetForm = async () => {
    await form.resetFields();
    onCancel();
  };
  useEffect(() => {
    if (visible) {
      mode === "add" && addModeInit();
      mode === "edit" && editModeInit();
    } else {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, data]);

  return (
    <Modal
      destroyOnClose={true}
      title={`${Mode[mode]}${configAgentType[rateType].title}`}
      visible={visible}
      onOk={handleOk}
      onCancel={resetForm}
      cancelText="取消"
      okText="送出"
      confirmLoading={loading}
      width={700}
    >
      <Spin spinning={loading || ldg}>
        <Form {...formLayout} form={form} onValuesChange={onValuesChange}>
          <Form.Item name="is_active" label="是否启用" valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item
            name="is_upperLayer"
            label="是否为超级代理"
            valuePropName="checked"
            hidden={mode === "edit"}
          >
            <Switch />
          </Form.Item>
          {mode === "edit" && (
            <Form.Item label="使用者ID">{data.user_id}</Form.Item>
          )}
          {mode === "add" && (
            <>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => (
                  <Form.Item
                    name="upper_layer_id"
                    label="上級代理"
                    hidden={getFieldValue("is_upperLayer")}
                  >
                    <SearchSelect
                      params={{ per_page: 999 }}
                      action={getAgentsSelect}
                      selector={selectAgent}
                      onSelect={onSelectUpperLayer}
                      searchKey="name"
                      val="user_id"
                      label={i => `${i.user_id} ${i.name}`}
                    />
                  </Form.Item>
                )}
              </Form.Item>

              <Form.Item
                name="user_id"
                label="使用者ID"
                rules={[{ required: true, message: "请输入用户ID" }]}
              >
                <SearchSelect
                  action={getUsers}
                  selector={selectUser}
                  // onSelect={onSelectUser}
                  searchKey="username"
                  val="id"
                  label={i => `${i.id} ${i.username}`}
                />
              </Form.Item>
            </>
          )}

          <Form.Item
            name="name"
            label="姓名"
            rules={[{ required: true, message: "请输入姓名" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="note" label="备注">
            <Input />
          </Form.Item>
          <Form.Item name="is_order" valuePropName="checked" label="可代收">
            <Checkbox />
          </Form.Item>
          <Form.Item name="is_transfer" valuePropName="checked" label="可代付">
            <Checkbox />
          </Form.Item>
          <Form.Item name="is_transfer_limit" label="限制代付范围">
            <Radio.Group>
              <Radio value={true}>是</Radio>
              <Radio value={false}>否</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) =>
              getFieldValue("is_transfer_limit") && (
                <CurrencyHelpTextFormItemFactory
                  name="transfer_min_amount"
                  label="限制代付下限"
                  row={{
                    currency: 0,
                    transfer_min_amount: getFieldValue("transfer_min_amount"),
                  }}
                  defaultValKey="transfer_min_amount"
                  key="transfer_min_amount"
                />
              )
            }
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) =>
              getFieldValue("is_transfer_limit") && (
                <CurrencyHelpTextFormItemFactory
                  name="transfer_max_amount"
                  label="限制代付上限"
                  row={{
                    currency: 0,
                    transfer_max_amount: getFieldValue("transfer_max_amount"),
                  }}
                  defaultValKey="transfer_max_amount"
                  key="transfer_max_amount"
                />
              )
            }
          </Form.Item>
          <Form.Item name="withdraw_rate" label="代付费率">
            <InputNumber step="1" min={0} max={100} />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => (
              <Form.Item name="recharge_rate_type" label="代收费率">
                <Radio.Group disabled={!getFieldValue("is_upperLayer")}>
                  <Radio value={0}>单一费率</Radio>
                  <Radio value={1}>阶层费率</Radio>
                  <Radio value={2}>时间费率</Radio>
                </Radio.Group>
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item name="recharge_rate" label="單一代收费率">
            <InputNumber step="1" min={0} max={100} />
          </Form.Item>

          <Form.Item
            shouldUpdate
            wrapperCol={{
              span: formLayout.wrapperCol.span + formLayout.labelCol.span,
            }}
          >
            <RechargeRateFormList
              name="recharge_class_rate"
              form={form}
              title="阶层费率"
            />
          </Form.Item>

          <Form.Item
            shouldUpdate
            wrapperCol={{
              span: formLayout.wrapperCol.span + formLayout.labelCol.span,
            }}
          >
            <RechargeRateFormList
              showTimeRange={true}
              name="recharge_time_class_rate"
              form={form}
              title="时段费率"
              alertMsg={
                <>
                  单个时段不可跨日，如要设定跨日请设定两个时段
                  <br />
                  例如[20:00]至隔天[08:00]，必須設定 [20:00]到[23:59]
                  以及[00:00]到[08:00]兩組時間
                </>
              }
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};
export default AddEdit;
