import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DownOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Layout, Menu, Dropdown, Button, message, Modal } from "antd";
import { selectAuth, logout } from "@/store/slice/auth";
import { resetUserPsw, resetUserOtp } from "@/store/slice/user";
import { setRouterTabs } from "@/store/slice/routerTab";
import EditableConfirm from "@/components/EditableConfirm";

const { Header } = Layout;

const HeaderView = () => {
  const { user } = useSelector(selectAuth);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleLogout = async () => {
    history.push("/Login");
    sessionStorage.removeItem("tabs");
    setRouterTabs([]);
    await dispatch(logout());
  };

  const [visiblePass, setVisiblePass] = useState(false);
  const [loading, setLoading] = useState(false);
  const handelVisiblePass = v => () => setVisiblePass(v);
  const handleUpdatePass = async formModel => {
    setLoading(true);
    const { status } = await resetUserPsw({ id: user.id, formModel });
    setLoading(false);
    if (status !== 200) return;
    setVisiblePass(false);
    message.success("重置成功！");
  };

  const handleResetOtp = async (close, id) => {
    const { status } = await resetUserOtp(id);
    close();
    if (status !== 200) return;
    message.success("重置成功！");
  };
  const handleResetOtpClick = () => {
    Modal.confirm({
      title: "重置OTP",
      icon: <ExclamationCircleOutlined />,
      content: `即将重置 ${user.name} OTP，是否继续？`,
      okText: "确认",
      cancelText: "取消",
      onOk: close => handleResetOtp(close, user.id),
    });
  };

  const UserMenu = (
    <Menu>
      {/* <Menu.Item key="1">基本资料</Menu.Item> */}
      <Menu.Item key="2" onClick={handelVisiblePass(true)}>
        重置密码
      </Menu.Item>
      <Menu.Item key="3" onClick={handleResetOtpClick}>
        重置OTP
      </Menu.Item>
      <Menu.Item key="4" onClick={handleLogout}>
        登出
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Header className="header">
        <Dropdown overlay={UserMenu} trigger={["click"]}>
          <Button type="link">
            {user.username} <DownOutlined />
          </Button>
        </Dropdown>
      </Header>
      <EditableConfirm
        title="修改密码"
        visible={visiblePass}
        onCancel={handelVisiblePass(false)}
        onOk={handleUpdatePass}
        loading={loading}
        fields={[
          {
            label: "新密码",
            name: "password",
            inputType: "string",
            required: true,
          },
        ]}
      />
    </>
  );
};
export default HeaderView;
