import React, { useEffect, useState } from "react";
import { Card, Form, Switch, message } from "antd";
import {
  getConfigBankRecord,
  postConfigBankRecord,
} from "@/store/slice/config";
import { useUserPerms } from "@/utils/hook";

// 訂單 開啟銀行卡自動上分
function AutoOrder({ formLayout }) {
  const perms = useUserPerms();
  const [form] = Form.useForm();
  const [autoOrderLdg, setAutoOrderLdg] = useState(false);
  const handleInit = async () => {
    setAutoOrderLdg(true);
    const { status, data } = await getConfigBankRecord();
    setAutoOrderLdg(false);
    if (status === 200) form.setFieldsValue({ ...data });
  };
  const onValuesChange = async (_, fields) => {
    setAutoOrderLdg(true);
    const { status } = await postConfigBankRecord({ ...fields });
    setAutoOrderLdg(false);
    status === 200 && message.success("更新成功");
  };
  useEffect(() => {
    handleInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Card size="small">
      <Form form={form} {...formLayout} onValuesChange={onValuesChange}>
        <Form.Item name="sw" label="银行卡纪录自动绑定" valuePropName="checked">
          <Switch
            disabled={!perms["config.bankrecord_update"]}
            loading={autoOrderLdg}
          />
        </Form.Item>
      </Form>
    </Card>
  );
}

export default AutoOrder;
