import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Space } from "antd";
import {
  selectDeveloperDaily,
  getDeveloperDaily,
  getDeveloperDailySum,
  exportDeveloperDaily,
} from "@/store/slice/developerDaily";
import { SearchFormFactory } from "@/components/factory/FormFactory";
import { NormalTable } from "@/components/factory/TableFactory";
import SumTable from "@/components/SumTable";
import { useList, useUserPerms, useExportExcel } from "@/utils/hook";
import { Currency, IsBoolEnum } from "@/utils/enum";
import {
  sumColumns,
  columns,
  statisticsColumns,
  exportColumnsRenderMap,
} from "./DeveloperDailyColumns";

const searchFields = {
  id__in: { type: "string", label: "ID" },
  developer_id__in: { type: "string", label: "用户ID" },
  created__btw: { type: "rangeDate", label: "报表时间", showTime: false },
  currency: { type: "select", label: "货币类型", options: Currency },
  is_online: {
    type: "select",
    label: "是否线上渠道",
    options: IsBoolEnum,
  },
};
const DeveloperDaily = () => {
  const perms = useUserPerms();
  const {
    res: { list, meta, sum },
    loading: listLoading,
    handleSearch,
    handleChangePage,
    handleChange,
  } = useList(getDeveloperDaily, selectDeveloperDaily);
  const dispatch = useDispatch();
  const getSum = (params = {}) => {
    perms["developer.daily.sum"] && dispatch(getDeveloperDailySum(params));
  };
  useEffect(() => {
    getSum();
    // eslint-disable-next-line
  }, []);
  const handleSearchClick = params => {
    handleSearch(params);
    getSum(params);
  };

  const {
    loading: exportLoading,
    handleExport,
    handleSelectedColumns,
  } = useExportExcel({
    exportColumnsRenderMap,
    exportAction: exportDeveloperDaily,
  });

  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchFormFactory
        fields={searchFields}
        handleSubmit={handleSearchClick}
        showExportBtn={true}
        handleExport={handleExport}
        exportLoading={exportLoading}
      />
      {perms["developer.daily.sum"] && (
        <SumTable data={sum} labels={sumColumns} />
      )}
      <NormalTable
        allColumns={columns}
        defaultColumns={columns.map(i => i.dataIndex)}
        dataSource={list}
        meta={meta}
        onChangePage={handleChangePage}
        onChange={handleChange}
        loading={listLoading}
        onShowSizeChange={handleChangePage}
        statisticsColumns={statisticsColumns}
        setSelectedColumns={handleSelectedColumns}
      />
    </Space>
  );
};
export default DeveloperDaily;
