import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "@/utils/request";
import { metaToPagin } from "@/utils/format";

export const getAppProfitSummary = createAsyncThunk(
  "report/getAppProfitSummary",
  async params => {
    const res = await request({
      url: "/api/report/app/profit/summary",
      method: "get",
      params,
    });
    return res;
  },
);

export const getAppProfitSummarySum = createAsyncThunk(
  "report/getAppProfitSummarySum",
  async params => {
    const res = await request({
      url: "/api/report/app/profit/summary/sum",
      method: "get",
      params,
    });
    return res;
  },
);

export const getAppProfitSummaryRefresh = async params => {
  const res = await request({
    url: "/api/report/app/profit/summary/refresh",
    method: "get",
    params,
  });
  return res;
};

// 匯出 excel
export const exportAppProfitSummary = async (params = {}) => {
  const res = await request({
    url: `/api/report/app/profit/summary/export`,
    method: "get",
    params: { per_page: 9999, ...params },
  });
  return res;
};

export const slice = createSlice({
  name: "AppProfitSummary",
  initialState: {
    list: [],
    meta: {},
    sum: {},
  },
  extraReducers: {
    [getAppProfitSummary.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.list = data.data;
      state.meta = metaToPagin(data.meta);
    },
    [getAppProfitSummarySum.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.sum = data;
    },
  },
});
export const selectAppProfitSummary = state => state.appProfitSummary;
export default slice.reducer;
