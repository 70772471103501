import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "@/utils/request";
import { metaToPagin } from "@/utils/format";

export const getProducts = createAsyncThunk(
  "product/getList",
  async (params = {}) => {
    const res = await request({
      url: "/api/products",
      method: "get",
      params,
    });
    return res;
  },
);

export const getProduct = createAsyncThunk("product/getDetail", async id => {
  const res = await request({ url: `/api/products/${id}`, method: "get" });
  return res;
});

export const addProduct = async params => {
  const res = await request({
    url: `/api/products`,
    method: "post",
    data: params,
  });
  return res;
};

export const batchProduct = async params => {
  const res = await request({
    url: `/api/products/batch`,
    method: "post",
    data: params.formData,
  });
  return res;
};

export const editProduct = async params => {
  const res = await request({
    url: `/api/products/${params.id}`,
    method: "post",
    data: params.formModel,
  });
  return res;
};

export const deleteProduct = async id => {
  const res = await request({
    url: `/api/products/${id}`,
    method: "delete",
  });
  return res;
};

export const slice = createSlice({
  name: "product",
  initialState: {
    list: [],
    meta: {},
    currentRow: {},
  },
  reducers: {
    setProducts: (state, action) => {
      state.list = action.payload;
    },
  },
  extraReducers: {
    [getProducts.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.list = data.data.sort((a, b) => a.seq - b.seq);
      state.meta = metaToPagin(data.meta);
    },
    [getProduct.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.currentRow = data;
    },
  },
});

export const { setProducts } = slice.actions;
export const selectProduct = state => state.product;
export default slice.reducer;
