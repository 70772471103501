import React from "react";
import { Space } from "antd";
import {
  selectAppAcctLog,
  getAppAcctLogs,
  exportAppAccLog,
} from "@/store/slice/appAcctLog";
import { useList, useExportExcel } from "@/utils/hook";
import { SearchFormFactory } from "@/components/factory/FormFactory";
import { NormalTable } from "@/components/factory/TableFactory";
import {
  columns,
  defaultColumns,
  exportColumnsRenderMap,
} from "./AppAcctLogColumn";

const AppAcctLog = () => {
  const searchFields = {
    id__in: { type: "string", label: "ID" },
    app_acct_id__in: { type: "string", label: "商户账户ID" },
    app_user_id__in: { type: "string", label: "商户用户ID" },
    app_name__k: { type: "string", label: "商户名称" },
    order_no__in: { type: "string", label: "订单号" },
    trans_no__in: { type: "string", label: "第三方订单号" },
    funding_type: {
      type: "select",
      label: "资金类型",
      options: {
        '{"type__ne":13}': "余额",
        '{"type__eq":13}': "圈存",
      },
    },
    created__btw: { type: "rangeDate", label: "创建日期" },
  };

  const {
    res: { list, meta },
    loading: listLoading,
    handleSearch,
    handleChangePage,
    handleChange,
  } = useList(getAppAcctLogs, selectAppAcctLog);

  const handleSearchSubmit = (params = {}) => {
    handleSearch(params);
  };

  const {
    loading: exportLoading,
    handleExport,
    handleSelectedColumns,
  } = useExportExcel({
    exportColumnsRenderMap,
    exportAction: exportAppAccLog,
  });

  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchFormFactory
        fields={searchFields}
        handleSubmit={handleSearchSubmit}
        showExportBtn={true}
        handleExport={handleExport}
        exportLoading={exportLoading}
      />
      <NormalTable
        allColumns={columns}
        defaultColumns={defaultColumns}
        dataSource={list}
        meta={meta}
        onChangePage={handleChangePage}
        onChange={handleChange}
        loading={listLoading}
        onShowSizeChange={handleChangePage}
        setSelectedColumns={handleSelectedColumns}
      />
    </Space>
  );
};
export default AppAcctLog;
