import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "@/utils/request";
import { metaToPagin } from "@/utils/format";

export const getGatewayAccts = createAsyncThunk(
  "gatewayAcct/getList",
  async (params = {}) => {
    const res = await request({
      url: "/api/gatewayaccts",
      method: "get",
      params,
    });
    return res;
  },
);
export const getGatewayAcct = createAsyncThunk(
  "gatewayAcct/getDetail",
  async id => {
    const res = await request({
      url: `/api/gatewayaccts/${id}`,
      method: "get",
    });
    return res;
  },
);
export const addGatewayAcct = async data => {
  const res = await request({
    url: `/api/gatewayaccts`,
    method: "post",
    data,
  });
  return res;
};
export const editGatewayAcct = async params => {
  const res = await request({
    url: `/api/gatewayaccts/${params.id}`,
    method: "post",
    data: params.formModel,
  });
  return res;
};
export const editGatewayAcctBalance = async params => {
  const res = await request({
    url: `/api/gatewayaccts/${params.id}/balance`,
    method: "post",
    data: params.formModel,
  });
  return res;
};

export const slice = createSlice({
  name: "gatewayAcct",
  initialState: {
    list: [],
    meta: {},
    currentRow: {},
    codeInfo: {},
  },
  reducers: {
    setGateways: (state, action) => {
      state.list = action.payload;
    },
  },
  extraReducers: {
    [getGatewayAccts.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.list = data.data;
      state.meta = metaToPagin(data.meta);
    },
    [getGatewayAcct.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.currentRow = data;
    },
  },
});
export const { setGatewayAcct } = slice.actions;
export const selectGatewayAcct = state => state.gatewayAcct;
export default slice.reducer;
