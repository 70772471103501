import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { Card, Form, Spin, message } from "antd";
import { selectUser, getUsers } from "@/store/slice/user";
import {
  getConfigAgent,
  getConfigAgentClass,
  postConfigAgent,
  postConfigAgentClass,
} from "@/store/slice/config";
import { useUserPerms } from "@/utils/hook";
import SearchSelect from "@/components/SearchSelect";

// 預設代理
function DefaultAgent({ formLayout }) {
  const perms = useUserPerms();
  const [agentLdg, setAgentLdg] = useState(false);
  const [agentForm] = Form.useForm();
  const handleAgentSelect = async ({ id, name }, postApi) => {
    setAgentLdg(true);
    const { status } = await postApi({ user_id: id, name });
    setAgentLdg(false);
    if (status !== 200) return;
    message.success("更新成功");
    handleGetAgentConfig();
  };
  const handleGetAgentConfig = () => {
    setAgentLdg(true);
    Promise.all([getConfigAgent(), getConfigAgentClass()])
      .then(([res1, res2]) => {
        let formInit = { agent_user_id: null, agentClass_user_id: null };
        if (res1.status === 200)
          formInit.agent_user_id = res1.data.user_id || null;
        if (res2.status === 200)
          formInit.agentClass_user_id = res2.data.user_id || null;
        agentForm.setFieldsValue({ ...formInit });
      })
      .finally(() => setAgentLdg(false));
  };

  useEffect(() => {
    handleGetAgentConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card title="预设代理" size="small">
      <Spin spinning={agentLdg}>
        <Form form={agentForm} {...formLayout}>
          <Form.Item name="agent_user_id" label="大额代理">
            <SearchSelect
              action={getUsers}
              params={{ is_agent__eq: 1 }}
              selector={selectUser}
              onSelect={data => handleAgentSelect(data, postConfigAgent)}
              searchKey="username"
              val="id"
              label={i => `${i.id} ${i.username}`}
              disabled={!perms["config.agent_update"]}
            />
          </Form.Item>
          <Form.Item name="agentClass_user_id" label="小额代理">
            <SearchSelect
              action={getUsers}
              params={{ is_agent__eq: 1 }}
              selector={selectUser}
              onSelect={data => handleAgentSelect(data, postConfigAgentClass)}
              searchKey="username"
              val="id"
              label={i => `${i.id} ${i.username}`}
              disabled={!perms["config.agent_update"]}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Card>
  );
}

export default DefaultAgent;
