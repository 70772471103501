// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "@/utils/request";
// import { metaToPagin } from "@/utils/format";

export const postAcctSwap = async (data = {}) => {
  const res = await request({
    url: "/api/tool/acctswap",
    method: "post",
    data,
  });
  return res;
};

export const postPickGateway = async (data = {}) => {
  const res = await request({
    url: "/api/tool/pick_gateway",
    method: "post",
    data,
  });
  return res;
};

export const postUpdateCallbackHost = async (data = {}) => {
  const res = await request({
    url: "/api/tool/update_gateway_callback_host",
    method: "post",
    data,
  });
  return res;
};

// export const slice = createSlice({
//   name: "tool",
//   initialState: {},
//   extraReducers: {},
// });
// export const selectTool = state => state.tool;
// export default slice.reducer;
