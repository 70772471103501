import { useMemo } from "react";
import { Modal, Descriptions, Button, Tree } from "antd";
import { dateFormat } from "@/utils/format";
import { PermsPageTree, getDefaultCheckKeys } from "@/utils/enum/perms";
import Spin from "@/components/Spin";
const Detail = ({ visible, loading, data, onCancel }) => {
  const { id, name, total, perms, created, updated, note } = data;
  const selectedKeys = useMemo(() => {
    return visible && perms ? getDefaultCheckKeys(perms) : [];
  }, [perms, visible]);

  return (
    <Modal
      title="角色明细"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="close" type="primary" onClick={onCancel}>
          关闭
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Descriptions column={1} bordered size="small">
          <Descriptions.Item label="ID">{id}</Descriptions.Item>
          <Descriptions.Item label="名称">{name}</Descriptions.Item>
          <Descriptions.Item label="使用者数量">{total}</Descriptions.Item>
          <Descriptions.Item label="权限列表">
            <Tree
              showIcon
              checkable
              treeData={PermsPageTree}
              checkedKeys={selectedKeys}
              disabled={true}
            />
          </Descriptions.Item>
          <Descriptions.Item label="创建日期">
            {dateFormat(created)}
          </Descriptions.Item>
          <Descriptions.Item label="更新日期">
            {dateFormat(updated)}
          </Descriptions.Item>
          <Descriptions.Item label="备注">{note}</Descriptions.Item>
        </Descriptions>
      </Spin>
    </Modal>
  );
};

export default Detail;
