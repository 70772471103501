import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Switch, Button, Space, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { selectConfig } from "@/store/slice/config";
import {
  selectAppGateway,
  getAppGateways,
  deleteAppGateway,
  postAppGateway,
} from "@/store/slice/appGateway";
import { useList, useUserPerms } from "@/utils/hook";

import { EditableTable } from "@/components/factory/TableFactory";

const Content = ({ appData = {} }) => {
  const { payTypeMap } = useSelector(selectConfig);
  const perms = useUserPerms();
  const {
    res: { list, meta },
    loading: listLoading,
    handleGetList,
    handleChangePage,
    handleChange,
  } = useList(getAppGateways, selectAppGateway, { app_id: appData.id }, false);

  const handleRowEditSubmit = async params => {
    const { status } = await postAppGateway(params);
    if (status !== 200) return;
    message.success("操作成功！");
    handleGetList();
  };

  const [enabledLoading, setEnabledLoading] = useState(false);
  const handleChangeIsActive = async (is_enabled, record) => {
    setEnabledLoading(true);
    const { status } = await postAppGateway({ ...record, is_enabled });
    setEnabledLoading(false);
    if (status !== 200) return;
    message.success("操作成功！");
    handleGetList({ page: meta.current });
  };

  const handleDeleteGateway = async (close, id) => {
    await deleteAppGateway(id);
    close();
    handleGetList();
  };
  const handleDeleteClick = record => {
    Modal.confirm({
      title: "是否清除渠道汇率设定",
      icon: <ExclamationCircleOutlined />,
      content: `即将清除 ${record.gateway_name}，是否继续？`,
      okText: "确认",
      cancelText: "取消",
      onOk: close => handleDeleteGateway(close, record.id),
    });
  };
  const columns = [
    // { title: "ID", dataIndex: "id", sorter: true },
    { title: "渠道ID", dataIndex: "gateway_id", sorter: true },
    { title: "渠道名称", dataIndex: "gateway_name", sorter: true },
    {
      title: "支付类别",
      dataIndex: "pay_type",
      render: val => payTypeMap[val] || "",
    },
    {
      title: "代收费率",
      dataIndex: "recharge_rate",
      inputType: "string",
      editable: true,
      sorter: true,
      // eslint-disable-next-line no-dupe-keys
      inputType: "number",
    },
    {
      title: "启用",
      dataIndex: "is_enabled",
      render: (val, record) => (
        <Switch
          checked={val}
          onChange={checked => handleChangeIsActive(checked, record)}
          loading={enabledLoading}
        />
      ),
    },
    // { title: "商户ID", dataIndex: "app_id", sorter: true },
    // { title: "商户名称", dataIndex: "app_name", sorter: true },
    {
      title: "操作者ID",
      dataIndex: "op_user_id",
      sorter: true,
      render: val => val || null,
    },
    { title: "操作者名称", dataIndex: "op_user_name", sorter: true },
    {
      title: "动作",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      render: (_, record) => (
        <Space>
          {record.id !== 0 && (
            <Button
              size="small"
              onClick={() => handleDeleteClick(record)}
              type="link"
              danger
              className="p-0"
              disabled={!perms["appgateways.Delete"]}
            >
              清除渠道
            </Button>
          )}
        </Space>
      ),
    },
  ];
  const defaultColumns = [];

  return (
    <>
      <EditableTable
        allColumns={columns}
        isShowAllColumns={true}
        defaultColumns={defaultColumns}
        dataSource={list}
        loading={listLoading || enabledLoading}
        onChangePage={handleChangePage}
        onChange={handleChange}
        onRowEditSubmit={handleRowEditSubmit}
        onShowSizeChange={handleChangePage}
        rowKey="gateway_id"
        meta={meta}
        editDisabled={!perms["appgateways.Create"]}
      />
    </>
  );
};

function GatewayListModal({
  visible = false,
  onCancel = () => {},
  appData = {},
}) {
  return (
    <>
      <Modal
        title="渠道"
        visible={visible}
        width={"80%"}
        onCancel={() => onCancel?.()}
        footer={false}
        destroyOnClose={true}
      >
        {visible && <Content appData={appData} />}
      </Modal>
    </>
  );
}

export default GatewayListModal;
