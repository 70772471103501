import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "@/utils/request";
import { metaToPagin } from "@/utils/format";

export const getAgentNotices = createAsyncThunk(
    "agentNotice/getList",
    async (params = {}) => {
        const res = await request({
            url: "/api/agent-notices",
            method: "get",
            params,
        });
        return res;
    },
);

export const getAgentNotice = createAsyncThunk(
    "agentNotice/getDetail", async id => {
        const res = await request({ url: `/api/agent-notices/${id}`, method: "get" });
        return res;
    },
);

export const addAgentNotice = async params => {
    const res = await request({
        url: `/api/agent-notices`,
        method: "post",
        data: params,
    });
    return res;
};

export const editAgentNotice = async params => {
    const res = await request({
        url: `/api/agent-notices/${params.id}`,
        method: "post",
        data: params.formModel,
    });
    return res;
};

export const deleteAgentNotice = async id => {
    const res = await request({
        url: `/api/agent-notices/${id}`,
        method: "delete",
    });
    return res;
};

export const slice = createSlice({
    name: "agentNotice",
    initialState: {
        list: [],
        meta: {},
        currentRow: {},
    },
    reducers: {
        setAgentNotices: (state, action) => {
            state.list = action.payload;
        },
    },
    extraReducers: {
        [getAgentNotices.fulfilled]: (state, action) => {
            const { status, data } = action.payload;
            if (status !== 200) return;
            state.list = data.data.sort((a, b) => a.seq - b.seq);
            state.meta = metaToPagin(data.meta);
        },
        [getAgentNotice.fulfilled]: (state, action) => {
            const { status, data } = action.payload;
            if (status !== 200) return;
            state.currentRow = data;
        },
    },
});

export const { setAgentNotices } = slice.actions;
export const selectAgentNotice = state => state.agentNotice;
export default slice.reducer;
