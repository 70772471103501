import React from "react";
import { Card, Space } from "antd";

import AcctSwap from "./AcctSwap";
import PickGateway from "./PickGateway";
import UpdateCallbackHost from "./UpdateCallbackHost";

function Tool() {
  return (
    <>
      <Card>
        <Space>
          <AcctSwap />
          <PickGateway />
          <UpdateCallbackHost />
        </Space>
      </Card>
    </>
  );
}

export default Tool;
