import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Space, Button } from "antd";
import {
  selectGatewayDaily,
  getGatewayDaily,
  getGatewayDailySum,
  getGatewayDailyRefresh,
  exportGatewayDaily,
} from "@/store/slice/gatewayDaily";
import { selectGateway, getGateways } from "@/store/slice/gateway";
import {
  useList,
  useUserPerms,
  useExportExcel,
  useSearchCache,
} from "@/utils/hook";
import { Currency, IsBoolEnum } from "@/utils/enum";
import { SearchFormFactory } from "@/components/factory/FormFactory";
import { NormalTable } from "@/components/factory/TableFactory";
import SumTable from "@/components/SumTable";
import RefreshDataModal from "@/components/RefreshDataModal";
import {
  sumColumns,
  columns,
  statisticsColumns,
  exportColumnsRenderMap,
} from "./GatewayDailyColumn";

const AppGatewayDaily = () => {
  const perms = useUserPerms();
  const searchFields = {
    id__in: { type: "string", label: "ID" },
    created__btw: { type: "rangeDate", label: "报表时间", showTime: false },
    currency: { type: "select", label: "货币类型", options: Currency },
    is_online: {
      type: "select",
      label: "是否线上渠道",
      options: IsBoolEnum,
    },
    ...(perms["gateway.List"] && {
      gateway_id__in: {
        type: "searchSelect",
        label: "渠道列表",
        action: getGateways,
        selector: selectGateway,
        searchKey: "name",
        val: "id",
        mode: "multiple",
        optionLabel: i => `${i.id} ${i.name}`,
      },
    }),
  };
  const {
    res: { list, meta, sum },
    loading: listLoading,
    handleGetList,
    handleSearch,
    handleChangePage,
    handleChange,
  } = useList(getGatewayDaily, selectGatewayDaily);

  const dispatch = useDispatch();
  const { handleGetPageSearchCache } = useSearchCache();
  const getSum = (params = {}) => {
    perms["gateway.daily.sum"] &&
      dispatch(
        getGatewayDailySum({ ...handleGetPageSearchCache(), ...params }),
      );
  };
  useEffect(() => {
    getSum();
    // eslint-disable-next-line
  }, []);

  const [searchData, setSearchData] = useState({});
  const handleSearchClick = params => {
    setSearchData(params);
    handleSearch(params);
    getSum(params);
  };

  const {
    loading: exportLoading,
    handleExport,
    handleSelectedColumns,
  } = useExportExcel({
    exportColumnsRenderMap,
    exportAction: exportGatewayDaily,
  });

  const [refreshVisible, setRefreshVisible] = useState(false);

  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchFormFactory
        fields={searchFields}
        handleSubmit={handleSearchClick}
        showExportBtn={true}
        handleExport={handleExport}
        exportLoading={exportLoading}
        otherBtn={
          <Button type="primary" onClick={() => setRefreshVisible(true)}>
            更新报表
          </Button>
        }
      />
      {perms["gateway.daily.sum"] && (
        <SumTable data={sum} labels={sumColumns} />
      )}
      <NormalTable
        allColumns={columns}
        defaultColumns={columns.map(i => i.dataIndex)}
        dataSource={list}
        meta={meta}
        onChangePage={handleChangePage}
        onChange={handleChange}
        loading={listLoading}
        onShowSizeChange={handleChangePage}
        statisticsColumns={statisticsColumns}
        setSelectedColumns={handleSelectedColumns}
      />
      <RefreshDataModal
        visible={refreshVisible}
        setVisible={setRefreshVisible}
        refreshAction={getGatewayDailyRefresh}
        callback={() => {
          handleGetList();
          getSum(searchData);
        }}
      />
    </Space>
  );
};
export default AppGatewayDaily;
