import React, { useState } from "react";
import { Button, Space, Modal, Upload, Tooltip, Alert } from "antd";
import {
  PlusOutlined,
  ExclamationCircleOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useList, useDetail, useUserPerms } from "@/utils/hook";
import { dateFormat } from "@/utils/format";
import {
  selectBank,
  getBanks,
  getBank,
  addBank,
  editBank,
  deleteBank,
  getBankFormat,
  batchBank,
  postBankLogo,
} from "@/store/slice/bank";
import { SearchFormFactory } from "@/components/factory/FormFactory";
import { EditableTable } from "@/components/factory/TableFactory";
import Detail from "@/components/Detail";
import AddEdit from "./AddEdit";

function Bank() {
  const perms = useUserPerms();
  const searchFields = {
    id__in: { type: "string", label: "ID" },
    code__k: { type: "string", label: "银行代号" },
    name__k: { type: "string", label: "银行名称" },
    created__btw: { type: "rangeDate", label: "创建日期" },
  };

  const {
    res: { list, meta },
    loading: listLoading,
    handleSearch,
    handleGetList,
    handleChangePage,
    handleChange,
    handleAdd: handleAddHook,
  } = useList(getBanks, selectBank);
  const [addVisible, setAddVisible] = useState(false);
  const handleAddClick = () => {
    setAddVisible(true);
  };
  const handleAdd = async formModel => {
    handleAddHook({ action: addBank, ...formModel });
    setAddVisible(false);
  };

  const [detailId, setDetailId] = useState(null);
  const {
    currentRow,
    loading: detailLoading,
    handleEdit: handleEditHook,
  } = useDetail({ action: getBank, id: detailId }, selectBank);
  const [detailVisible, setDetailVisible] = useState(false);
  const handleDetailClick = async id => {
    setDetailId(id);
    setDetailVisible(true);
  };
  const [editVisible, setEditVisible] = useState(false);
  const handleEditClick = async id => {
    setDetailId(id);
    setEditVisible(true);
  };
  const handleEdit = async formModel => {
    const { status } = await handleEditHook({
      action: editBank,
      id: currentRow.id,
      ...formModel,
    });
    if (status !== 200) return;
    setEditVisible(false);
    setDetailId(null);
    handleGetList({ page: meta.current });
  };
  const handleDelete = async (close, id) => {
    const { status } = await deleteBank(id);
    close();
    if (status !== 200) return;
    handleGetList({ page: meta.current });
  };
  const handleDeleteClick = id => {
    Modal.confirm({
      title: "是否删除",
      icon: <ExclamationCircleOutlined />,
      content: `即将删除 ${id}，是否继续？`,
      okText: "确认",
      cancelText: "取消",
      onOk: close => handleDelete(close, id),
    });
  };

  const handleRowEditSubmit = async ({ id, ...params }) => {
    await handleEditHook({ action: editBank, id, ...params });
    handleGetList({ page: meta.current });
  };

  const handleDownloadClick = async () => {
    const { status, data } = await getBankFormat();
    if (status !== 200) return;
    var dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(data));
    let a = document.createElement("a");
    a.href = dataStr;
    a.download = "bank-demo.json";
    a.click();
  };

  const handleBatchUpload = async file => {
    let form = new FormData();
    form.append("file", file);
    const { status } = await batchBank(form);
    if (status === 200) handleGetList({ page: meta.current });
    return false;
  };

  const handleLogoUpload = async (id, logo) => {
    let formData = new FormData();
    formData.append("logo", logo);
    console.log("logo", logo);
    // console.log("formData", formData.getAll());
    const { status } = await postBankLogo({ id, formData });
    if (status === 200) handleGetList({ page: meta.current });
    return false;
  };

  const columns = [
    { title: "ID", dataIndex: "id" },
    {
      title: "Logo",
      dataIndex: "logo_url",
      render: val => (val ? <img src={val} height={30} alt="" /> : "未上傳"),
    },
    { title: "银行代号", dataIndex: "code", editable: true },
    { title: "银行名称", dataIndex: "name", editable: true },
    {
      title: "创建日期",
      dataIndex: "created",
      render: val => dateFormat(val),
      sorter: true,
      className: "text-nowrap",
    },
    {
      title: "更新日期",
      dataIndex: "updated",
      render: val => dateFormat(val),
      sorter: true,
      className: "text-nowrap",
    },
    {
      title: "动作",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      render: (_, record) => (
        <Space>
          <Button
            size="small"
            onClick={() => handleDetailClick(record.id)}
            type="link"
            className="p-0"
            disabled={!perms["bank.Get"]}
          >
            查看
          </Button>
          <Button
            size="small"
            type="text"
            className="p-0"
            onClick={() => handleEditClick(record.id)}
            disabled={!perms["bank.Get"] || !perms["bank.Update"]}
          >
            编辑
          </Button>
          <Upload
            beforeUpload={file => handleLogoUpload(record.id, file)}
            showUploadList={false}
            accept="image/*"
          >
            <Button size="small" type="link" className="p-0">
              設定logo
            </Button>
          </Upload>
          <Button
            size="small"
            type="text"
            className="p-0"
            danger
            onClick={() => handleDeleteClick(record.id)}
            disabled={!perms["bank.Delete"]}
          >
            删除
          </Button>
        </Space>
      ),
    },
  ];
  const defaultColumns = ["id", "code", "name", "created", "updated", "action"];
  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchFormFactory fields={searchFields} handleSubmit={handleSearch} />
      <Alert
        message={"第一次操作请「下载银行JSON档案」并且「汇入银行JSON档案」"}
        type="warning"
        showIcon
      />
      <Space className="w-100" style={{ justifyContent: "space-between" }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleAddClick}
          disabled={!perms["bank.Create"]}
        >
          添加
        </Button>
        <Space>
          <Button
            type="primary"
            ghost
            icon={<DownloadOutlined />}
            onClick={handleDownloadClick}
          >
            下载银行JSON档案
          </Button>
          <Upload
            beforeUpload={handleBatchUpload}
            showUploadList={false}
            accept=".json"
          >
            <Tooltip title="限定 json 格式">
              <Button type="primary" ghost icon={<UploadOutlined />}>
                汇入银行JSON档案
              </Button>
            </Tooltip>
          </Upload>
        </Space>
      </Space>
      <EditableTable
        allColumns={columns}
        defaultColumns={defaultColumns}
        dataSource={list}
        loading={listLoading}
        onChangePage={handleChangePage}
        onChange={handleChange}
        onRowEditSubmit={handleRowEditSubmit}
        onShowSizeChange={handleChangePage}
        meta={meta}
        editDisabled={!perms["bank.Update"]}
      />
      <AddEdit
        visible={addVisible}
        onOk={handleAdd}
        onCancel={() => setAddVisible(false)}
        loading={listLoading}
        mode="add"
      />
      <AddEdit
        visible={editVisible}
        onOk={handleEdit}
        onCancel={() => setEditVisible(false)}
        loading={detailLoading}
        data={currentRow}
        mode="edit"
      />
      <Detail
        title="银行明细"
        visible={detailVisible}
        data={currentRow}
        onCancel={() => setDetailVisible(false)}
        loading={detailLoading}
        columns={columns.filter(i => i.dataIndex !== "action")}
      />
    </Space>
  );
}

export default Bank;
