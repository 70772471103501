import { useState, useEffect } from "react";
import { Button, Space, Switch } from "antd";
import {
  selectAgent,
  getAgents,
  getAgent,
  addAgent,
  editAgent,
} from "@/store/slice/agent";
import { PlusOutlined } from "@ant-design/icons";
import { useList, useDetail, useUserPerms } from "@/utils/hook";
import { SearchFormFactory } from "@/components/factory/FormFactory";
import { EditableTable } from "@/components/factory/TableFactory";
import Tag from "@/components/Tag";
import Detail from "@/components/Detail";
import JsonModal from "@/components/JsonModal";
import { IsBoolEnum } from "@/utils/enum";
import { dateFormat } from "@/utils/format";
import AddEdit from "./AddEdit";
import UpperLayerAgentListModal from "./UpperLayerAgentListModal";

const Agent = () => {
  const perms = useUserPerms();
  const searchFields = {
    id__in: { type: "string", label: "ID" },
    user_id__in: { type: "string", label: "使用者ID" },
    username__k: { type: "string", label: "使用者" },
    name__k: { type: "string", label: "姓名" },
    is_active: {
      type: "select",
      label: "是否启用",
      options: IsBoolEnum,
      isBool: true,
    },
    created__btw: { type: "rangeDate", label: "创建日期" },
  };
  const {
    res: { list, meta },
    loading: listLoading,
    handleSearch,
    handleGetList,
    handleChangePage,
    handleChange,
    handleAdd: handleAddHook,
    setLoading: setListLoading,
  } = useList(getAgents, selectAgent);

  /**
   * 新增大小額
   * rateType: 0大額(單一) 1小額(階層)
   */
  const [addMode, setAddMode] = useState({ visible: false, rateType: 0 });
  const handleAddClick = (visible = false, rateType = 0) => {
    setAddMode({ visible, rateType });
  };
  const handleAdd = async formModel => {
    handleAddHook({ action: addAgent, ...formModel });
    handleAddClick();
  };

  /**
   * Detail
   */
  const [detailId, setDetailId] = useState(null);
  const {
    currentRow,
    loading: detailLoading,
    handleEdit: handleEditHook,
  } = useDetail({ action: getAgent, id: detailId }, selectAgent);
  const [detailVisible, setDetailVisible] = useState(false);
  const handleDetailClick = async id => {
    setDetailId(id);
    setDetailVisible(true);
  };

  /**
   * 查看上級代理
   */
  const [upperLayerId, setUpperLayerId] = useState(null);
  const [upperLayerAgentListVisible, setUpperLayerAgentListVisible] =
    useState(false);
  const handleUpperLayerAgentListClick = async id => {
    setUpperLayerId(id);
    setUpperLayerAgentListVisible(true);
  };

  const [jsonVisible, setJsonVisible] = useState(false);
  const handleJsonClick = async id => {
    setDetailId(id);
    setJsonVisible(true);
  };
  useEffect(() => {
    jsonVisible || setDetailId(null);
  }, [jsonVisible]);

  /**
   * 編輯
   * rateType: 0大額(單一) 1小額(階層) 2大額(時段)
   */
  const [editMode, setEditMode] = useState({ visible: false, rateType: 0 });
  const handleEditClick = ({ id, recharge_rate_type }) => {
    setDetailId(id);
    setEditMode({ visible: true, rateType: recharge_rate_type });
  };
  const handleEditClose = () => setEditMode({ visible: false, rateType: 0 });
  const handleEdit = async formModel => {
    const { status } = await handleEditHook({
      action: editAgent,
      id: currentRow.id,
      ...formModel,
    });
    if (status !== 200) return;
    handleEditClose();
    setDetailId(null);
    handleGetList({ page: meta.current });
  };
  const handleRowEditSubmit = async ({ id, ...params }) => {
    await handleEditHook({ action: editAgent, id, ...params });
    handleGetList({ page: meta.current });
  };

  const handleChangeIsActive = async (checked, { id, ...params }) => {
    setListLoading(true);
    await handleEditHook({
      action: editAgent,
      id,
      ...params,
      is_active: checked,
    });
    handleGetList({ page: meta.current });
  };

  const columns = [
    { title: "ID", dataIndex: "id", sorter: true },
    {
      title: "使用者ID",
      dataIndex: "user_id",
      sorter: true,
    },
    {
      title: "使用者",
      dataIndex: "username",
      sorter: true,
    },
    {
      title: "姓名",
      dataIndex: "name",
      editable: true,
      inputType: "string",
      sorter: true,
    },
    {
      title: "层级",
      dataIndex: "level",
      sorter: true,
    },
    {
      title: "查看上级代理",
      dataIndex: "action-look-up-upper-layer-list",
      align: "center",
      render: (_, record) => (
        <Space>
          <Button
            size="small"
            onClick={() => handleUpperLayerAgentListClick(record.id)}
            type="link"
            className="p-0"
            disabled={!perms["agent.UpperList"]}
          >
            查看
          </Button>
        </Space>
      ),
    },
    {
      title: "代收费率",
      dataIndex: "recharge_rate",
      inputType: "string",
      sorter: true,
    },
    {
      title: "启用",
      dataIndex: "is_active",
      dRender: val => <Tag val={val} />,
      render: (val, record) => (
        <Switch
          checked={val}
          onChange={checked => handleChangeIsActive(checked, record)}
          disabled={!perms["agent.Update"]}
        />
      ),
    },
    {
      title: "代付费率",
      dataIndex: "withdraw_rate",
      inputType: "string",
      sorter: true,
    },
    {
      title: "创建日期",
      dataIndex: "created",
      render: val => dateFormat(val),
      sorter: true,
      className: "text-nowrap",
    },
    {
      title: "更新日期",
      dataIndex: "updated",
      render: val => dateFormat(val),
      sorter: true,
      className: "text-nowrap",
    },
    { title: "备注", dataIndex: "note" },
    {
      title: "动作",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      render: (_, record) => (
        <Space>
          <Button
            size="small"
            onClick={() => handleJsonClick(record.id)}
            type="link"
            className="p-0"
            disabled={!perms["agent.Get"]}
          >
            json
          </Button>
          <Button
            size="small"
            onClick={() => handleDetailClick(record.id)}
            type="link"
            className="p-0"
            disabled={!perms["agent.Get"]}
          >
            查看
          </Button>
          <Button
            size="small"
            type="text"
            className="p-0"
            onClick={() => handleEditClick(record)}
            disabled={!perms["agent.Update"] || !perms["agent.Get"]}
          >
            编辑
          </Button>
        </Space>
      ),
    },
  ];
  const defaultColumns = [
    "id",
    "user_id",
    "username",
    "name",
    "level",
    "action-look-up-upper-layer-list",
    "recharge_rate",
    "is_active",
    "action",
  ];
  const addDisabled = !perms["agent.Create"] || !perms["config.agent"];
  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchFormFactory fields={searchFields} handleSubmit={handleSearch} />
      <Space>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => handleAddClick(true, 0)}
          disabled={addDisabled}
        >
          添加大额代理
        </Button>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => handleAddClick(true, 1)}
          disabled={addDisabled}
        >
          添加小额代理
        </Button>
      </Space>

      <EditableTable
        allColumns={columns}
        defaultColumns={defaultColumns}
        dataSource={list}
        loading={listLoading}
        onChangePage={handleChangePage}
        onChange={handleChange}
        onRowEditSubmit={handleRowEditSubmit}
        onShowSizeChange={handleChangePage}
        meta={meta}
        editDisabled={!perms["agent.Update"]}
      />
      <UpperLayerAgentListModal
        modalVisible={upperLayerAgentListVisible}
        onModalCancel={() => setUpperLayerAgentListVisible(false)}
        originParams={{ id: upperLayerId }}
      />
      <JsonModal
        visible={jsonVisible}
        data={currentRow}
        onCancel={() => setJsonVisible(false)}
        loading={detailLoading}
      />
      <Detail
        title="明细"
        visible={detailVisible}
        data={currentRow}
        onCancel={() => setDetailVisible(false)}
        loading={detailLoading}
        columns={columns.filter(i => i.dataIndex !== "action")}
      />
      <AddEdit
        visible={editMode.visible}
        rateType={editMode.rateType}
        onOk={handleEdit}
        onCancel={handleEditClose}
        loading={detailLoading}
        data={currentRow}
        mode="edit"
      />
      <AddEdit
        visible={addMode.visible}
        rateType={addMode.rateType}
        onOk={handleAdd}
        onCancel={handleAddClick}
        loading={listLoading}
        mode="add"
      />
    </Space>
  );
};
export default Agent;
