import React, { useState, useEffect, useMemo } from "react";
import { Button, Space, Modal, message, Image } from "antd";
import { useHistory, generatePath } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  selectOrder,
  getOrders,
  getOrdersSum,
  getOrder,
  addOrder,
  approveOrder,
  denyOrder,
  // cancelOrder,
  recycleOrder,
  notifyOrder,
  completedOrder,
  exportOrder,
} from "@/store/slice/order";
import { selectApp, getApps } from "@/store/slice/app";
import { selectGateway, getGateways } from "@/store/slice/gateway";
import { Currency, IsBoolEnum } from "@/utils/enum";
import {
  useList,
  useDetail,
  useExportExcel,
  useUserPerms,
  useSearchCache,
} from "@/utils/hook";
import {
  rangeLimitDays,
  objFormateToSearchFields,
  searchFieldsInitialValuesFormat,
} from "@/utils/format";
import { SearchFormFactory } from "@/components/factory/FormFactory";
import { NormalTable } from "@/components/factory/TableFactory";
import JsonModal from "@/components/JsonModal";
import SumTable from "@/components/SumTable";
import useColumn from "@/hooks/useOrderColumn";
import Edit from "./Edit";
import AddEdit from "./AddEdit";

const createdInitialMoment = [moment().startOf("days"), moment().endOf("day")];
const Order = ({ params = null }) => {
  const perms = useUserPerms();

  const { defaultColumns, tableColumns, sumColumns, exportColumnsRenderMap } =
    useColumn();

  const searchFields = useMemo(
    () => ({
      id__in: { type: "string", label: "ID" },
      order_no__in: { type: "string", label: "订单号" },
      trans_no__in: { type: "string", label: "第三方订单号" },
      currency: { type: "select", label: "货币类型", options: Currency },
      userid__in: { type: "string", label: "会员ID" },
      ...(perms["app.List"] &&
        !params && {
          app_id__in: {
            type: "searchSelect",
            label: "商户名称",
            action: getApps,
            selector: selectApp,
            searchKey: "name",
            val: "id",
            mode: "multiple",
            optionLabel: i => `${i.id} ${i.name}`,
          },
        }),
      // gateway_name__ie: { type: "string", label: "通道名称" },
      ...(perms["gateway.List"] && {
        gateway_id__in: {
          type: "searchSelect",
          label: "渠道列表",
          action: getGateways,
          selector: selectGateway,
          searchKey: "name",
          val: "id",
          mode: "multiple",
          optionLabel: i => `${i.id} ${i.name}`,
        },
      }),
      is_online: {
        type: "select",
        label: "是否在线订单",
        options: IsBoolEnum,
      },
      succeeded: {
        type: "select",
        label: "是否成功",
        options: IsBoolEnum,
        isBool: true,
      },
      approved: {
        type: "select",
        label: "审核通过",
        options: IsBoolEnum,
        isBool: true,
      },
      developer_id__in: { type: "string", label: "商户ID" },
      created__btw: {
        type: "rangeDate",
        label: "创建日期",
        initialValue: createdInitialMoment,
      },
      paid_at__btw: { type: "rangeDate", label: "支付时间" },
      ...objFormateToSearchFields(params),
    }),
    [params, perms],
  );

  // params && delete searchFields.app_list;
  const fieldsInitialValues = useMemo(() => {
    return searchFieldsInitialValuesFormat(searchFields);
  }, [searchFields]);
  const { handleGetPageSearchCache } = useSearchCache();

  const {
    loading: exportLoading,
    handleExport,
    handleSelectedColumns,
  } = useExportExcel({
    exportColumnsRenderMap,
    exportAction: exportOrder,
  });
  const handleExportClick = async formModel => {
    let search = {
      ...formModel,
      ...(params?.app_id ? { app_id: params.app_id } : null),
    };
    handleExport(search);
  };

  const {
    res: { list, meta, sum },
    loading: listLoading,
    handleSearch,
    handleGetList,
    handleChangePage,
    handleChange,
    handleAdd: handleAddHook,
  } = useList(getOrders, selectOrder, { ...fieldsInitialValues });

  const dispatch = useDispatch();
  const handleGetOrdersSum = (search = {}) => {
    dispatch(
      getOrdersSum({
        ...fieldsInitialValues,
        ...handleGetPageSearchCache(),
        ...search,
      }),
    );
  };
  useEffect(() => {
    handleGetOrdersSum();
    // eslint-disable-next-line
  }, []);
  const handleCustomSearch = formModel => {
    if (!formModel.created__btw && !formModel.paid_at__btw) {
      message.warning("「创建时间」与「支付时间」必填其中一项");
      return;
    }
    if (formModel.created__btw) {
      if (!rangeLimitDays(31, formModel.created__btw)) {
        message.warning("「创建时间」不得超過31天");
        return;
      }
    }
    if (formModel.paid_at__btw) {
      if (!rangeLimitDays(31, formModel.paid_at__btw)) {
        message.warning("「支付时间」不得超過31天");
        return;
      }
    }
    const searchParams = {
      ...formModel,
      ...(formModel.succeeded === false && {
        status__in: "4,5,6,7,9,10,13,14,15",
      }),
    };
    handleSearch(searchParams);
    handleGetOrdersSum(searchParams);
  };
  const [detailId, setDetailId] = useState(null);
  const {
    currentRow,
    loading: detailLoading,
    handleEdit,
  } = useDetail({ action: getOrder, id: detailId }, selectOrder);

  const history = useHistory();
  const handleDetailClick = async id => {
    history.push(generatePath("/OrderDetail/:id", { id }));
  };

  const [jsonVisible, setJsonVisible] = useState(false);
  const handleJsonClick = async id => {
    setDetailId(id);
    setJsonVisible(true);
  };
  useEffect(() => {
    jsonVisible || setDetailId(null);
  }, [jsonVisible]);

  const [addVisible, setAddVisible] = useState(false);
  const handleAddClick = () => {
    setAddVisible(true);
  };
  const handleAdd = async formModel => {
    handleAddHook({ action: addOrder, ...formModel });
    handleGetOrdersSum();
    setAddVisible(false);
  };

  const [editVisible, setEditVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const handleEditClick = async (record, mode) => {
    setEditVisible(true);
    setDetailId(record.id);
    setEditMode(mode);
  };
  const handleEditOk = async formModel => {
    const action = editMode === "approve" ? approveOrder : denyOrder;
    const { status } = await handleEdit({ action, id: detailId, ...formModel });
    if (status !== 200) return;
    setEditVisible(false);
    setDetailId(null);
    await handleGetList({ page: meta.page });
  };

  // const handleCancelClick = id => {
  //   Modal.confirm({
  //     title: "是否取消订单",
  //     icon: <ExclamationCircleOutlined />,
  //     content: `即将取消订单 ${id}，是否继续？`,
  //     okText: "确认",
  //     cancelText: "取消",
  //     onOk: close => handleCancelOrder(close, id),
  //   });
  // };
  // const handleCancelOrder = async (close, id) => {
  //   const { status } = await cancelOrder(id);
  //   close();
  //   if (status !== 200) return;
  //   message.success("訂單已取消！");
  //   await handleGetList({ page: meta.page });
  // };

  const handleRecycleClick = id => {
    Modal.confirm({
      title: "是否收回订单",
      icon: <ExclamationCircleOutlined />,
      content: `即将收回订单 ${id}，是否继续？`,
      okText: "确认",
      cancelText: "取消",
      onOk: close => handleRecycleOrder(close, id),
    });
  };
  const handleRecycleOrder = async (close, id) => {
    const { status } = await recycleOrder(id);
    close();
    if (status !== 200) return;
    message.success("訂單已收回！");
    await handleGetList({ page: meta.page });
  };

  const handleNotifyClick = async id => {
    Modal.confirm({
      title: "是否通知订单",
      icon: <ExclamationCircleOutlined />,
      content: `即将通知订单 ${id}，是否继续？`,
      okText: "确认",
      cancelText: "取消",
      onOk: close => handleNotify(close, id),
    });
  };
  const handleNotify = async (close, id) => {
    const { status } = await notifyOrder(id);
    close();
    if (status !== 200) return;
    message.success("订单已通知！");
    await handleGetList({ page: meta.page });
  };

  const handleCompletedClick = async id => {
    Modal.confirm({
      title: "是否完成订单",
      icon: <ExclamationCircleOutlined />,
      content: `即将完成订单 ${id}，是否继续？`,
      okText: "确认",
      cancelText: "取消",
      onOk: close => handleCompleted(close, id),
    });
  };
  const handleCompleted = async (close, id) => {
    const { status } = await completedOrder(id);
    close();
    if (status !== 200) return;
    message.success("订单已完成！");
    await handleGetList({ page: meta.page });
  };

  const [visiblePreview, setVisiblePreview] = useState(false);
  const [previewUrls, setPreviewUrls] = useState([]);
  const previewApproveImages = urls => {
    setVisiblePreview(true);
    setPreviewUrls(urls);
  };

  const columns = [
    ...tableColumns,
    {
      title: "动作",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      render: (_, record) => (
        <Space>
          <Button
            size="small"
            onClick={() => handleJsonClick(record.id)}
            type="link"
            className="p-0"
            disabled={!perms["order.Get"]}
          >
            json
          </Button>
          <Button
            size="small"
            onClick={() => handleDetailClick(record.id)}
            type="link"
            className="p-0"
            disabled={!perms["order.Get"]}
          >
            查看
          </Button>
          {record.extra?.approve_image_urls && (
            <Button
              size="small"
              type="text"
              className="p-0"
              onClick={() =>
                previewApproveImages(record.extra.approve_image_urls)
              }
            >
              审核凭证
            </Button>
          )}
          {[2, 5, 6, 9].includes(record.status) && (
            <Button
              size="small"
              type="text"
              onClick={() => handleEditClick(record, "approve")}
              className="p-0"
              disabled={!perms["order.Approve"]}
            >
              手动通过
            </Button>
          )}
          {[2].includes(record.status) && (
            <>
              <Button
                size="small"
                onClick={() => handleEditClick(record, "deny")}
                type="text"
                danger
                className="p-0"
                disabled={!perms["order.Deny"]}
              >
                拒绝
              </Button>
              {/* <Button
                size="small"
                onClick={() => handleCancelClick(record.id)}
                type="text"
                danger
                className="p-0"
              >
                取消
              </Button> */}
            </>
          )}
          {[13, 14, 15].includes(record.status) &&
            record.approval_status === 9 && (
              <>
                <Button
                  size="small"
                  onClick={() => handleRecycleClick(record.id)}
                  type="text"
                  className="p-0"
                  disabled={!perms["order.Approve"] || record.is_recycle}
                >
                  {record.is_recycle ? "已收回" : "收回"}
                </Button>
              </>
            )}
          {record.paid && !record.notified && (
            <Button
              size="small"
              type="text"
              onClick={() => handleNotifyClick(record.id)}
              className="p-0"
              disabled={!perms["order.Notify"]}
            >
              通知
            </Button>
          )}
          {record.status === 4 && !record.succeeded && !record.is_recycle && (
            <Button
              size="small"
              type="text"
              onClick={() => handleCompletedClick(record.id)}
              className="p-0"
              disabled={!perms["order.Complete"]}
            >
              完成
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const payerCredExpandedRowRender = record => {
    return <div>{record}</div>;
  };
  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchFormFactory
        fields={searchFields}
        handleSubmit={handleCustomSearch}
        showExportBtn={true}
        handleExport={handleExportClick}
        exportLoading={exportLoading}
      />
      <Space>
        {params?.app_id ? null : (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddClick}
            disabled={!perms["order.Create"] && !perms["bank.List"]}
          >
            添加
          </Button>
        )}
      </Space>
      <SumTable data={sum} labels={sumColumns} />
      <NormalTable
        allColumns={columns}
        defaultColumns={defaultColumns}
        dataSource={list}
        meta={meta}
        onChangePage={handleChangePage}
        onChange={handleChange}
        loading={listLoading}
        setSelectedColumns={handleSelectedColumns}
        expandable={{
          rowExpandable: record => true,
          expandedRowRender: record =>
            payerCredExpandedRowRender(JSON.stringify(record.payer_cred)),
        }}
        onShowSizeChange={handleChangePage}
      />
      <AddEdit
        visible={addVisible}
        onOk={handleAdd}
        // data={{ app_id: params?.app_id || null }}
        onCancel={() => setAddVisible(false)}
        loading={listLoading}
        mode="add"
      />
      <JsonModal
        width={650}
        visible={jsonVisible}
        data={currentRow}
        onCancel={() => setJsonVisible(false)}
        loading={detailLoading}
      />
      <Edit
        visible={editVisible}
        onOk={handleEditOk}
        onCancel={() => setEditVisible(false)}
        loading={detailLoading}
        data={currentRow}
        mode={editMode}
      />
      <Image.PreviewGroup
        preview={{
          visible: visiblePreview,
          onVisibleChange: vis => setVisiblePreview(vis),
        }}
      >
        {previewUrls.map((url, idx) => (
          <Image key={idx} width={200} style={{ display: "none" }} src={url} />
        ))}
      </Image.PreviewGroup>
    </Space>
  );
};
export default Order;
