import React from "react";
import {
  Form,
  Alert,
  InputNumber,
  TimePicker,
  Space,
  Button,
  Typography,
} from "antd";
import moment from "moment";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

function RechargeRateFormList({
  name = "",
  form = null,
  title = "",
  showAlertMsg = true,
  alertMsg = "请由小到大新增，并且上下限单位为「元」",
  showTimeRange = false,
  // isUpperLayer = true,
  // upperLayerClassRate = [], // state 傳不進來ＱＱ
}) {
  /**
   * class_rate
   */
  const getRateField = (idx, field) => {
    const val = form.getFieldValue([name, idx, field]);
    return Number(val);
  };
  // 驗證下限
  const validatorMin = (_, val, idx) => {
    if (Number(val) >= getRateField(idx, "maximum"))
      return Promise.reject("请小于上限金额");
    if (Number(val) < getRateField(idx - 1, "maximum"))
      return Promise.reject("请大于上一笔上限金额");
    return Promise.resolve();
  };

  // 驗證上限
  const validatorMax = (_, val, idx) => {
    if (Number(val) <= getRateField(idx, "minimum"))
      return Promise.reject("请大于下限金额");
    return Promise.resolve();
  };
  // 上限操作
  const onChangeMax = (v, idx = 0) => {
    // console.log("onChangeMin", v, idx, listLeg);
    const nextMin = getRateField(idx + 1, "minimum");
    if (nextMin && nextMin >= 0) {
      let classRateList = form.getFieldValue([name]);
      classRateList[idx + 1].minimum = v;
      form.setFieldsValue({ [name]: [...classRateList] });
    }
  };

  /**
   * time_class_rate
   */
  const getTimeRateField = (idx, field) => {
    const val = form.getFieldValue([name, idx, field]);
    return val;
  };
  // 驗證起始時間
  const validatorStart = (_, start, idx) => {
    const end = getTimeRateField(idx, "end_time");
    return start.isBefore(end)
      ? Promise.resolve()
      : Promise.reject("请小于结束时间");
  };
  // 驗證結束時間
  const validatorEnd = (_, val, idx) => {
    const start = getTimeRateField(idx, "start_time");
    return start.isBefore(val)
      ? Promise.resolve()
      : Promise.reject("请大于起始时间");
  };
  const onChangeEndTime = (str, idx) => {
    const nextStart = getTimeRateField(idx + 1, "start_time");
    if (nextStart && nextStart >= 0) {
      let classRateList = form.getFieldValue([name]);
      classRateList[idx + 1].start_time = moment(str, "HH:mm");
      form.setFieldsValue({ [name]: [...classRateList] });
    }
  };

  // 驗證費率
  const validatorRate = (_, val) => {
    // 費率區間
    if (val && val > 100) return Promise.reject("费率為 0~100");
    return Promise.resolve();

    // const rate = idx =>
    //   form.getFieldValue(["recharge_class_rate", idx, "rate"]);
    // 不能大於上級代理費率
    // if (!isUpperLayer) {
    //   const defaultRate = Number(upperLayerClassRate[currIdx]?.rate);
    //   if (val > defaultRate) return Promise.reject("须小于上级代理费率");
    // }

    // if (currIdx === 0) {
    //   // 取下筆
    //   const nextRate = rate(currIdx + 1);
    //   if (!nextRate && nextRate !== 0) return Promise.resolve();
    //   if (val < nextRate) return Promise.reject("请大于下笔费率");
    // }
    // if (currIdx > 0) {
    //   // 取上下筆
    //   const preRate = rate(currIdx - 1);
    //   const nextRate = rate(currIdx + 1);
    //   if (val >= preRate) return Promise.reject("请小于上笔费率");
    //   if (nextRate && val < nextRate) return Promise.reject("请大于上笔费率");
    // }
  };

  const handleAddRateRange = add => {
    let classRateList = form.getFieldValue([name]);
    if (!classRateList || !classRateList[classRateList.length - 1]) {
      form.validateFields().then(() => add());
      return;
    }
    if (classRateList) {
      form.validateFields().then(() => {
        add();
        const currMax = classRateList[classRateList.length - 1]?.maximum;
        currMax &&
          form.setFieldsValue({
            [name]: [...classRateList, { minimum: currMax }],
          });
      });
    }
  };

  const handleAddTimeRateRange = add => {
    let classRateList = form.getFieldValue([name]);
    if (!classRateList || !classRateList[classRateList.length - 1]) {
      form.validateFields().then(() => add());
      return;
    }
    if (classRateList) {
      form.validateFields().then(() => {
        add();
        const currEndTime = classRateList[classRateList.length - 1]?.end_time;
        currEndTime &&
          form.setFieldsValue({
            [name]: [...classRateList, { start_time: currEndTime }],
          });
      });
    }
  };

  return (
    <>
      <Typography.Title level={5}>{title}</Typography.Title>
      {showAlertMsg && (
        <Alert
          message={alertMsg}
          type="warning"
          style={{ marginBottom: "10px" }}
        />
      )}
      <Form.List name={name}>
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, ...restField }, idx) => (
                <Space
                  key={key}
                  style={{ display: "flex", marginBottom: 8 }}
                  align="baseline"
                >
                  {showTimeRange && (
                    <>
                      <Form.Item
                        {...restField}
                        label="起始(<=)"
                        name={[name, "start_time"]}
                        initialValue={moment("00:00", "HH:mm")}
                        validateFirst={true}
                        rules={[
                          { required: true, message: "必填" },
                          {
                            validator: (_, val) => validatorStart(_, val, idx),
                          },
                        ]}
                      >
                        <TimePicker format="HH:mm" disabled={true} />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label="結束(<)"
                        name={[name, "end_time"]}
                        validateFirst={true}
                        rules={[
                          { required: true, message: "必填" },
                          { validator: (_, val) => validatorEnd(_, val, idx) },
                        ]}
                      >
                        <TimePicker
                          format="HH:mm"
                          onChange={(_, str) => onChangeEndTime(str, idx)}
                        />
                      </Form.Item>
                    </>
                  )}
                  {showTimeRange || (
                    <>
                      <Form.Item
                        {...restField}
                        label="下限(<=)"
                        name={[name, "minimum"]}
                        initialValue={0}
                        validateFirst={true}
                        rules={[
                          { required: true, message: "必填" },
                          { validator: (_, val) => validatorMin(_, val, idx) },
                        ]}
                      >
                        <InputNumber step="1" disabled={true} />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label="上限(<)"
                        name={[name, "maximum"]}
                        validateFirst={true}
                        rules={[
                          { required: true, message: "必填" },
                          { validator: (_, val) => validatorMax(_, val, idx) },
                        ]}
                      >
                        <InputNumber
                          step="1"
                          onChange={e => onChangeMax(e, idx, fields.length)}
                          // disabled={idx < fields.length - 1}
                          // disabled={!isUpperLayer || idx < fields.length - 1}
                        />
                      </Form.Item>
                    </>
                  )}
                  <Form.Item
                    {...restField}
                    label="费率"
                    name={[name, "rate"]}
                    validateFirst={true}
                    rules={[
                      { required: true, message: "必填" },
                      { validator: (_, val) => validatorRate(_, val, idx) },
                    ]}
                  >
                    <InputNumber step="1" min={0} />
                  </Form.Item>
                  <Button
                    type="text"
                    icon={<MinusCircleOutlined />}
                    onClick={() => remove(name)}
                    disabled={!(idx + 1 === fields.length)}
                  />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() =>
                    showTimeRange
                      ? handleAddTimeRateRange(add)
                      : handleAddRateRange(add)
                  }
                  block
                  // disabled={!isUpperLayer}
                  icon={<PlusOutlined />}
                >
                  新增阶层
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    </>
  );
}

export default RechargeRateFormList;
