import { useState, useEffect } from "react";
import { Button, Space, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  selectGatewayAcct,
  getGatewayAccts,
  getGatewayAcct,
  addGatewayAcct,
  editGatewayAcct,
  editGatewayAcctBalance,
} from "@/store/slice/gatewayAcct";
import EditableConfirm from "@/components/EditableConfirm";
import { SearchFormFactory } from "@/components/factory/FormFactory";
import { NormalTable } from "@/components/factory/TableFactory";
import Detail from "@/components/Detail";
import JsonModal from "@/components/JsonModal";
import { useList, useDetail, useUserPerms } from "@/utils/hook";
import { dateFormat, priceFormat } from "@/utils/format";
import { Currency } from "@/utils/enum";
import AddEdit from "./AddEdit";

const GatewayAcct = () => {
  const perms = useUserPerms();
  const searchFields = {
    id__in: { type: "string", label: "ID" },
    gateway_id__in: { type: "string", label: "渠道ID" },
    gateway_name__k: { type: "string", label: "渠道名称" },
    currency: { type: "select", label: "货币类型", options: Currency },
    created__btw: { type: "rangeDate", label: "创建日期" },
  };

  const {
    res: { list, meta },
    loading: listLoading,
    handleSearch,
    handleGetList,
    handleChangePage,
    handleChange,
    handleAdd: handleAddHook,
  } = useList(getGatewayAccts, selectGatewayAcct);

  const [addVisible, setAddVisible] = useState(false);
  const handleAddClick = () => {
    setAddVisible(true);
  };
  const handleAdd = async formModel => {
    handleAddHook({ action: addGatewayAcct, ...formModel });
    setAddVisible(false);
  };

  const [detailId, setDetailId] = useState(null);
  const {
    currentRow,
    loading: detailLoading,
    setLoading: setDetailLoading,
    handleEdit: handleEditHook,
  } = useDetail({ action: getGatewayAcct, id: detailId }, selectGatewayAcct);
  const [detailVisible, setDetailVisible] = useState(false);
  const handleDetailClick = async id => {
    setDetailId(id);
    setDetailVisible(true);
  };

  const [jsonVisible, setJsonVisible] = useState(false);
  const handleJsonClick = async id => {
    setDetailId(id);
    setJsonVisible(true);
  };
  const [editVisible, setEditVisible] = useState(false);
  const handleEditClick = async id => {
    setDetailId(id);
    setEditVisible(true);
  };
  useEffect(() => {
    jsonVisible || editVisible || setDetailId(null);
  }, [jsonVisible, editVisible]);

  const handleEdit = async formModel => {
    const { status } = await handleEditHook({
      action: editGatewayAcct,
      id: currentRow.id,
      ...formModel,
    });
    if (status !== 200) return;
    setEditVisible(false);
    handleGetList({ page: meta.current });
  };

  const fields = [
    {
      label: "增加或减少金額",
      name: "amount",
      inputType: "price",
      required: true,
      min: null,
    },
    {
      label: "备注",
      name: "note",
      inputType: "string",
    },
  ];
  const [balanceVisible, setBalanceVisible] = useState(false);
  const handleBalanceClick = record => {
    setDetailId(record.id);
    setBalanceVisible(true);
  };
  const handleCancelBalance = () => {
    setBalanceVisible(false);
    setDetailId(null);
  };
  const handleBalance = async formModel => {
    setDetailLoading(true);
    const { status } = await editGatewayAcctBalance({
      id: currentRow.id,
      formModel,
    });
    setDetailLoading(false);
    if (status !== 200) return;
    handleCancelBalance();
    message.success(`已更新余额`);
    handleGetList({ page: meta.current });
  };

  const columns = [
    { title: "ID", dataIndex: "id", sorter: true },
    { title: "渠道ID", dataIndex: "gateway_id", sorter: true },
    { title: "渠道名称", dataIndex: "gateway_name", sorter: true },
    { title: "渠道别名", dataIndex: "gateway_alias", sorter: true },
    {
      title: "余额",
      dataIndex: "balance",
      render: (val, record) => priceFormat({ val, currency: record.currency }),
      className: "text-nowrap",
      sorter: true,
    },
    {
      title: "货币类型",
      dataIndex: "currency",
      render: val => Currency[val] || "",
    },
    {
      title: "创建日期",
      dataIndex: "created",
      render: val => dateFormat(val),
      className: "text-nowrap",
      sorter: true,
    },
    {
      title: "更新日期",
      dataIndex: "updated",
      render: val => dateFormat(val),
      className: "text-nowrap",
      sorter: true,
    },
    {
      title: "动作",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      render: (_, record) => (
        <Space>
          <Button
            size="small"
            onClick={() => handleJsonClick(record.id)}
            type="link"
            className="p-0"
            disabled={!perms["gateway.Get"]}
          >
            json
          </Button>
          <Button
            size="small"
            onClick={() => handleDetailClick(record.id)}
            type="link"
            className="p-0"
            disabled={!perms["gateway.Get"]}
          >
            查看
          </Button>
          <Button
            size="small"
            onClick={() => handleBalanceClick(record)}
            type="link"
            className="p-0"
            disabled={!perms["gatewayacct.UpdateBalance"]}
          >
            修改余额
          </Button>
          <Button
            size="small"
            onClick={() => handleEditClick(record.id)}
            type="text"
            className="p-0"
            disabled={!perms["gatewayacct.Update"]}
          >
            编辑
          </Button>
        </Space>
      ),
    },
  ];
  const defaultColumns = [
    "id",
    "gateway_id",
    "gateway_name",
    "gateway_alias",
    "balance",
    "currency",
    "created",
    "updated",
    "action",
  ];

  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchFormFactory fields={searchFields} handleSubmit={handleSearch} />
      <Space>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleAddClick}
          disabled={!perms["gatewayacct.Create"]}
        >
          添加
        </Button>
      </Space>
      <NormalTable
        allColumns={columns}
        defaultColumns={defaultColumns}
        dataSource={list}
        meta={meta}
        loading={listLoading}
        onChangePage={handleChangePage}
        onChange={handleChange}
        onShowSizeChange={handleChangePage}
      />
      <AddEdit
        visible={addVisible}
        onOk={handleAdd}
        onCancel={() => setAddVisible(false)}
        loading={listLoading}
        mode="add"
      />
      <JsonModal
        width={650}
        visible={jsonVisible}
        data={currentRow}
        onCancel={() => setJsonVisible(false)}
        loading={detailLoading}
      />
      <Detail
        width="700px"
        title="支付网关明细"
        visible={detailVisible}
        data={currentRow}
        onCancel={() => setDetailVisible(false)}
        loading={detailLoading}
        columns={columns.filter(i => i.dataIndex !== "action")}
      />
      <AddEdit
        visible={editVisible}
        onOk={handleEdit}
        onCancel={() => setEditVisible(false)}
        loading={detailLoading}
        data={currentRow}
        mode="edit"
      />
      <EditableConfirm
        title="更新余额"
        fields={fields}
        visible={balanceVisible}
        data={{ ...currentRow, currency: 0 }}
        onCancel={handleCancelBalance}
        loading={detailLoading}
        onOk={handleBalance}
      />
    </Space>
  );
};
export default GatewayAcct;
