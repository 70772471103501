import { dateFormat, priceFormat } from "@/utils/format";
import {
  Currency,
  transferStatus,
  TransfersCategory,
  isSuccessLang,
  isActiveLang,
} from "@/utils/enum";
import Tag from "@/components/Tag";
const appFeeTypeFormat = ({ feeType, fee = 0, feeRate }) => {
  const _fee = fee
    ? (fee / 100).toLocaleString("en-US", {
        maximumFractionDigits: 2,
      })
    : "0";

  const map = {
    0: `固定($${_fee})`,
    1: `百分比(${feeRate}%)`,
    2: `阶层利率(${feeRate}%)`,
    3: `固定($${_fee}) + 一般利率(${feeRate}%)`,
    4: `固定($${_fee}) + 阶层利率(${feeRate}%)`,
  };
  return map[feeType] || "";
};

export const columns = [
  { title: "ID", dataIndex: "id", sorter: true },
  { title: "开发者订单号", dataIndex: "order_no" },
  {
    title: "是否为安全提款",
    dataIndex: "is_save_withdrawals",
    sorter: true,
    render: (val, record) => {
      return (
        <Tag val={record.note === "安全渠道"}>
          {record.note === "安全渠道" ? "是" : "否"}
        </Tag>
      );
    },
  },
  {
    title: "是否拆单",
    dataIndex: "is_split_order",
    render: (val, record) => {
      return record.sub_status ? (
        <Tag val={false}>{"总"}</Tag>
      ) : record.m_transfer_id ? (
        <Tag val={true}>{"子"}</Tag>
      ) : null;
    },
  },
  {
    title: "是否有子单",
    dataIndex: "sub_status",
    render: (val, record) => {
      return record.sub_status ? <Tag val={true}>{"是"}</Tag> : null;
    },
  },
  {
    title: "已拆单金额",
    dataIndex: "sub_amount",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
  },
  {
    title: "主单ID",
    dataIndex: "m_transfer_id",
  },
  {
    title: "已支付金额",
    dataIndex: "amounted",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
  },
  {
    title: "绑定支付订单号",
    dataIndex: "pay_order_no",
  },
  {
    title: "是否锁定中",
    dataIndex: "order_lock",
  },
  { title: "第三方订单号", dataIndex: "trans_no" },
  { title: "AppID", dataIndex: "app_id", sorter: true },
  { title: "商戶用户ID", dataIndex: "app_user_id", sorter: true },
  { title: "商戶用户", dataIndex: "app_name_cn", sorter: true },
  { title: "用戶ID", dataIndex: "user_id", sorter: true },
  { title: "代理ID", dataIndex: "agent_id" },
  { title: "代理姓名", dataIndex: "agent_name" },
  {
    title: "用户账户",
    dataIndex: "account",
  },
  {
    title: "银行卡开户行",
    dataIndex: "bank_name",
  },
  {
    title: "出款银行卡ID",
    dataIndex: "card_id",
  },
  {
    title: "出款钱包ID",
    dataIndex: "crypto_wallet_id",
  },
  {
    title: "真实姓名",
    dataIndex: "name",
  },
  {
    title: "category",
    dataIndex: "category",
    render: val => TransfersCategory[val],
  },
  {
    title: "出款金额",
    dataIndex: "amount",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
    sorter: true,
    className: "text-nowrap",
  },
  {
    title: "实际出款金额",
    dataIndex: "amount_paid",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
    sorter: true,
    className: "text-nowrap",
  },
  {
    title: "出款货币类型",
    dataIndex: "currency",
    render: val => Currency[val] || "",
    sorter: true,
    className: "text-nowrap",
  },
  {
    title: "商户手续费率",
    dataIndex: "app_fee_type",
    render: (val, record) =>
      appFeeTypeFormat({
        feeType: val,
        fee: record.app_fee,
        feeRate: record.app_fee_rate,
      }),
    sorter: true,
    className: "text-nowrap",
  },
  {
    title: "商户实收手续费",
    dataIndex: "amount_app_fee",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
    sorter: true,
    className: "text-nowrap",
  },
  {
    title: "出帐金额",
    dataIndex: "amount_app",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
    sorter: true,
    className: "text-nowrap",
  },
  {
    title: "备注",
    dataIndex: "note",
    sorter: true,
  },
  {
    title: "支付网关类名",
    dataIndex: "gateway",

    sorter: true,
  },
  {
    title: "是否通过审核",
    dataIndex: "approved",
    render: val => <Tag val={val} />,
  },
  {
    title: "审核状态",
    dataIndex: "approval_status",
    render: val => transferStatus[val],
  },
  {
    title: "审核人ID",
    dataIndex: "approver_id",
  },
  {
    title: "审核通过时间",
    dataIndex: "approved_at",
    width: 120,
    render: val => dateFormat(val),
    sorter: true,
  },
  {
    title: "审核备注",
    dataIndex: "comments",
  },
  {
    title: "网关ID",
    dataIndex: "gateway_id",
    sorter: true,
  },
  // {
  //   title: "网关名称",
  //   dataIndex: "gateway_name",
  //   render: (val, record) =>
  //     record.is_online ? val : <Text type="danger">{val}</Text>,
  //   sorter: true,
  // },
  {
    title: "创建时间",
    dataIndex: "created",
    className: "text-nowrap",
    render: val => dateFormat(val),
    sorter: true,
  },
  {
    title: "更新日期",
    dataIndex: "updated",
    className: "text-nowrap",
    render: val => dateFormat(val),
    sorter: true,
  },
  {
    title: "是否取消",
    dataIndex: "canceled",
    render: val => <Tag val={val} />,
  },
  {
    title: "取消时间",
    dataIndex: "canceled_at",
    className: "text-nowrap",
    render: val => dateFormat(val),
    sorter: true,
  },
  {
    title: "过期时间",
    dataIndex: "expired_at",
    className: "text-nowrap",
    render: val => dateFormat(val),
    sorter: true,
  },
  {
    title: "是否过期",
    dataIndex: "expired",
    render: val => <Tag val={val} />,
  },

  {
    title: "出款人ID",
    dataIndex: "paid_id",
    sorter: true,
  },
  {
    title: "出款人",
    dataIndex: "paid_name",
    sorter: true,
  },
  {
    title: "是否已付款",
    dataIndex: "paid",
    render: val => <Tag val={val} />,
  },
  {
    title: "领取时间",
    dataIndex: "paid_at",
    className: "text-nowrap",
    render: val => dateFormat(val),
    sorter: true,
  },
  {
    title: "发起订单IP",
    dataIndex: "client_ip",
    sorter: true,
  },
  {
    title: "是否成功",
    dataIndex: "succeeded",
    render: (val, { status }) =>
      [10, 13, 14, 15, 16, 17].includes(status) ? (
        <Tag val={val}>{isSuccessLang(val)}</Tag>
      ) : null,
  },
  {
    title: "成功时间",
    dataIndex: "succeeded_at",
    render: (val, { succeeded }) =>
      succeeded === true ? dateFormat(val) : null,
  },
  {
    title: "失败状态码",
    dataIndex: "failure_code",
  },
  {
    title: "失败原因",
    dataIndex: "failure_msg",
  },
  {
    title: "是否回调",
    dataIndex: "notified",
    render: val => <Tag val={val} />,
  },
  {
    title: "回调时间",
    dataIndex: "notified_at",
    className: "text-nowrap",
    render: val => dateFormat(val),
    sorter: true,
  },
  {
    title: "回调url",
    dataIndex: "notify_url",
  },
  {
    title: "回调的状态",
    dataIndex: "notify_status",
    render: val => transferStatus[val],
  },
  {
    title: "订单状态",
    dataIndex: "status",
    render: val => transferStatus[val],
  },
  {
    title: "补充信息",
    dataIndex: "extra",
    render: val => JSON.stringify(val),
  },
  {
    title: "代付凭证",
    dataIndex: "credential",
  },
  {
    title: "已清算",
    dataIndex: "settled",
    render: val => <Tag val={val} />,
  },
];

const detailColumnsCardArr = [
  "account",
  "name",
  "bank_name",
  "amount",
  "amount_paid",
  "app_fee_type",
  "amount_app_fee",
  "amount_app",
  "approver_id",
  "updated",
  "comments",
];

const detailColumnsUSDTArr = [
  "account",
  "currency",
  "bank_name",
  "amount",
  "amount_paid",
  "app_fee_type",
  "amount_app_fee",
  "amount_app",
  "approver_id",
  "updated",
  "comments",
];

export const sumColumns = [
  {
    title: "订单次数",
    dataIndex: "total_times",
  },
  {
    title: "订单成功次数",
    dataIndex: "total_succeeded_times",
  },
  {
    title: "订单成功金额",
    dataIndex: "total_succeeded_amount",
    render: val => priceFormat({ val, currency: 0 }),
  },
];

const formatColumn = arr => columns.filter(i => arr.indexOf(i.dataIndex) >= 0);

export const detailColumnsCard = formatColumn(detailColumnsCardArr);

export const detailColumnsUSDT = formatColumn(detailColumnsUSDTArr);

const excelPriceFormat = (val, currency) =>
  priceFormat({ val, currency, showUnit: false });

export const exportColumnsRenderMap = {
  category: val => TransfersCategory[val],
  amount: (val, record) => excelPriceFormat(val, record.currency),
  amount_paid: (val, record) => excelPriceFormat(val, record.currency),
  currency: val => Currency[val] || "",
  app_fee_type: (val, record) =>
    appFeeTypeFormat({
      feeType: val,
      fee: record.app_fee,
      feeRate: record.app_fee_rate,
    }),
  amount_app_fee: (val, record) => excelPriceFormat(val, record.currency),
  amount_app: (val, record) => excelPriceFormat(val, record.currency),
  approved: val => isActiveLang(val),
  approval_status: val => transferStatus[val],
  approved_at: val => dateFormat(val),
  created: val => dateFormat(val),
  updated: val => dateFormat(val),
  canceled: val => isActiveLang(val),
  canceled_at: val => dateFormat(val),
  expired_at: val => dateFormat(val),
  expired: val => isActiveLang(val),
  paid: val => isActiveLang(val),
  paid_at: val => dateFormat(val),
  succeeded: (val, { status }) =>
    [10, 13, 14, 15, 16, 17].includes(status) ? isSuccessLang(val) : null,
  succeeded_at: (val, { succeeded }) =>
    succeeded === true ? dateFormat(val) : null,
  notified: val => isActiveLang(val),
  notified_at: val => dateFormat(val),
  notify_status: val => transferStatus[val],
  status: val => transferStatus[val],
  extra: val => JSON.stringify(val),
  settled: val => isActiveLang(val),
};
